import { FC, useEffect, useState } from 'react'
import { ICollection, IExtraFiling, IFiling, IPriceVariant, IProduct, IVariant } from '../../Types/IProduct'
import {
    Container,
    Description,
    ButtonCart,
    ImageContainer,
    ImageWrapper,
    MainImage,
    PreviewImage,
    Title,
    Wrapper,
    Line,
    PriceValue, MainImageWrapper
} from './ProductPageStyled'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { MobileSlide } from '../../components/sliders/mobileSlider/MobileSliderStyled'
import MobileSlider from '../../components/sliders/mobileSlider/MobileSlider'
import BestSellers from '../../components/BestSellers/BestSellers'
import Parameters from './Modules/Parametrs/Parameters'
import Fillings from './Modules/Fillings/Fillings'
import Collection from './Modules/Colection/Collection'
import Variants from './Modules/Variants/Variants'
import ExtraFilings from './Modules/ExtraFilings/ExtraFilings'
import { getImageUrl } from '../../utils/getImageUrl'
import { IOption, IProductValues } from './Types/Types'
import Price from './Modules/Price/Price'
import Quantity from './Components/Quantity'
import { v4 as uuidv4 } from 'uuid'
import { addProduct } from '../../store/reducers/CartSlice/CartSlice'
import { IImage } from '../../Types/IImage'
import { showMessage } from '../../store/reducers/AppCondition/AppConditionSlice'
import { defaultIngredients } from './Constants/defaultIngredients'
import { iconBadImage } from '../../UI/Icons/icons'
import { getProductPrice } from '../../Helpers/getProductPrice'
import { IOrderProduct } from '../../Types/IOrder'


const getPrice = (cost: string, quantity: string) => {
    return (Number(cost) * Number(quantity)).toFixed(2)
}

const getRequiredProductOptions = (product: IProduct) => {
    const result: { [key: string]: string } = {}
    if (!!product.filings.length) {
        result.filings = 'Filling'
    }
    if (!!product.extraFilings.filings.length) {
        result.extraFilings = 'Sponge cake-filling'
    }
    if (!!product.price.variants.length) {
        result.priceVariant = 'Price variant'
    }
    if (!!product.variants.length) {
        result.variants = 'Options'
    }
    return result
}

interface ProductPageProps {
    sectionName: string
    sectionId: string
    product: IProduct
}

const ProductPage: FC<ProductPageProps> = ({
                                               product,
                                               sectionName,
                                               sectionId
                                           }) => {

    const productRequiredOptions = getRequiredProductOptions(product)

    const dispatch = useAppDispatch()
    const { mobile } = useAppSelector((state) => state.appConditionReducer)

    const [maneImage, setManeImage] = useState<IImage>(product.photo[0])

    const [displayedIngredients, setDisplayedIngredients] = useState<string>('')
    const [activeFilling, setActiveFilling] = useState<number>(0)

    const [productValues, setProductValues] = useState<IProductValues>({
        quantity: String(product.minimalQuantity),
        cost: getProductPrice(
            product.price,
            product.price.variants[0] ? product.price.variants[0] : null
        ),
        options: []
    })

    useEffect(() => {
        setManeImage(product.photo[0])
    }, [product])

    useEffect(() => {
        if (product.ingredients) {
            setDisplayedIngredients(product.ingredients)
        } else {
            setDisplayedIngredients(defaultIngredients)
        }
    }, [])

    useEffect(() => {
        if (product.price.type === 'variants' && product.price.variants[0]) {
            setProductValues({
                ...productValues,
                options: [
                    {
                        title: 'priceVariant',
                        value: product.price.variants[0].unit
                    }
                ]
            })
        }
    }, [])

    const changeActiveFilingHandler = (num: number, filing: string) => {
        setDisplayedIngredients(filing)
        setActiveFilling(num)
    }

    const addOption = (option: IOption) => {
        setProductValues({
            ...productValues,
            options: [...productValues.options, option]
        })
    }

    const setOption = (option: IOption) => {
        setProductValues({
            ...productValues,
            options: productValues.options.map((item) =>
                item.title === option.title
                    ? {
                        ...item,
                        value: option.value
                    }
                    : item
            )
        })
    }

    const removeOption = (option: IOption) => {
        setProductValues({
            ...productValues,
            options: productValues.options.filter(
                ({ title }) => title !== option.title
            )
        })
    }

    const changeCurrentPriceVariant = (variant: IPriceVariant) => {
        setProductValues({
            ...productValues,
            cost: variant.cost,
            options: productValues.options.map((item) =>
                item.title === 'priceVariant'
                    ? {
                        ...item,
                        value: variant.unit
                    }
                    : item
            )
        })
    }

    const setQuantity = (value: string) => {
        setProductValues({ ...productValues, quantity: value })
    }

    const addProductToCart = () => {
        let readyToBy = true
        let toMessage = ''
        for (const option in productRequiredOptions) {
            if (!productValues.options.some(({ title }) => title === option)) {
                toMessage += `${productRequiredOptions[option]} `
                readyToBy = false
            }
        }

        if (readyToBy) {
            const orderProduct: IOrderProduct = {
                id: uuidv4(),
                status: 'created',
                sectionId: sectionId,
                productId: product.id,
                sectionName: sectionName,
                name: product.title,
                sale: '',
                ...productValues
            }
            dispatch(
                addProduct(orderProduct)
            )
            dispatch(showMessage(`${product.title} added to cart`))
        } else {
            dispatch(showMessage(`Please select: ${toMessage}`))
        }

    }

    if (!mobile) {
        return (
            <>
                <Wrapper>
                    <ImageWrapper>
                        <MainImageWrapper>
                            <MainImage
                                src={getImageUrl(maneImage)}
                                onError={(e) =>
                                    (e.currentTarget.src = iconBadImage)
                                }
                            />
                        </MainImageWrapper>
                        <ImageContainer>
                            {product.photo.map((image, i) => (
                                <PreviewImage
                                    src={getImageUrl(image)}
                                    onClick={() => setManeImage(image)}
                                    key={i}
                                    onError={(e) =>
                                        (e.currentTarget.src = iconBadImage)
                                    }
                                />
                            ))}
                        </ImageContainer>
                    </ImageWrapper>
                    <Container>
                        <Title>{product.title}</Title>
                        <Price
                            onChangeVariant={changeCurrentPriceVariant}
                            price={product.price}
                        />
                        <Description>{product.description}</Description>
                        <ExtraFilings
                            title={product.extraFilings.sectionTitle}
                            filings={product.extraFilings.filings}
                            onSetIngredients={setDisplayedIngredients}
                            addOption={addOption}
                            setOption={setOption}
                            removeOption={removeOption}
                        />
                        <Fillings
                            fillings={product.filings}
                            onSetIngredients={setDisplayedIngredients}
                            addOption={addOption}
                            setOption={setOption}
                            removeOption={removeOption}
                        />

                        <Collection
                            collection={product.collections}
                            onSetIngredients={setDisplayedIngredients}
                            activeFilling={activeFilling}
                            changeActiveFilling={changeActiveFilingHandler}
                        />

                        <Variants
                            variants={product.variants}
                            addOption={addOption}
                            setOption={setOption}
                            removeOption={removeOption}
                        />
                        <Line>
                            <Quantity
                                minimalQuantity={product.minimalQuantity}
                                quantity={productValues.quantity}
                                onSetQuantity={setQuantity}
                            />
                            {(product.price.type === 'interval' ||
                                product.price.type === 'only' ||
                                product.price.type === 'variants') && (
                                <PriceValue>
                                    {product.price.currency} {getPrice(productValues.cost, productValues.quantity)}
                                </PriceValue>
                            )}
                        </Line>
                        <ButtonCart onClick={addProductToCart}>
                            Add to cart
                        </ButtonCart>
                        <Parameters
                            ingredients={displayedIngredients}
                            delivery={product.delivery}
                            storage={product.storage}
                        />
                    </Container>
                </Wrapper>
                <BestSellers />
            </>
        )
    } else {
        return (
            <>
                <Wrapper>
                    <Title>{product.title}</Title>
                    <Price
                        onChangeVariant={changeCurrentPriceVariant}
                        price={product.price}
                    />
                    <MobileSlider>
                        {product.photo.map((image, i) => (
                            <MobileSlide key={i}>
                                <ImageWrapper>
                                    <MainImage src={getImageUrl(image)} />
                                </ImageWrapper>
                            </MobileSlide>
                        ))}
                    </MobileSlider>
                    <Container>
                        <Description>{product.description}</Description>

                        <ExtraFilings
                            title={product.extraFilings.sectionTitle}
                            filings={product.extraFilings.filings}
                            onSetIngredients={setDisplayedIngredients}
                            addOption={addOption}
                            setOption={setOption}
                            removeOption={removeOption}
                        />

                        <Fillings
                            fillings={product.filings}
                            onSetIngredients={setDisplayedIngredients}
                            addOption={addOption}
                            setOption={setOption}
                            removeOption={removeOption}
                        />

                        <Collection
                            collection={product.collections}
                            onSetIngredients={setDisplayedIngredients}
                            activeFilling={activeFilling}
                            changeActiveFilling={changeActiveFilingHandler}
                        />

                        <Variants
                            variants={product.variants}
                            addOption={addOption}
                            setOption={setOption}
                            removeOption={removeOption}
                        />
                        <Line>
                            <Quantity
                                minimalQuantity={product.minimalQuantity}
                                quantity={productValues.quantity}
                                onSetQuantity={setQuantity}
                            />
                            {(product.price.type === 'interval' ||
                                product.price.type === 'only' ||
                                product.price.type === 'variants') && (
                                <PriceValue>
                                    {product.price.currency} {getPrice(productValues.cost, productValues.quantity)}
                                </PriceValue>
                            )}
                        </Line>
                        <ButtonCart onClick={addProductToCart}>
                            Add to cart
                        </ButtonCart>
                        <Parameters
                            ingredients={displayedIngredients}
                            delivery={product.delivery}
                            storage={product.storage}
                        />
                    </Container>
                </Wrapper>
                <BestSellers />
            </>
        )
    }
}

export default ProductPage
