import styled from 'styled-components'
import { MainTitle } from '../../../../UI/Common/common'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const Title = styled(MainTitle)`
  @media (${({ theme }) => theme.media.mobile}) {
    display: none;
  }
`

export const Subtitle = styled.p`
  margin: 20px 0 0 0;
  width: 600px;
  text-align: center;

  @media (${({ theme }) => theme.media.mobile}) {
    width: auto;
    display: none;
  }
`

export const Container = styled.div`
  margin: 40px 0 0 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 20px 0;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 0 0 0 0;
    grid-gap: 60px 0;
  }
`


