import styled, { css } from 'styled-components'
import { MainTextCss } from '../../UI/Text/Text'

interface WrapperProps {
    isOpen: boolean
}

export const Wrapper = styled.div<WrapperProps>`
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    width: 100%;
    max-height: 273px;
    background-color: ${({ theme }) => theme.colors.graphics};
    border-radius: 4px;
    z-index: 100000;
    overflow: hidden;
    display: none;

    ${({ isOpen }) =>
        isOpen &&
        css`
            display: block;
        `}
`

export const Header = styled.div`
    height: 64px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 1px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.background};
    background-color: ${({ theme }) => theme.colors.background};
`

export const HeaderTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.graphics};
    ${MainTextCss};
    color: ${({ theme }) => theme.colors.background};
`

export const Container = styled.div`
    height: 209px;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    background-color: ${({ theme }) => theme.colors.background};
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 2px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.background};
    }

    ::-webkit-scrollbar-track {
    }
`

export const Line = styled.div`
    padding: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: min-content;
    grid-gap: 4px 4px;
    background-color: ${({ theme }) => theme.colors.graphics};
`
