import styled, { css } from 'styled-components'
import { MainTextCss } from '../../UI/Text/Text'
import {
    iconCalendarDark,
    iconCalendarLight,
    iconTimeDark,
    iconTimeLight
} from '../../UI/Icons/icons'

export const labelCss = css`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content;
  grid-gap: 10px 0;
`

export const Label = styled.label`
  ${labelCss};
`

export const DateLabel = styled.div`
  ${labelCss};
  z-index: 1000;

  .react-datepicker {
    background-color: ${({ theme }) => theme.colors.graphics};
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
  }

  .react-datepicker__tab-loop {
    position: absolute;
    top: 0;
    left: 0;
  }

  .react-datepicker__current-month {
    color: ${({ theme }) => theme.colors.background};
    font-weight: 500;
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.graphics};
    border-bottom: 1px solid ${({ theme }) => theme.colors.background};
  }

  .react-datepicker__tab-loop {
    //position: absolute;
    //top: 0;
    //left: 0;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation-icon {
    :before {
      border-color: ${({ theme }) => theme.colors.background};
    }
  }

  .react-datepicker__day {
    :hover {
      background-color: ${({ theme }) => theme.colors.graphics};
      outline: 2px solid ${({ theme }) => theme.colors.background};
    }
  }

  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.graphics};
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.colors.graphics};
    color: ${({ theme }) => theme.colors.background};
    outline: none;
    border: none;
  }

  .react-datepicker__day--today {
    background-color: ${({ theme }) => theme.colors.graphics};
    outline: 2px solid ${({ theme }) => theme.colors.background};
    border-radius: 0.3rem;
  }

  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.colors.background};
    opacity: 0.3;

    :hover {
      outline: none;
    }
  }

  .react-datepicker__header__dropdown {
    padding: 5px 0;
  }

  .react-datepicker__month-dropdown-container {
  }

  .react-datepicker__month-dropdown-container--select {
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    background-color: ${({ theme }) => theme.colors.graphics};
    height: 25px;
    border-radius: 4px;
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.background};

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.background};
      border: none;
    }
  }
`

export const Placeholder = styled.p`
  padding: 0 0 0 7px;
  line-height: 24px;
  ${MainTextCss};
`

export const Logo = styled.img`
  position: absolute;
  bottom: 12px;
  left: 10px;
  width: 20px;
  height: 20px;
`

interface InputProps {
    isLogo?: boolean
    isActive?: boolean
    isReadonly?: boolean
    inputPadding?: string
}

export const Input = styled.input<InputProps>`
  padding: 0 10px 0 20px;
  min-width: 70px;
  height: 44px;
  outline: none;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.graphics};
  border-radius: 4px;
  ${MainTextCss};


  ${({ isActive }) =>
          isActive &&
          css`
            background-color: ${({ theme }) => theme.colors.graphics};
            color: ${({ theme }) => theme.colors.background};
          `}

  ${({ isLogo }) =>
          isLogo &&
          css`
            padding: 0 40px;
          `}

  ${({ isReadonly }) =>
          isReadonly &&
          css`
            cursor: initial;
          `}
}

`

export const TextArea = styled.textarea`
  padding: 10px 10px 10px 20px;
  height: 120px;
  outline: none;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.graphics};
  border-radius: 4px;
  resize: none;
  ${MainTextCss};
`

export const Error = styled.p`
  padding: 0 0 0 10px;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.error};
`

interface ContainerProps {
    isChosen?: boolean
}

export const Container = styled.div<ContainerProps>`
  padding: 0 10px 0 20px;
  height: 44px;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-gap: 0 10px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.graphics};
  border-radius: 4px;
  cursor: pointer;

  ${({ isChosen }) =>
          isChosen &&
          css`
            background-color: ${({ theme }) => theme.colors.graphics};

            p {
              color: ${({ theme }) => theme.colors.background};
            }
          `}
`

export const ContainerTime = styled(Container)`
  grid-template-columns: max-content max-content max-content 1fr;
  justify-items: end;
`

export const Value = styled.p`
  ${MainTextCss};
`

interface IconProps {
    isChosen?: boolean
}

export const Icon = styled.div<IconProps>`
  width: 20px;
  height: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
`

export const IconCalendar = styled(Icon)`
  background-image: url(${iconCalendarLight});

  ${({ isChosen }) =>
          isChosen &&
          css`
            background-image: url(${iconCalendarDark});
          `}
`

export const IconTime = styled(Icon)`
  background-image: url(${iconTimeLight});

  ${({ isChosen }) =>
          isChosen &&
          css`
            background-image: url(${iconTimeDark});
          `}
`
