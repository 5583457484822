import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from './Styles/GlobalStyles'
import { baseTheme } from './Styles/Theme/BaseTheme'
import { Provider } from 'react-redux'
import { persistor, store } from './store/store'
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'
import App from './Modules/App/App'
import Loader from './components/Loader/Loader'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <ThemeProvider theme={baseTheme}>
        <GlobalStyle />
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={<Loader />}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </ThemeProvider>
)
