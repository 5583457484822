import { FC, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { IAppOptionsState } from '../../store/reducers/AppOptions/AppOptionsSlice'
import { Menu, MenuButton, Wrapper } from './AccountStyled'
import { getTransactions } from '../../store/reducers/Transactions/ActionCreators'

const Account: FC = () => {
    const dispatch = useAppDispatch()
    const { user } = useAppSelector((state) => state.userReducer)
    const { isAuth } = useAppSelector(
        (state) => state.appOptionsReducer as IAppOptionsState
    )

    useEffect(() => {
        if (!user) return
        const loadTransactions = async () => {
            dispatch(getTransactions({ clientId: user.id }))
        }

        loadTransactions()
        const interval = setInterval(() => {
            loadTransactions()
        }, 20000)

        return () => {
            clearInterval(interval)
        }

    }, [])

    return !isAuth || !user ? (
        <Navigate to='/' replace />
    ) : (
        <Wrapper>
            <Menu>
                <MenuButton to='/account/orders'>Orders</MenuButton>
                <MenuButton to='/account/profile'>Profile</MenuButton>
                <MenuButton to='/account/transactions'>Transactions</MenuButton>
            </Menu>
            <Outlet />
        </Wrapper>
    )
}

export default Account
