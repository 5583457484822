import { configureStore } from '@reduxjs/toolkit'
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE
} from 'reduxjs-toolkit-persist'
import appConditionReducer from './reducers/AppCondition/AppConditionSlice'
import userReducer from './reducers/User/UserSlice'
import persistCardReducer from './reducers/CartSlice/CartSlice'
import productReducer from './reducers/ProductsSlice/ProductsSlice'
import appOptionsReducer from './reducers/AppOptions/AppOptionsSlice'
import transactionsReducer from './reducers/Transactions/TransactionsSlice'

export const store = configureStore({
    reducer: {
        appConditionReducer,
        userReducer,
        persistCardReducer,
        productReducer,
        appOptionsReducer,
        transactionsReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
