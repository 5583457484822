import { FC, forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
    Container,
    DateLabel,
    Error,
    IconCalendar,
    Placeholder,
    Value
} from '../FieldsStyled'
import { FormikErrors, FormikTouched } from 'formik'

interface FieldDateProps {
    name: string
    value: Date | null
    placeholder: string
    onChange: (field: string, value: any, shouldValidate?: boolean) => void
    isTouched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
    error:
        | string
        | string[]
        | FormikErrors<any>
        | FormikErrors<any>[]
        | undefined
}

const FieldDate: FC<FieldDateProps> = ({
                                           name,
                                           value,
                                           placeholder,
                                           onChange,
                                           isTouched,
                                           error
                                       }) => {
    const today = new Date()
    const ExampleCustomInput = forwardRef<HTMLDivElement>((props: any, ref) => (
        <Container onClick={props.onClick} ref={ref}>
            <Value>{props.value ? props.value : 'Select Date'}</Value>
            <IconCalendar />
        </Container>
    ))

    const changeHandler = (value: Date | null) => {
        onChange(name, value)
    }

    return (
        <DateLabel>
            <Placeholder>{placeholder}</Placeholder>
            <DatePicker
                name='date'
                dateFormat='dd.MM.yyyy'
                onChange={changeHandler}
                customInput={<ExampleCustomInput />}
                selected={value}
                dropdownMode='select'
                showMonthDropdown
                showYearDropdown
                minDate={
                    new Date(1920, 0, 1)
                }
                maxDate={new Date(today.getFullYear() + 1, 0, 1)}
                calendarStartDay={1}
            />
            {isTouched && error && <Error>{String(error)}</Error>}
        </DateLabel>
    )
}

export default FieldDate
