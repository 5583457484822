import { AppDispatch } from '../../store'
import { instance } from '../../../API/baseAPI'
import { IAppOptionsResponse, IChangeOptions } from './Types'
import { setOptions } from './AppOptionsSlice'
import { store } from '../../store'
import { errorHandler } from '../../Helpers/errorHandler'

export const getOptions =
    () => async (dispatch: AppDispatch) => {
        try {
            const updatedAt = store.getState().appOptionsReducer.updatedAt
            const response = await instance.post<IAppOptionsResponse | 'actual'>(
                '/app-options', { updatedAt })
            if (response.data === 'actual') {
                return
            }
            dispatch(setOptions(response.data))
        } catch (err: any) {
            dispatch(errorHandler(err))
        }
    }

export const changeOptions =
    (data: IChangeOptions) => async (dispatch: AppDispatch) => {
        try {
            const response = await instance.patch<IAppOptionsResponse>(
                '/app-options', data)
            dispatch(setOptions(response.data))
        } catch (err: any) {
            dispatch(errorHandler(err))
        }
    }