import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
    margin: 40px 0 0 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 50px 0;

    @media (${({ theme }) => theme.media.mobile}) {
        grid-gap: 30px 0;
    }
`

export const Title = styled.div`
    font-size: 21px;
`

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, min-content);
    grid-auto-rows: min-content;
    grid-gap: 15px 15px;

    @media (${({ theme }) => theme.media.mobile}) {
        grid-template-columns: 1fr 1fr;
    }
`

interface VariantProps {
    isActive: boolean
}

export const Variant = styled.div<VariantProps>`
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    grid-gap: 15px 0;
    box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.colors.graphics};
    border-radius: 4px;
    cursor: pointer;

    ${({ isActive }) =>
        isActive &&
        css`
            background-color: ${({ theme }) => theme.colors.graphics};
            color: ${({ theme }) => theme.colors.background};
        `}
`

export const VariantImage = styled.img.attrs({
    alt: 'VariantImage'
})`
    width: 129px;
    height: 135px;
    object-fit: cover;
`

export const VariantTitle = styled.p`
    font-size: 12px;
`
