import React, { FC } from 'react'
import { Container, Image, ImageWrapper, Subtitle, Title, Wrapper, Button } from './PromoStyled'
import { useNavigate } from 'react-router'
import promoPng from '../../../../images/main/cake-main.webp'
import { useAppSelector } from '../../../../hooks/store'
import { IProductsPersistState } from '../../../../store/reducers/ProductsSlice/ProductsSlice'

const Promo: FC = () => {

    const navigate = useNavigate()
    const customMadeSectionId = useAppSelector((state) => {
        const customMadeSection = (state.productReducer as IProductsPersistState).productsSections.find(({ title }) => title.toLowerCase() === 'custom made')
        return customMadeSection?.id
    })

    return (
        <Wrapper>
            <Container>
                <Title>
                    We will bring <br></br> your ideas to life
                </Title>
                <Subtitle>
                    Step into a world of mouthwatering confections that will make your celebrations truly unforgettable
                </Subtitle>
                <Button onClick={() => navigate(`sections/${customMadeSectionId}`)}>Custom Made</Button>
            </Container>
            <ImageWrapper>
                <Image src={promoPng} />
            </ImageWrapper>
        </Wrapper>

    )
}

export default Promo
