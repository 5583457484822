import { FC, useEffect } from 'react'
import { IPrice, IPriceVariant } from '../../../../Types/IProduct'
import styled from 'styled-components'
import PriceInterval from './Components/PriceInterval/PriceInterval'
import { Title, Wrapper } from './PriceStyled'
import PriceVariants from './Components/PriceVariants/PriceVariants'

interface PriceProps {
    price: IPrice
    onChangeVariant: (value: IPriceVariant) => void
}

const Price: FC<PriceProps> = ({ price, onChangeVariant }) => {
    return (
        <Wrapper>
            {price.type === 'only' && (
                <Title>
                    {price.currency}
                    {price.value} {price.unit}
                </Title>
            )}
            {price.type === 'request' && <Title>{price.value}</Title>}
            {price.type === 'custom' && <Title>{price.value}</Title>}
            {price.type === 'interval' && <PriceInterval price={price} />}
            {price.type === 'variants' && (
                <PriceVariants
                    onChangeVariant={onChangeVariant}
                    price={price}
                />
            )}
        </Wrapper>
    )
}

export default Price
