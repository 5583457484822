import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Arrow, Container, LinkItem, Wrapper } from './BreadcrumbsStyled'
import { generateLink } from './helpers/generateLink'
import { ILink } from '../../Types/ILink'
import { useAppSelector } from '../../hooks/store'
import { IProductsPersistState } from '../../store/reducers/ProductsSlice/ProductsSlice'

const Breadcrumbs: FC = () => {

    const location = useLocation()
    const [routesData, setRoutesData] = useState<ILink[]>([])
    const { productsSections } = useAppSelector(state => state.productReducer as IProductsPersistState)

    useEffect(() => {
        setRoutesData(generateLink(location.pathname, productsSections))
    }, [location])

    return (
        <Wrapper>
            <Container>
                <LinkItem to='/'>Home</LinkItem>
                <Arrow />
            </Container>
            {routesData.map((route, i) => i !== routesData.length - 1 ? (
                    <Container key={i}>
                        <LinkItem to={route.path}>{route.title}</LinkItem>
                        <Arrow />
                    </Container>
                ) : (
                    <Container key={i}>
                        <LinkItem to={route.path}>{route.title}</LinkItem>
                    </Container>
                )
            )}
        </Wrapper>
    )
}

export default Breadcrumbs
