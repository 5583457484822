import { AppDispatch } from '../../store'
import { instance } from '../../../API/baseAPI'
import { ITransaction } from '../../../Types/ITransactions'
import { setTransactions } from './TransactionsSlice'
import { errorHandler } from '../../Helpers/errorHandler'

export const getTransactions = (data: { clientId: string }) => async (dispatch: AppDispatch) => {
    try {
        const response = await instance.post<ITransaction[]>('payments/customer/transactions', data)
        dispatch(setTransactions(response.data))
    } catch (err: any) {
        dispatch(errorHandler(err))
    }
}