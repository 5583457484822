import styled, { css } from 'styled-components'
import { MainText } from '../../../../UI/Common/common'
import { SectionName } from '../../ProductPageStyled'

export const Wrapper = styled.div`
  margin: 40px 0 0 0;
  width: 80%;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 30px 0 0 0;
    width: 100%;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

export const Title = styled(SectionName)`
  margin: 0 0 0 0;
  line-height: 21px;
`

interface ContainerProps {
    isOpen: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: none;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  background-color: ${({ theme }) => theme.colors.background};

  ${({ isOpen }) =>
          isOpen &&
          css`
            display: grid;
          `}
`

interface FilingWrapperProps {
    isActive?: boolean
}

export const FilingWrapper = styled.div<FilingWrapperProps>`
  margin: 15px 0;
  padding: 10px 15px 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.graphics};
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;

  ${({ isActive }) =>
          isActive &&
          css`
            margin: 15px 0 0 0;
            background-color: ${({ theme }) => theme.colors.graphics};
            color: ${({ theme }) => theme.colors.background};
          `} @media (${({ theme }) => theme.media.mobile}) {
  padding: 10px 10px 10px 0px;
  width: 100%;
}
`

export const FilingImageWrapper = styled.div`
  position: relative;
  margin: 0 20px 0 0;
  width: 30%;
  padding: 21% 0 0 0;
  overflow: hidden;

  @media (${({ theme }) => theme.media.mobile}) {
    width: 30%;
    margin: 0 0 0 0;
  }
`

export const FilingImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
`

export const FilingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const FilingTitle = styled(MainText)`
  margin: 0 0 0 0;

  @media (${({ theme }) => theme.media.mobile}) {
    font-size: 14px;
    line-height: 16px;
  }
`

export const FilingDescription = styled.div`
  margin: 6px 0 0 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`

export const FilingSections = styled(MainText)`
  padding: 0 0 0 5px;
  margin: 0 0 5px 0;
  font-size: 12px;
  line-height: 12px;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 0 0 2px 0;
    font-size: 10px;
    line-height: 12px;
  }
`
