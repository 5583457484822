import styled from 'styled-components'
import { MainTextCss } from '../../../../UI/Text/Text'

export const List = styled.div`
  margin: 30px 0 0 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 10px 0;
`

export const ListItem = styled.p`
  ${MainTextCss}
`

export const Bar = styled.div`
  margin: 40px 0 0 0;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: 1fr;
  grid-gap: 0 40px;
`