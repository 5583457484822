import styled, { css } from 'styled-components'
import { MainText } from '../../../../UI/Common/common'
import { ButtonMain, ButtonMainClear } from '../../../../UI/Buttons/ButtonMain'

export const Wrapper = styled.div`
    padding: 20px 0;
    display: grid;
    grid-template-columns: 220px 1fr 200px;
    grid-template-rows: 1fr;
    grid-gap: 0 40px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graphics};

    @media (${({ theme }) => theme.media.mobile}) {
        padding: 10px;
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;
        grid-gap: 30px 0;
        border: none;
        background-color: ${({ theme }) => theme.colors.graphics};
        border-radius: 4px;
    }
`

export const Info = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 10px 0;
    justify-items: start
`

export const Products = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    grid-gap: 10px 0;
`

export const Table = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 10px 0;
`

export const Line = styled.div`
    display: grid;
    grid-template-columns: 1fr 30px 75px;
    grid-template-rows: 1fr;
    grid-gap: 0 30px;
`

export const FinalLine = styled(Line)`
    margin: auto 0 0 0;
    padding: 0 0 0 7px;
    background-color: ${({ theme }) => theme.colors.graphics};
    border-radius: 4px;

    @media (${({ theme }) => theme.media.mobile}) {
        background-color: ${({ theme }) => theme.colors.background};
    }
`

export const TextContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    justify-content: center;
    grid-gap: 7px 0;
`

interface TextProps {
    isExcel?: boolean
    crossed?: boolean
}

export const Text = styled.div<TextProps>`
    ${MainText};

    ${({ isExcel }) => isExcel && css`
        padding: 2px 4px;
        background-color: ${({ theme }) => theme.colors.graphics};
        color: ${({ theme }) => theme.colors.background};
        border-radius: 4px;
    `};

    ${({ crossed }) => crossed && css`
        opacity: 0.5;
        text-decoration: line-through;
    `};

    @media (${({ theme }) => theme.media.mobile}) {
        color: ${({ theme }) => theme.colors.background};

        ${({ isExcel }) => isExcel && css`
            background-color: ${({ theme }) => theme.colors.background};
            color: ${({ theme }) => theme.colors.graphics};
        `};
    }

`

export const TextCenter = styled(Text)`
    text-align: center;
`

export const TextDark = styled(Text)`
    color: ${({ theme }) => theme.colors.background};

    @media (${({ theme }) => theme.media.mobile}) {
        color: ${({ theme }) => theme.colors.graphics};
    }
`

export const Bar = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 15px 0;
`

export const Button = styled(ButtonMain)`
    width: 100%;

    @media (${({ theme }) => theme.media.mobile}) {
        background-color: ${({ theme }) => theme.colors.background};
        color: ${({ theme }) => theme.colors.graphics};
    }
`