import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.graphics};
    font-size: 16px;
    letter-spacing: 0.7px;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    --base-scale: calc(100vw / 75);
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;

    @media (${({ theme }) => theme.media.mobile}) {
      width: 1px;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.graphics};
    border-radius: 7px;
  }


  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.graphics};
    transition: background-color 5000s ease-in-out 0s;
  }
`

export default GlobalStyle
