import styled from 'styled-components'
import { MainText, MainTitle } from '../../UI/Common/common'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const Title = styled(MainTitle)`
  margin: 40px 0 60px 0;

  @media (${({ theme }) => theme.media.mobile}) {
    text-align: center;
  }
`

export const Text = styled(MainText)`

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 40px 0 0 0;
  }
`

export const Container = styled.div`
  margin: 0 0 60px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (${({ theme }) => theme.media.mobile}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`

export const Image = styled.img.attrs({
    alt: 'About'
})`
  width: 40%;

  @media (${({ theme }) => theme.media.mobile}) {
    width: 100%;
  }
`
export const Description = styled.div`
  width: 55%;

  @media (${({ theme }) => theme.media.mobile}) {
    width: 100%;
  }
`

export const DescriptionTitle = styled.h2`
  margin: 0 0 30px 0;
  padding: 0;
  font-size: 28px;
  font-weight: 300;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 20px 0;
    text-align: center;
  }
`
