import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAddressData, IUser } from '../../../Types/IUser'
import { IChangeAddress, IChangeImages, IRemoveAddress } from './Types'
import { IOrder } from '../../../Types/IOrder'

interface IInitialState {
    user: IUser | null
    userIsLoading: boolean
}

const initialState: IInitialState = {
    user: null,
    userIsLoading: false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<IUser | null>) {
            state.user = action.payload
        },
        addUserAddress(state, action: PayloadAction<IAddressData>) {
            if (!state.user) return
            state.user.addressesData = [...state.user.addressesData, action.payload]
        },
        removeUserAddress(state, action: PayloadAction<IRemoveAddress>) {
            if (!state.user) return
            state.user.addressesData = state.user.addressesData.filter(
                ({ id }) => id !== action.payload.addressId
            )
        },
        changeUserAddress(state, action: PayloadAction<IChangeAddress>) {
            if (!state.user) return
            state.user.addressesData = state.user.addressesData.map((address) =>
                address.id === action.payload.address.id ? action.payload.address : address)
        },
        addNewOrder(state, action: PayloadAction<IOrder>) {
            if (!state.user) return
            state.user.orders = [...state.user.orders, action.payload]
        },
        changeImages(state, action: PayloadAction<IChangeImages>) {
            if (!state.user) return
            const { orderId, images } = action.payload
            state.user.orders = state.user.orders.map(order => order.id === orderId ? { ...order, images } : order)
        }
    }
})

export const {
    setUser,
    addUserAddress,
    removeUserAddress,
    changeUserAddress,
    addNewOrder,
    changeImages
} = userSlice.actions

export default userSlice.reducer