import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { yupValidation } from '../../../../Constants/yupValidation'
import {
    ButtonSubmit,
    Fields,
    Form,
    FormLink,
    FormMessage,
    FormTitle
} from '../../../../UI/Form/FormStyled'
import FieldText from '../../../../components/Fields/FieldText/FieldText'
import { useAppDispatch } from '../../../../hooks/store'
import {
    recoveryConfirm,
    recoveryPassword
} from '../../../../store/reducers/User/ActionCreators'
import { TabKey } from '../../Layout'
import styled from 'styled-components'
import { MainTextCss } from '../../../../UI/Text/Text'

export const Container = styled.div`
    margin: 20px 0 0 0;
    width: 100%;
    height: 122px;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 10px;
`

export const Line = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

export const Timer = styled.p`
    margin: 30px 5px 0 5px;
    width: 20px;
    ${MainTextCss};
`

interface FormLoginProps {
    openTab: TabKey
    setOpenTab: Dispatch<SetStateAction<TabKey>>
}

const FormLogin: FC<FormLoginProps> = ({ openTab, setOpenTab }) => {
    const dispatch = useAppDispatch()
    const [codeIsSend, setCodeIsSend] = useState(false)
    const [codeIsVisible, setCodeIsVisible] = useState<boolean>(false)
    const [startTimer, setStartTimer] = useState(false)
    const [timer, setTimer] = useState(60)
    const { values, touched, errors, handleChange, handleSubmit } = useFormik({
        initialValues: {
            email: '',
            code: ''
        },
        validationSchema: Yup.object({
            email: yupValidation.email
        }),
        onSubmit: async (values, { resetForm }) => {
            if (!values.code && !codeIsSend) {
                const result = await dispatch(recoveryPassword(values.email))
                if (result) {
                    setCodeIsVisible(true)
                    setCodeIsSend(true)
                    setStartTimer(true)
                }
                return
            }
            if (codeIsSend && values.code) {
                const result = await dispatch(recoveryConfirm(values))
                if (result) {
                    setOpenTab('change')
                    resetForm()
                }
            }
        }
    })

    useEffect(() => {
        if (!startTimer) return
        if (timer <= 0) {
            setStartTimer(false)
        }
        const interval = setInterval(() => {
            setTimer(timer - 1)
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [timer, startTimer])

    const getNewCodeHandler = () => {
        if (timer !== 0) return
        dispatch(recoveryPassword(values.email))
        setTimer(60)
        setStartTimer(true)
    }

    if (openTab !== 'recovery') return null
    return (
        <Form onSubmit={handleSubmit}>
            <FormTitle>Password Recovery</FormTitle>
            <Fields>
                <FieldText
                    name="email"
                    value={values.email}
                    placeholder="Email"
                    onChange={handleChange}
                    isTouched={touched.email}
                    error={errors.email}
                    type="email"
                />
                <FieldText
                    name="code"
                    value={values.code}
                    placeholder="Code"
                    onChange={handleChange}
                    isTouched={touched.code}
                    error={errors.code}
                    readonly={!codeIsVisible}
                />
            </Fields>
            {codeIsVisible ? (
                <>
                    {timer !== 0 ? (
                        <Line>
                            <FormMessage>You can get a new code in</FormMessage>
                            <Timer>{timer} </Timer>
                        </Line>
                    ) : (
                        <FormMessage>
                            <FormLink onClick={getNewCodeHandler}>
                                Get a new code
                            </FormLink>
                        </FormMessage>
                    )}
                </>
            ) : (
                <FormMessage>
                    Enter your email to receive the recovery code
                </FormMessage>
            )}
            <ButtonSubmit type="submit">
                {!codeIsSend ? 'Get Code' : 'Submit'}
            </ButtonSubmit>
            <FormMessage>
                Back to{' '}
                <FormLink onClick={() => setOpenTab('login')}>log in</FormLink>
            </FormMessage>
        </Form>
    )
}

export default FormLogin
