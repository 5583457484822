import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.125s;

  :hover {
    opacity: 1;
  }
`

export const Message = styled.p`
  margin: 0 10px 0 0;
  font-size: 12px;
`


