import { FC, ReactNode, useEffect } from 'react'
import { hideScrollBar, showScrollBar } from '../../utils/changeScrollBar'
import { ButtonClose, Container, Wrapper } from './ModalStyled'
import { useAppSelector } from '../../hooks/store'

interface ModalProps {
    isVisible: boolean
    children: ReactNode
    onClose: () => void
}

const Modal: FC<ModalProps> = ({ isVisible, children, onClose }) => {
    const { mobile } = useAppSelector((state) => state.appConditionReducer)

    useEffect(() => {
        if (isVisible) {
            hideScrollBar(mobile)
        } else {
            showScrollBar(mobile)
        }
    }, [isVisible])

    const closeHandler = () => {
        onClose()
    }

    return (
        <Wrapper isVisible={isVisible} onClick={closeHandler}>
            <Container
                isVisible={isVisible}
                onClick={(e) => e.stopPropagation()}
            >
                {children}
                <ButtonClose onClick={closeHandler} />
            </Container>
        </Wrapper>
    )
}

export default Modal
