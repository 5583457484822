import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface IInitialState {
    mobile: boolean
    message: string
}

const initialState: IInitialState = {
    mobile: false,
    message: ''
}

export const appConditionSlice = createSlice({
    name: 'appCondition',
    initialState,
    reducers: {
        setMobile(state) {
            state.mobile = true
        },
        showMessage(state, action: PayloadAction<string>) {
            state.message = action.payload
        },
        hideMessage(state) {
            state.message = ''
        }
    }
})

export const {
    setMobile,
    showMessage,
    hideMessage
} = appConditionSlice.actions

export default appConditionSlice.reducer
