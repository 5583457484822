import styled from 'styled-components'

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-gap: 40px 40px;

    @media (${({ theme }) => theme.media.mobile}) {
        grid-template-columns: 1fr;
        grid-gap: 20px 0;
    }
`
