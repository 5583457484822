import { FC } from 'react'
import { TableContainer, TableLine, TableText, TextContainer } from '../../OrderStyled'
import { IOrderProduct } from '../../../../Types/IOrder'
import { getProductSum } from '../../../../utils/getProductSum'
import { getCostWithSale } from '../../../../utils/getCostWithSale'

interface OrderProductProps {
    product: IOrderProduct
}

const OrderProduct: FC<OrderProductProps> = ({ product }) => {

    const productCost = !product.cost || product.cost === '0' ? 'Awaiting' : `£ ${product.cost}`
    const productSum = Number(product.cost) ? `£ ${getProductSum(product)}` : '-'

    return (
        <TableLine key={product.id}>
            <TableText>{product.name}</TableText>
            <TextContainer>
                {product.options.map((option, i) => (
                    <TableText key={i}>
                        {option.value}
                    </TableText>
                ))}
            </TextContainer>
            <TableText>{product.quantity}</TableText>
            <TableContainer>
                <TableText crossed={!!product.sale}>{productCost}</TableText>
                {(product.cost && product.sale) && (
                    <TableText>£ {getCostWithSale(product.cost, product.sale)}</TableText>
                )}
            </TableContainer>
            <TableText> {productSum}</TableText>
        </TableLine>
    )
}

export default OrderProduct