import React, { FC } from 'react'
import LinkMore from '../../../../../../UI/Links/linkMore/LinkMore'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (${({ theme }) => theme.media.mobile}) {
    align-items: flex-start;
  }
`

export const CardTitle = styled.div`
  font-family: 'Theonory';
  font-size: 24px;
`

export const CardDescription = styled.p`
  margin: 15px 0 25px 0;
  font-size: 14px;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 30px 0 15px 0;
  }
`

interface CardProps {
    title: string
    description: string
    path: string
}

const Card: FC<CardProps> = ({
                                 title,
                                 description,
                                 path
                             }) => {

    return (
        <Wrapper>
            <CardTitle>{title}</CardTitle>
            <CardDescription>{description}</CardDescription>
            <LinkMore message='See all Categories' path={path} />
        </Wrapper>
    )
}

export default Card