import { FC, useState } from 'react'
import { useFormikContext } from 'formik'
import { ICartInitialValues } from '../../../../Components/CartFormik/CartFormik'
import {
    Line
} from '../../CartDeliveryStyled'
import FieldAddress, {
    IFieldAddressData
} from '../../../../../../components/Fields/FieldAddress/FieldAddress'
import FieldNumber from '../../../../../../components/Fields/FieldNumber/FieldNumber'
import FieldText from '../../../../../../components/Fields/FieldText/FieldText'
import { useAppSelector } from '../../../../../../hooks/store'
import { ButtonDropDown } from '../../../../../../UI/Buttons/ButtonsIcon'
import AddressesDropDown from '../../../../Components/AddressesDropDown/AddressesDropDown'
import { IAddressData } from '../../../../../../Types/IUser'
import {
    Container,
    Header,
    Text,
    Wrapper,
    Title,
    DropDownContainer,
    InfoTitle,
    ButtonAdd, ButtonAddMobile
} from '../MethodsStyled'

interface RoyalMailMethodProps {
    onOpenForm: () => void
}

const RoyalMailMethod: FC<RoyalMailMethodProps> = ({ onOpenForm }) => {
    const { user } = useAppSelector((state) => state.userReducer)
    const { mobile } = useAppSelector((state) => state.appConditionReducer)
    const [containerIsOpen, setContainerIsOpen] = useState<boolean>(false)
    const { values, touched, errors, handleChange, setFieldValue } =
        useFormikContext<ICartInitialValues>()

    const changeAddressHandler = (data: IFieldAddressData) => {
        setFieldValue('deliveryPlace', {
            address: data.address,
            postCode: data.postCode,
            isPaidParking: data.isCongestionZone,
            deliveryDistance: data.deliveryDistance,
            floor: '',
            flat: ''
        })
    }

    const selectAddressHandler = (address: IAddressData) => {
        setFieldValue('deliveryPlace', {
            address: address.address,
            postCode: address.postCode,
            flat: address.flat,
            floor: address.floor,
            isPaidParking: address.isCongestionZone,
            deliveryDistance: address.deliveryDistance.distance
        })
    }

    const clearAddressHandler = () => {
        setFieldValue('deliveryPlace', {
            address: '',
            postCode: '',
            flat: '',
            floor: '',
            isPaidParking: false,
            deliveryDistance: ''
        })
    }

    if (!mobile) {
        return (
            <>
                <Line>
                    {user ? (
                        <DropDownContainer>
                            <Title>Choose Addresss</Title>
                            <AddressesDropDown
                                title='Addresses'
                                value={values.deliveryPlace.address}
                                options={user.addressesData}
                                onChange={selectAddressHandler}
                                onClear={clearAddressHandler}
                                isTouched={touched.deliveryPlace?.address}
                                error={errors.deliveryPlace?.address}
                            />
                        </DropDownContainer>
                    ) : (
                        <FieldAddress
                            name='deliveryPlace.address'
                            placeholder='Delivery Address'
                            onChoice={changeAddressHandler}
                            isTouched={touched.deliveryPlace?.address}
                            error={errors.deliveryPlace?.address}
                        />
                    )}
                    {(values.deliveryPlace.address || !user) && (
                        <Line>
                            <Line>
                                <FieldNumber
                                    name='deliveryPlace.floor'
                                    value={values.deliveryPlace.floor}
                                    placeholder='Floor'
                                    onChange={handleChange}
                                    isTouched={touched.deliveryPlace?.floor}
                                    error={errors.deliveryPlace?.floor}
                                />
                                <FieldNumber
                                    name='deliveryPlace.flat'
                                    value={values.deliveryPlace.flat}
                                    placeholder='Flat'
                                    onChange={handleChange}
                                    isTouched={touched.deliveryPlace?.flat}
                                    error={errors.deliveryPlace?.flat}
                                />
                            </Line>
                            <FieldText
                                name='deliveryPlace.postCode'
                                value={values.deliveryPlace.postCode}
                                placeholder='Post Code'
                                onChange={handleChange}
                                isTouched={touched.deliveryPlace?.postCode}
                                error={errors.deliveryPlace?.postCode}
                            />
                        </Line>
                    )}
                    {(user && !values.deliveryPlace.address) && (<ButtonAdd onClick={onOpenForm}>+</ButtonAdd>)}
                </Line>
                <Wrapper>
                    <Header onClick={() => setContainerIsOpen(!containerIsOpen)}>
                        <InfoTitle>Royal Mail</InfoTitle>
                        <ButtonDropDown isOpen={containerIsOpen} />
                    </Header>
                    <Container isHidden={!containerIsOpen}>
                        <Text>
                            * In that case, Jelena's Cake Ltd is not responsible
                            for the safety of the products in transit, since we
                            are unable to control all the aspects of
                            transportation
                        </Text>
                        <Text>
                            Order collection is also available and it is free of
                            charge. In that case, Jelena's Cake Ltd is not
                            responsible for the safety of the products in
                            transit, since we are unable to control all the
                            aspects of transportation and all responsibility
                            rests on the person who collected the order
                        </Text>
                    </Container>
                </Wrapper>
            </>
        )
    } else {
        return (
            <>
                {user ? (
                    <DropDownContainer>
                        <Title>Choose Addresss</Title>
                        <AddressesDropDown
                            title='Addresses'
                            value={values.deliveryPlace.address}
                            options={user.addressesData}
                            onChange={selectAddressHandler}
                            onClear={clearAddressHandler}
                            isTouched={touched.deliveryPlace?.address}
                            error={errors.deliveryPlace?.address}
                        />
                        {!values.deliveryPlace.address && (
                            <ButtonAddMobile onClick={onOpenForm}>
                                Add New Address
                            </ButtonAddMobile>
                        )}
                    </DropDownContainer>
                ) : (
                    <FieldAddress
                        name='deliveryPlace.address'
                        placeholder='Deliveri Address'
                        onChoice={changeAddressHandler}
                        onClear={clearAddressHandler}
                        isTouched={touched.deliveryPlace?.address}
                        error={errors.deliveryPlace?.address}
                    />
                )}
                <Line>
                    <FieldNumber
                        name='deliveryPlace.floor'
                        value={values.deliveryPlace.floor}
                        placeholder='Floor'
                        onChange={handleChange}
                        isTouched={touched.deliveryPlace?.floor}
                        error={errors.deliveryPlace?.floor}
                    />
                    <FieldNumber
                        name='deliveryPlace.flat'
                        value={values.deliveryPlace.flat}
                        placeholder='Flat'
                        onChange={handleChange}
                        isTouched={touched.deliveryPlace?.flat}
                        error={errors.deliveryPlace?.flat}
                    />
                </Line>
                <FieldText
                    name='deliveryPlace.postCode'
                    value={values.deliveryPlace.postCode}
                    placeholder='Postal Code'
                    onChange={handleChange}
                    isTouched={touched.deliveryPlace?.postCode}
                    error={errors.deliveryPlace?.postCode}
                />
                <Wrapper>
                    <Header
                        onClick={() => setContainerIsOpen(!containerIsOpen)}
                    >
                        <Title>Royal Mail</Title>
                        <ButtonDropDown isOpen={containerIsOpen} />
                    </Header>
                    <Container isHidden={!containerIsOpen}>
                        <Text>
                            Order will be send by Royal Mail Postage*
                        </Text>
                        <Text>
                            * In that case, Jelena's Cake Ltd is not responsible
                            for the safety of the products in transit, since we
                            are unable to control all the aspects of
                            transportation
                        </Text>
                        <Text>
                            Order collection is also available and it is free of
                            charge. In that case, Jelena's Cake Ltd is not
                            responsible for the safety of the products in
                            transit, since we are unable to control all the
                            aspects of transportation and all responsibility
                            rests on the person who collected the order
                        </Text>
                    </Container>
                </Wrapper>
            </>
        )
    }
}

export default RoyalMailMethod
