import React, { FC } from 'react'
import { Button, Container, Image, Text, Title, Wrapper } from './GiftStyled'
import { useNavigate } from 'react-router'

const Gift: FC = () => {
    const navigate = useNavigate()

    return (
        <Wrapper>
            <Image />
            <Container>
                <Title>
                    You can place <br></br> an order from any part of the world
                </Title>
                <Text>
                    And congratulate your relatives, friends or business
                    partners on an important event in their life. We will listen
                    to all your wishes, help you make a choice and will be more
                    than happy to deliver your gift across all of London
                </Text>
                <Button onClick={() => navigate('/custom_made')}>Enquiry</Button>
            </Container>
        </Wrapper>
    )
}

export default Gift
