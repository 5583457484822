import styled from 'styled-components'
import logoSvg from '../../images/main/Logo.svg'

export const MainTitle = styled.h2`
  font-family: 'Theonory', sans-serif;
  font-weight: 200;
  font-size: 46px;
  line-height: 50px;

  @media (${({ theme }) => theme.media.mobile}) {
    font-size: 28px;
    line-height: 30px;
  }
`
export const MainText = styled.p`
  margin: 0 0 20px 0;
`

export const Logo = styled.img.attrs({
    alt: 'Logo',
    src: logoSvg
})`
  width: 230px;
  height: 70px;

  @media (${({ theme }) => theme.media.mobile}) {
    width: 100%;
    height: auto;
  }
`
 
export const LineLink = styled.a`
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graphics};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.graphics};
  opacity: 1;
  transition: 0.4s;

  :hover {
    opacity: 0.8;
  }
`
