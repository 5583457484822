import React, { FC } from 'react'
import { Container, Image, Text, Title, Wrapper } from './AboutStyled'
import LinkMore from '../../../../UI/Links/linkMore/LinkMore'
import { useAppSelector } from '../../../../hooks/store'

const About: FC = () => {

    const { mobile } = useAppSelector((state) => state.appConditionReducer)

    if (!mobile) {
        return (
            <Wrapper>
                <Image />
                <Container>
                    <Title>About Us</Title>
                    <Text>
                        Today Jelena, with over twenty years of experience as a professional confectioner, deservedly
                        enjoys repeat orders and outstanding reviews from a loyal and growing client base – proving the
                        old adage that “ nothing succeeds like success”!
                    </Text>
                    <Text>
                        If you want that special occasion to be truly special, look no further. See below a selection of
                        exquisite cakes and cookies that strike the perfect balance between appearance, texture and
                        flavour, all baked to flawless perfection
                    </Text>
                    <LinkMore message='Read more' path='/about' />
                </Container>
            </Wrapper>
        )
    } else {
        return (
            <Wrapper>
                <Title>About Us</Title>
                <Image />
                <Container>
                    <Text>
                        Today Jelena, with over twenty years of experience as a professional confectioner, deservedly
                        enjoys repeat orders and outstanding reviews from a loyal and growing client base – proving the
                        old adage that “ nothing succeeds like success”!
                    </Text>
                    <Text>
                        If you want that special occasion to be truly special, look no further. See below a selection of
                        exquisite cakes and cookies that strike the perfect balance between appearance, texture and
                        flavour, all baked to flawless perfection
                    </Text>
                    <LinkMore message='Read more' path='/about' />
                </Container>
            </Wrapper>
        )
    }
}

export default About
