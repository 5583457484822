import React, { FC } from 'react'
import { Container, Subtitle, Title, Wrapper } from './ShowcaseStyled'
import { useAppSelector } from '../../../../hooks/store'
import Section from './Components/Section/Section'
import { IProductsPersistState } from '../../../../store/reducers/ProductsSlice/ProductsSlice'

const Showcase: FC = () => {

    const productsSections = useAppSelector((state) =>
        (state.productReducer as IProductsPersistState).productsSections
    )

    return (
        <Wrapper>
            <Title>
                Our products
            </Title>
            <Subtitle>
                We will listen to all your wishes, pay attention to important little things
                <br></br>and bring your ideas to life
            </Subtitle>
            <Container>
                {productsSections.slice(0, 2).map(section => <Section section={section} key={section.id} />)}
            </Container>
        </Wrapper>
    )
}

export default Showcase
