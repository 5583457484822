import React, { FC, useEffect } from 'react'
import { useLocation } from 'react-router'
import { ButtonClose, Text, Wrapper } from './MessageStyled'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { hideMessage } from '../../store/reducers/AppCondition/AppConditionSlice'


const Message: FC = () => {

    const location = useLocation()
    const dispatch = useAppDispatch()
    const { message } = useAppSelector((state) => state.appConditionReducer)

    function closeMessage() {
        dispatch(hideMessage())
    }

    useEffect(() => {
        closeMessage()
    }, [location])

    useEffect(() => {
        if (message) {
            let timer = setTimeout(() => {
                closeMessage()
            }, 5000)

            return () => clearTimeout(timer)
        }
    }, [message])


    return (
        <Wrapper isVisible={!!message}>
            <ButtonClose onClick={closeMessage} />
            <Text>{message}</Text>
        </Wrapper>
    )
}

export default Message
