import React, { FC } from 'react'
import { useAppSelector } from '../../../../hooks/store'
import { IProductsSection } from '../../../../Types/IProduct'
import { linkList } from '../../Constants/linkList'
import { useNavigate } from 'react-router'
import {
    Menu,
    MenuBar,
    MenuBurgerMobile,
    MenuButton,
    MenuButtonIcon, MenuButtonIndicator, MenuInfoMobile,
    MenuLink,
    MenuLinks,
    Wrapper
} from '../../HeaderStyled'
import { Link } from 'react-router-dom'
import { Logo } from '../../../../UI/Common/common'
import { iconCart, iconProfile } from '../../../../UI/Icons/icons'
import { ICardPersistState } from '../../../../store/reducers/CartSlice/CartSlice'

interface HeaderProps {
    onProfileClick: () => void
}

const Header: FC<HeaderProps> = ({ onProfileClick }) => {

    const { products } = useAppSelector((state) => state.persistCardReducer as ICardPersistState)
    const productsSections = useAppSelector((state) => state.productReducer.productsSections as IProductsSection[])
    const productsLinks = productsSections.map(section => ({
        title: section.title,
        path: `sections/${section.id}`
    }))
    const menuLinks = [...productsLinks, ...linkList]

    const navigate = useNavigate()

    const cardClickHandler = () => {
        navigate('/cart')
    }

    return (
        <Wrapper>
            <Link to='/'>
                <Logo />
            </Link>
            <Menu>
                <MenuLinks>
                    {menuLinks.map((link, i) => (
                        <MenuLink
                            to={link.path}
                            className={({ isActive }) => isActive ? 'active' : 'noActive'}
                            key={i}
                        >{link.title}</MenuLink>
                    ))}
                </MenuLinks>
                <MenuBar>
                    <MenuButton onClick={cardClickHandler}>
                        <MenuButtonIcon src={iconCart} />
                        {!!products.length && <MenuButtonIndicator>{products.length}</MenuButtonIndicator>}
                    </MenuButton>
                    <MenuButton onClick={onProfileClick}>
                        <MenuButtonIcon src={iconProfile} />
                    </MenuButton>
                </MenuBar>
            </Menu>
        </Wrapper>
    )
}

export default Header