import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0 20px;
  display: grid;
  grid-template-columns: minmax(100px, 1040px);
  grid-template-rows: min-content 1fr min-content;
  justify-content: center;
  overflow-x: hidden;

  @media (${({ theme }) => theme.media.mobile}) {
    padding: 0 20px;
    grid-template-columns: 1fr;
  }
`

