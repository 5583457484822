import { FC, FormEvent } from 'react'
import Modal from '../../../../components/Modal/Modal'
import { Form, FormTitle } from '../../../../UI/Form/FormStyled'
import { ButtonMain } from '../../../../UI/Buttons/ButtonMain'
import { useAppDispatch } from '../../../../hooks/store'
import { removeAccount } from '../../../../store/reducers/User/ActionCreators'
import { useNavigate } from 'react-router'
import { Bar, List, ListItem } from './RemoveAccountStyled'

interface RemoveAccountProps {
    isVisible: boolean
    onClose: () => void
    userId: string
}

const RemoveAccount: FC<RemoveAccountProps> = ({ isVisible, onClose, userId }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const submitHandler = (e: FormEvent) => {
        e.preventDefault()
        navigate('/')
        dispatch(removeAccount({ clientId: userId }))
    }

    return (
        <Modal isVisible={isVisible} onClose={onClose}>
            <Form onSubmit={submitHandler}>
                <FormTitle>Delete Account</FormTitle>
                <List>
                    <ListItem>Deleting this account will also delete its data:</ListItem>
                    <ListItem>- your account personal information </ListItem>
                    <ListItem>- your order history </ListItem>
                </List>
                <Bar>
                    <ButtonMain type='button' onClick={onClose}>Cancel</ButtonMain>
                    <ButtonMain type='submit'>Delete</ButtonMain>
                </Bar>
            </Form>
        </Modal>
    )
}

export default RemoveAccount