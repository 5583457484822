import { FC, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import CartFormik from './Components/CartFormik/CartFormik'
import CartMain from './Components/CartMain/CartMain'
import { TitleCenter, Wrapper } from './CartStyled'
import CartMessage from './Components/CartMessage/CartMessage'
import { clearProducts, ICardPersistState } from '../../store/reducers/CartSlice/CartSlice'
import { useNavigate, useParams } from 'react-router'

const Cart: FC = () => {
    const dispatch = useAppDispatch()
    const cartAction = useParams().action
    const navigate = useNavigate()
    const isProducts = useAppSelector(
        (state) =>
            !!(state.persistCardReducer as ICardPersistState).products.length
    )

    const [messageIsVisible, setMessageIsVisible] = useState<boolean>(false)

    useEffect(() => {
        if (cartAction) {
            if (cartAction === 'isPaid') {
                dispatch(clearProducts())
                navigate('/cart')
                setMessageIsVisible(true)
            }
        }
    }, [])

    if (isProducts && !cartAction) {
        return (
            <CartFormik onShowMessage={() => setMessageIsVisible(true)}>
                <CartMain />
            </CartFormik>
        )
    } else {
        return (
            <Wrapper>
                <CartMessage isVisible={messageIsVisible} onHide={() => setMessageIsVisible(false)} />
                <TitleCenter>Cart is empty</TitleCenter>
            </Wrapper>
        )
    }
}

export default Cart
