import { FC, forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
    Container,
    DateLabel,
    Error,
    IconCalendar,
    Placeholder,
    Value
} from '../FieldsStyled'
import { FormikErrors, FormikTouched } from 'formik'
import { useAppSelector } from '../../../hooks/store'
import { IAppOptionsState } from '../../../store/reducers/AppOptions/AppOptionsSlice'

interface FieldOrderDateProps {
    name: string
    value: string
    placeholder: string
    onChange: (field: string, value: any, shouldValidate?: boolean) => void
    isTouched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
    error:
        | string
        | string[]
        | FormikErrors<any>
        | FormikErrors<any>[]
        | undefined
}

const FieldOrderDate: FC<FieldOrderDateProps> = ({
                                                     name,
                                                     value,
                                                     placeholder,
                                                     onChange,
                                                     isTouched,
                                                     error
                                                 }) => {
    const { closeDates, closeWeekDays } = useAppSelector(
        (state) => (state.appOptionsReducer as IAppOptionsState).closeOrderDates
    )
    const today = new Date()
    const ExampleCustomInput = forwardRef<HTMLDivElement>((props: any, ref) => (
        <Container isChosen={!!value} onClick={props.onClick} ref={ref}>
            <Value>{props.value ? props.value : 'Select Date'}</Value>
            <IconCalendar isChosen={!!value} />
        </Container>
    ))

    const changeHandler = (value: Date | null) => {
        onChange(name, value)
    }

    return (
        <DateLabel>
            <Placeholder>{placeholder}</Placeholder>
            <DatePicker
                name='date'
                dateFormat='dd.MM.yyyy'
                onChange={changeHandler}
                customInput={<ExampleCustomInput />}
                excludeDates={closeDates.map((date) => new Date(date))}
                selected={value ? new Date(value) : null}
                filterDate={(date) => {
                    return !closeWeekDays.includes(date.getDay())
                }}
                dropdownMode='select'
                minDate={
                    new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate() + 3
                    )
                }
                calendarStartDay={1}
            />
            {isTouched && error && <Error>{String(error)}</Error>}
        </DateLabel>
    )
}

export default FieldOrderDate
