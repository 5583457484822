import { FC } from 'react'
import { IAddress, IAddressData } from '../../../../Types/IUser'
import { ButtonPlus } from '../../../../UI/Buttons/ButtonsIcon'
import Address from '../Address/Address'
import { Container, Header, Title, Wrapper } from './AddressesStyled'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { IAppOptionsState } from '../../../../store/reducers/AppOptions/AppOptionsSlice'
import { addAddress } from '../../../../store/reducers/User/ActionCreators'

interface AddressesProps {
    userId: string
    addresses: IAddressData[]
}

const testAddress = {
    id: '12',
    address: '',
    postCode: '',
    flat: '',
    floor: '',
    isCongestionZone: false,
    deliveryDistance: {
        origin: '',
        destination: '',
        distance: '',
        cost: ''
    }
}

const Addresses: FC<AddressesProps> = ({
                                           userId,
                                           addresses
                                       }) => {
    const dispatch = useAppDispatch()
    const { originDeliveryAddress } = useAppSelector((state) => (
        state.appOptionsReducer as IAppOptionsState).appDelivery
    )

    const addAddressHandler = () => {
        dispatch(addAddress({ clientId: userId }))
    }


    return (
        <Wrapper>
            <Header>
                <Title>Addresses</Title>
                <ButtonPlus onClick={addAddressHandler} type='button' />
            </Header>
            <Container>
                {addresses.map(address => (
                    <Address
                        origin={originDeliveryAddress}
                        userId={userId}
                        address={address}
                        key={address.id}
                    />
                ))}
            </Container>
        </Wrapper>
    )
}

export default Addresses