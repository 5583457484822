import styled from 'styled-components'
import giftPng from '../../../../images/main/gift.webp'
import { MainText, MainTitle } from '../../../../UI/Common/common'
import { ButtonMain } from '../../../../UI/Buttons/ButtonMain'

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (${({ theme }) => theme.media.mobile}) {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: center;
    }
`

export const Title = styled(MainTitle)``

export const Image = styled.img.attrs({
    alt: 'Gift',
    src: giftPng
})`
    width: 30%;

    @media (${({ theme }) => theme.media.mobile}) {
        margin: 40px 0 0 0;
        width: 80%;
    }
`

export const Container = styled.div`
    width: 55%;

    @media (${({ theme }) => theme.media.mobile}) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`

export const Text = styled(MainText)`
    margin: 20px 0 40px 0;

    @media (${({ theme }) => theme.media.mobile}) {
        margin: 20px 0 0 0;
    }
`

export const Button = styled(ButtonMain)`
    @media (${({ theme }) => theme.media.mobile}) {
        margin: 40px auto 0 auto;
        width: 100%;
        height: 60px;
    }
`
