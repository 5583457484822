import { FC } from 'react'
import { useParams } from 'react-router'
import { useAppSelector } from '../../hooks/store'
import { IProductsPersistState } from '../../store/reducers/ProductsSlice/ProductsSlice'
import ProductPage from './ProductPage'


export const ProductPageWrapper: FC = () => {
    const { sectionId, productId } = useParams()
    const data = useAppSelector(state => {
        if (!sectionId || !productId) return undefined
        const section = (state.productReducer as IProductsPersistState).productsSections.find(({ id }) => id === sectionId)
        if (!section) return undefined
        const product = section.products.find(({ id }) => id === productId)
        if (!product) return undefined
        return { section, product }
    })

    if (!data) {
        return null
    }

    return (
        <ProductPage sectionName={data.section.title} sectionId={data.section.id} product={data.product} />
    )
}