export const pricesData = [
    {
        title: 'CAKES',
        text: [
            'The price of the cake depends on the complexity of the design / decoration and starts from £60.00.'
        ]
    },
    {
        title: 'One tire cake:',
        text: [
            '8 inches (8-12 portions)',
            '9 inches (12-16 portions)',
            '10 inches (16-20 portions)'
        ]
    },
    {
        title: 'Two tires cake:',
        text: [
            '8 inches + 6 inches (20-25 portions)',
            '9 inches + 7 inches (25-30 portions)',
            '10 inches + 8 inches (30-35 portions)',
            'span',
            'Three and Four tire cake are also available if requested.'
        ]

    },
    {
        title: 'CUPCAKES',
        text: [
            'NewOrder quantity is 6 / 9 / 12 pcs in the box.',
            'The price of the cupcakes depends on the complexity of the design / decoration and starts from £4.00 per 1 pcs'
        ]
    },
    {
        title: 'CAKE POPS',
        text: [
            'Minimal order quantity is 12 pcs.',
            'The price of the cake pops depends on the complexity of the design / decoration and starts from £4.00 per 1 pcs.'
        ]
    },
    {
        title: 'GLAZED COOKIES',
        text: [
            'Minimal order quantity is 12 pcs.',
            'The price of the cake pops depends on the complexity of the design / decoration and starts from £4.00 per 1 pcs'
        ]
    }
]
