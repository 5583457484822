import React, { FC } from 'react'
import {
    LineContainer,
    List,
    ListItem,
    ListTitle,
    Message,
    Named,
    Text,
    Title,
    Wrapper
} from '../../UI/Text/TextPage'

const TermsPage: FC = () => {
    return (
        <Wrapper>
            <Title>Terms & Conditions</Title>
            <Named>PAYMENTS:</Named>
            <Text>
                After all the details of the order have been discussed and confirmed, the company Jelena’s Cake Ltd
                issues and sends an invoice to the
                client on their previously specified email. The client must pay the invoice within three days from the
                date it is received. If the
                client fails to comply with the terms of payment within the time period, company Jelena’s Cake Ltd has
                the rights to not comply with
                the terms of the contract. Payment due date is also indicated in the invoice sent to the client
            </Text>
            <Named>CANCELLATION</Named>
            <Text>
                No refunds will be processed for any cancellations that are made in less than 48 hours before the
                delivery or collection date.
                If you cancel the order 48 hours before the date of delivery or collection you will be charged 50% of
                the total order cost.
            </Text>
            <Named>CHANGES TO THE ORDER:</Named>
            <Text>
                It happens. We understand. What we do tend to ask is that you try to keep all changes prior to the 7
                days leading up to the delivery
                or collection date. Customers will be notified of any price changes in regards to order changes, before
                they are confirmed.
            </Text>
            <Named>DELIVERY:</Named>
            <Text>
                Delivery is available throughout all of London and beyond. The price of delivery is calculated from
                point A to point B, along the
                optimal route. We always provide a time slot for the delivery. Every delivery is individual.
            </Text>
            <Text>
                Delivery cost is £1.25 per 1 mile, the cost of delivery increases if the route passes through toll
                roads, bridges and tunnels. Also,
                the customer pays for the cost of parking when delivering orders to shopping centres and entertainment
                establishments with paid
                parking.
                All deliveries must be checked by an authorised person at the time of delivery.
            </Text>
            <Text>
                *- additional charge may apply. The Congestion Charge is a £15.00 daily charge if you drive within the
                Congestion Charge zone
                7:00-18:00 Monday – Friday and 12:00-18:00 Sat – Sun and bank holidays. No charge between Christmas Day
                and New Year’s Day bank
                holiday (inclusive).
                Almost 100% of orders are delivered on time and there was never a case when a celebration was left
                without dessert.
                But London is a huge city and there are situations on the roads that cannot be foreseen in advance,
                delays are possible.
                In any case, we do our best to ensure that your order is delivered on time.
            </Text>
            <Named>
                PLEASE NOTE: PRICES AND AVAILABLITY OF THE LISTED PRODUCTS ARE SUBJECT TO CHANGE WITHOUT NOTICE
            </Named>
            <Named>
                COLLECTION:
            </Named>
            <Text>
                Order collection is also available and it is free of charge. In that case, Jelena's Cake Ltd is not
                responsible for the safety of the
                products in transit, since we are unable to control all the aspects of transportation and all
                responsibility rests on the person who
                collected the order.
            </Text>
            <Named>ALLERGY ADVICE:</Named>
            <Text>
                All products are made in a kitchen that handles NUTS, DAIRY, EGGS, GLUTEN, SOYA, PEANUTS and other
                ALLERGENIC ingredients.
            </Text>
            <Named>STORAGE INSTRUCTION:</Named>
            <LineContainer>
                <List>
                    <ListTitle>CAKES</ListTitle>
                    <ListItem>Store refrigerated at max +5C.</ListItem>
                    <ListItem>Consume within 3 days and </ListItem>
                    <ListItem>by the ‘use by’ date.</ListItem>
                    <ListItem>Use by: see on packaging.</ListItem>
                </List>
                <List>
                    <ListTitle>CUPCAKES</ListTitle>
                    <ListItem>Store refrigerated at max +5C.</ListItem>
                    <ListItem>Consume within 3 days and by the ‘use by’ date.</ListItem>
                    <ListItem>Use by: see on packaging.</ListItem>
                </List>
                <List>
                    <ListTitle>COOKIES</ListTitle>
                    <ListItem>Store in a cool, dry place away </ListItem>
                    <ListItem>from direct sunlight.</ListItem>
                    <ListItem>Best before: see on packaging.</ListItem>
                </List>
            </LineContainer>
            <Named>PLEASE NOTE:</Named>
            <Text>Icing figurines and other cake decorations may contain non-edible food-safe parts and support
                elements, and we also use
                non-edible food-safe support elements at the base of cakes that are two tires and higher
            </Text>
            <Named>COPYRIGHTING:</Named>
            <Text>
                Content presented on the Jelena's Cake Ltd website can be used by you for your personal use only. If you
                would like to use content
                that is presented on the Jelena's Cake Ltd website for commercial purposes or otherwise, please contact
                us jelenascake@yahoo.co.uk.
            </Text>
            <Message>
                Please contact us directly through email or social media.
                We will be more than happy to answer all your questions
            </Message>
        </Wrapper>
    )
}

export default TermsPage
