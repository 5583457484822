import styled from 'styled-components'
import { MainTitle } from '../../../../UI/Common/common'
import { ButtonMain } from '../../../../UI/Buttons/ButtonMain'
import { MainTextCss } from '../../../../UI/Text/Text'

export const Wrapper = styled.form`
    margin: 40px 0 0 0;
    min-height: calc(100vh - 300px);
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 60px 0;

    @media (${({ theme }) => theme.media.mobile}) {
        grid-gap: 30px 0;
    }
`

export const CartTitle = styled(MainTitle)`
    margin: 0;
    text-align: center;
`

export const Button = styled(ButtonMain)`
    justify-self: end;
    width: 180px;

    @media (${({ theme }) => theme.media.mobile}) {
        width: 100%;
    }
`

export const Final = styled.p`
    justify-self: end;
    ${MainTextCss};
`

export const Text = styled.p`
    width: 50%;
    justify-self: end;
    font-size: 14px;
`
