import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Image, ImageWrapper, Title, Wrapper } from './BestSellersStyled'
import { IProduct, IProductsSection } from '../../Types/IProduct'
import { useAppSelector } from '../../hooks/store'
import MobileSlider from '../sliders/mobileSlider/MobileSlider'
import { MobileSlide } from '../sliders/mobileSlider/MobileSliderStyled'
import { getImageUrl } from '../../utils/getImageUrl'


const BestSellers: FC = () => {

    const productsSections = useAppSelector((state) => state.productReducer.productsSections as IProductsSection[])
    const productArr: IProduct[] = []
    productsSections.forEach((section, i) => {
        if (productArr.length >= 4) return
        section.products.forEach((product, index) => {
            if (index <= 1) {
                productArr.push(product)
            }
            return
        })
    })
    const { mobile } = useAppSelector((state) => state.appConditionReducer)
    const navigate = useNavigate()


    if (!mobile) {
        return (
            <Wrapper>
                <Title>Our Best Sellers</Title>
                <Container>
                    {productArr.map((product, i) => (
                        <ImageWrapper key={i} onClick={() => navigate(product.path)}>
                            {product.photo[0] && <Image src={getImageUrl(product.photo[0])} />}
                        </ImageWrapper>
                    ))}
                </Container>
            </Wrapper>
        )
    } else {
        return (
            <Wrapper>
                <Title>Our Best Sellers</Title>
                <MobileSlider>
                    {productArr.map((product, i) => (
                        <MobileSlide key={i}>
                            <ImageWrapper onClick={() => navigate(product.path)}>
                                {product.photo[0] && <Image src={getImageUrl(product.photo[0])} />}
                            </ImageWrapper>
                        </MobileSlide>
                    ))}
                </MobileSlider>
            </Wrapper>
        )
    }
}

export default BestSellers