import { Dispatch, FC, SetStateAction } from 'react'
import { useFormik } from 'formik'
import InputEmail from '../../../../components/Inputs/InputEmail'
import InputPassword from '../../../../components/Inputs/InputPassword'
import * as Yup from 'yup'
import { yupValidation } from '../../../../Constants/yupValidation'
import {
    ButtonSubmit,
    Fields,
    FieldsColumns,
    Form,
    FormLink,
    FormMessage,
    FormTitle
} from '../../../../UI/Form/FormStyled'
import InputText from '../../../../components/Inputs/InputText'
import InputPhone from '../../../../components/Inputs/InputPhone'
import FieldText from '../../../../components/Fields/FieldText/FieldText'
import FieldPhone from '../../../../components/Fields/FieldPhone/FieldPhone'
import { useAppDispatch } from '../../../../hooks/store'
import { ISignUp } from '../../../../store/reducers/User/Types'
import { signUp } from '../../../../store/reducers/User/ActionCreators'
import { useNavigate } from 'react-router'
import { TabKey } from '../../Layout'

interface ISignUpData extends ISignUp {
    checkPassword: string
}

interface FormSignUpProps {
    openTab: TabKey
    setOpenTab: Dispatch<SetStateAction<TabKey>>
}

const FormSignUp: FC<FormSignUpProps> = ({ openTab, setOpenTab }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const formik = useFormik<ISignUpData>({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            password: '',
            checkPassword: ''
        },
        validationSchema: Yup.object({
            firstName: yupValidation.onlyText,
            lastName: yupValidation.onlyText,
            email: yupValidation.email,
            phone: yupValidation.phone,
            password: yupValidation.password,
            checkPassword: Yup.string()
                .oneOf([Yup.ref('password')], 'Passwords do not match')
                .required('Password confirm is required')
        }),
        onSubmit: async (values, { resetForm }) => {
            const { checkPassword, ...rest } = values
            const response = await dispatch(signUp(rest))
            if (response) {
                setOpenTab('close')
                navigate('/account')
                resetForm()
            }
        }
    })

    if (openTab !== 'signup') return null
    return (
        <Form onSubmit={formik.handleSubmit}>
            <FormTitle>Create an account</FormTitle>
            <FieldsColumns>
                <FieldText
                    name="firstName"
                    value={formik.values.firstName}
                    placeholder="First Name"
                    onChange={formik.handleChange}
                    isTouched={formik.touched.firstName}
                    error={formik.errors.firstName}
                />
                <FieldText
                    name="lastName"
                    value={formik.values.lastName}
                    placeholder="Last Name"
                    onChange={formik.handleChange}
                    isTouched={formik.touched.lastName}
                    error={formik.errors.lastName}
                />
                <FieldText
                    name="email"
                    value={formik.values.email}
                    placeholder="Email"
                    onChange={formik.handleChange}
                    isTouched={formik.touched.email}
                    error={formik.errors.email}
                    type="email"
                />
                <FieldPhone
                    name="phone"
                    value={formik.values.phone}
                    placeholder="Phone"
                    onChange={formik.handleChange}
                    isTouched={formik.touched.phone}
                    error={formik.errors.phone}
                    type="phone"
                />
                <FieldText
                    name="password"
                    value={formik.values.password}
                    placeholder="Password"
                    onChange={formik.handleChange}
                    isTouched={formik.touched.password}
                    error={formik.errors.password}
                    type="password"
                />
                <FieldText
                    name="checkPassword"
                    value={formik.values.checkPassword}
                    placeholder="Confirm password"
                    onChange={formik.handleChange}
                    isTouched={formik.touched.checkPassword}
                    error={formik.errors.checkPassword}
                    type="password"
                />
            </FieldsColumns>
            <ButtonSubmit type="submit">Submit</ButtonSubmit>
            <FormMessage>
                If you have an account,{' '}
                <FormLink onClick={() => setOpenTab('login')}>log in</FormLink>
            </FormMessage>
        </Form>
    )
}

export default FormSignUp
