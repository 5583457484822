import styled, { css } from 'styled-components'
import { iconCrossSimpleLight } from '../../../../UI/Icons/icons'

interface WrapperProps {
    isRemoved: boolean
}

export const Wrapper = styled.div<WrapperProps>`
    position: relative;
    width: 122px;
    height: 162px;
    border-radius: 4px;
    background: #e6d2aa;
    overflow: hidden;
    transition: 0.125s;

    :hover {
        box-shadow: 0px 0px 1px 1px ${({ theme }) => theme.colors.graphics};
    }

    ${({ isRemoved }) =>
        !isRemoved &&
        css`
            :hover {
                box-shadow: none;
            }
        `}
`

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

interface ButtonRemoveProps {
    isVisible: boolean
}

export const ButtonRemove = styled.button<ButtonRemoveProps>`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.graphics};
  background-image: url(${iconCrossSimpleLight});
  background-size: 13px;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: 0.125s;
  cursor: pointer;


  ${({ isVisible }) =>
      isVisible &&
      css`
          visibility: visible;
          opacity: 1;
      `}
}
`
