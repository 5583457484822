import { ChangeEvent, FC, useState } from 'react'
import FieldText from '../FieldText/FieldText'
import { FormikErrors, FormikTouched } from 'formik'
import { IPrediction } from '../../../API/GoogleMaps/Types'
import {
    iconGoogleLogo,
    iconSearchDark,
    iconSearchLight
} from '../../../UI/Icons/icons'
import { useGoogleMap } from '../../../API/GoogleMaps/useGoogleMap'
import {
    ButtonClear,
    PoweredBy,
    PoweredByImage,
    PoweredByTitle,
    Prediction,
    Predictions,
    Wrapper
} from './FieldAddressStyled'

export interface IFieldAddressData {
    address: string
    postCode: string
    isCongestionZone: boolean
    deliveryDistance: string
}

interface FieldAddressProps {
    name: string
    placeholder: string
    value?: string
    onChoice: (data: IFieldAddressData) => void
    onClear?: (() => void) | null
    isTouched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
    error:
        | string
        | string[]
        | FormikErrors<any>
        | FormikErrors<any>[]
        | undefined
}

const FieldAddress: FC<FieldAddressProps> = ({
                                                 name,
                                                 value,
                                                 placeholder,
                                                 onChoice,
                                                 onClear,
                                                 isTouched,
                                                 error
                                             }) => {
    const [searchValue, setSearchValue] = useState(value ? value : '')
    const [predictions, setPredictions] = useState<IPrediction[]>([])
    const [inputIsActive, setInputIsActive] = useState<boolean>(!!value)
    const { getPredictions, getPostCode, getDistance } = useGoogleMap()

    const changeHandler = async (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setSearchValue(value)
        if (value && value.length > 3) {
            const newPredictions = await getPredictions(value)
            if (newPredictions) {
                setPredictions(newPredictions)
            }
        }
        if (inputIsActive) {
            setInputIsActive(false)
        }
    }

    const predictionClickHandler = async (prediction: IPrediction) => {
        const postCodeData = await getPostCode(prediction.place_id)
        if (!postCodeData.postCode) {
            return
        }
        const deliveryDistance = await getDistance(prediction.place_id)
        setSearchValue(prediction.description)
        setPredictions([])
        setInputIsActive(true)
        onChoice({
            address: prediction.description,
            postCode: postCodeData.postCode,
            isCongestionZone: postCodeData.isCongestionZone,
            deliveryDistance
        })
    }

    const clearHandler = () => {
        setSearchValue('')
        setPredictions([])
        setInputIsActive(false)
        if (onClear) {
            onClear()
        }
    }

    return (
        <Wrapper>
            <FieldText
                name={name}
                value={searchValue}
                placeholder={placeholder}
                isActive={inputIsActive}
                logo={{ light: iconSearchLight, dark: iconSearchDark }}
                onChange={changeHandler}
                isTouched={isTouched}
                error={error}
                isAutocomplete={false}
            />
            {(searchValue || !!predictions.length) && <ButtonClear isDark={inputIsActive} onClick={clearHandler} />}
            <Predictions isVisible={predictions && !!predictions.length}>
                {predictions?.map((prediction, i) => (
                    <Prediction
                        key={i}
                        onClick={() => predictionClickHandler(prediction)}
                    >
                        {prediction.description || 'Not found'}
                    </Prediction>
                ))}
                <PoweredBy>
                    <PoweredByTitle>powered by</PoweredByTitle>
                    <PoweredByImage
                        src={iconGoogleLogo}
                        alt='Powerd by Google'
                    />
                </PoweredBy>
            </Predictions>
        </Wrapper>
    )
}

export default FieldAddress
