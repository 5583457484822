import { FC, useState } from 'react'
import { useAppSelector } from '../../../../../../hooks/store'
import { ButtonDropDown } from '../../../../../../UI/Buttons/ButtonsIcon'
import { Container, Header, InfoTitle, Link, Text, Title, Wrapper } from '../MethodsStyled'
import { originPlaceUrl } from '../../Constants/originPlaceUrl'

const OrderCollectionMethod: FC = () => {
    const [containerIsOpen, setContainerIsOpen] = useState<boolean>(false)

    return (
        <Wrapper>
            <Header
                onClick={() => setContainerIsOpen(!containerIsOpen)}
            >
                <InfoTitle>Order Collection</InfoTitle>
                <ButtonDropDown isOpen={containerIsOpen} />
            </Header>
            <Container isHidden={!containerIsOpen}>
                <Text>
                    Order collection is free of charge. You can collect the
                    order from
                    <br></br>
                    <Link href={originPlaceUrl} target='_blank'>
                        66 Bradfield Drive IG11 9AS
                    </Link>
                </Text>
                <Text>
                    Please confirm collection time with us
                </Text>
            </Container>
        </Wrapper>
    )
}

export default OrderCollectionMethod
