import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { SectionName, SelectedText } from '../../ProductPageStyled'
import { IFiling } from '../../../../Types/IProduct'
import { IOption } from '../../Types/Types'
import { Container, FilingTitle, Title, Wrapper } from './FilingsStyled'
import { defaultIngredients } from '../../Constants/defaultIngredients'


interface FillingsProps {
    fillings: IFiling[]
    onSetIngredients: (value: string) => void
    addOption: (option: IOption) => void
    setOption: (option: IOption) => void
    removeOption: (option: IOption) => void
}

const Fillings: FC<FillingsProps> = ({
                                         fillings,
                                         onSetIngredients,
                                         addOption,
                                         setOption,
                                         removeOption
                                     }) => {

    const [activeNum, setActiveNum] = useState<number | null>(null)

    const clickHandler = (filing: IFiling, num: number) => {
        if (num === activeNum) {
            setActiveNum(null)
            removeOption({ title: 'filings', value: filing.title })
            onSetIngredients(defaultIngredients)
            return
        }
        setActiveNum(num)
        if (activeNum === null) {
            addOption({ title: 'filings', value: filing.title })
        } else {
            setOption({ title: 'filings', value: filing.title })
        }
        onSetIngredients(filing.ingredients)
    }

    if (!fillings.length) return null

    return (
        <Wrapper>
            <Title>Filling</Title>
            <Container>
                {fillings.map((item, i) => (
                    <FilingTitle
                        isActive={i === activeNum}
                        key={i}
                        onClick={() => clickHandler(item, i)}
                    >
                        {item.title}
                    </FilingTitle>
                ))}
            </Container>
        </Wrapper>
    )
}

export default Fillings