import styled from 'styled-components'
import { FormTitleCss, MainTextCss } from '../Text/Text'
import { ButtonMain } from '../Buttons/ButtonMain'

export const Form = styled.form`
    padding: 60px 160px 40px 160px;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    background-color: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.graphics};
    justify-items: center;
    border-radius: 4px;
    overflow: hidden;

    @media (${({ theme }) => theme.media.mobile}) {
        max-height: 98dvh;
        width: 100%;
        padding: 20px 10px 20px 10px;
    }
`

export const FormTitle = styled.div`
    ${FormTitleCss};
`

export const Fields = styled.div`
    margin: 35px 0 0 0;
    display: grid;
    grid-template-columns: 400px;
    grid-auto-rows: min-content;
    grid-gap: 20px 0;

    @media (${({ theme }) => theme.media.mobile}) {
        width: 100%;
        grid-template-columns: 1fr;
        overflow-y: scroll;
    }
`
export const FieldsColumns = styled.div`
    margin: 35px 0 0 0;
    display: grid;
    grid-template-columns: 300px 300px;
    grid-auto-rows: min-content;
    grid-gap: 20px 20px;

    @media (${({ theme }) => theme.media.mobile}) {
        width: 100%;
        height: calc(100dvh - 300px);
        grid-template-columns: 1fr;
        overflow-y: scroll;
    }
`

export const ButtonSubmit = styled(ButtonMain)`
    margin: 30px 0 0 0;

    @media (${({ theme }) => theme.media.mobile}) {
        width: 100%;
    }
`

export const FormText = styled.div`
    ${MainTextCss};

    @media (${({ theme }) => theme.media.mobile}) {
        text-align: center;
        font-size: 14px;
    }
`

export const FormMessage = styled.div`
    margin: 30px 0 0 0;
    ${MainTextCss};

    @media (${({ theme }) => theme.media.mobile}) {
        text-align: center;
        font-size: 14px;
    }
`

export const FormLink = styled.span`
    border-bottom: 1px solid ${({ theme }) => theme.colors.graphics};
    cursor: pointer;
    opacity: 0.7;

    :hover {
        opacity: 1;
    }
`

export const FormStringButton = styled.div`
    margin: 30px 0 0 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graphics};
    cursor: pointer;
    opacity: 0.7;

    :hover {
        opacity: 1;
    }
`
