import styled from 'styled-components'
import { MainText } from '../../UI/Common/common'

export const Wrapper = styled.div`
    padding: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid ${({ theme }) => theme.colors.graphics};
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.background};
`

export const Text = styled(MainText)`
    margin: 0 0 20px 0;
    font-size: 12px;

    @media (${({ theme }) => theme.media.mobile}) {
        font-size: 16px;
    }
`

export const Container = styled.div`
    margin: 0 auto 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

export const Avatar = styled.div`
    flex-shrink: 0;
    margin: 0 10px 0 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;
`

export const Person = styled.div`
    width: 100%;
`

export const Name = styled.p`
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
`

export const Location = styled.div`
    font-size: 12px;
`
