import styled from 'styled-components'
import { MainTextCss, TitleCss } from '../../../../../../UI/Text/Text'
import {
    iconCrossSmall,
    iconMinus,
    iconPlusSmall
} from '../../../../../../UI/Icons/icons'

export const Wrapper = styled.div`
  padding: 30px 0;
  display: grid;
  grid-template-columns: max-content 1fr min-content 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graphics};
`

export const WrapperMobile = styled.div`
  padding: 30px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graphics};
`

export const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-gap: 20px 0;

  @media (${({ theme }) => theme.media.mobile}) {
    grid-gap: 20px 0;
  }
`

export const Container = styled.div`
  max-width: 290px;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-gap: 20px 0;

  @media (${({ theme }) => theme.media.mobile}) {
    max-width: none;
    grid-template-columns: 10% 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0 20px;
  }
`

export const ContainerMobile = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0 20px;
`

export const Info = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 1fr;
  grid-gap: 0 20px;
`

export const InfoMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`

export const InfoHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content;
`

export const Image = styled.img`
  width: 161px;
  height: 225px;
  background-color: white;
  object-fit: cover;

  @media (${({ theme }) => theme.media.mobile}) {
    width: 90px;
    height: 112px;
  }
`

export const Title = styled.p`
  font-size: 21px;
  font-weight: 300;

  @media (${({ theme }) => theme.media.mobile}) {
    font-size: 18px;
  }
`

export const SubTitle = styled.div`
  ${TitleCss};
  @media (${({ theme }) => theme.media.mobile}) {
    font-size: 14px;
  }
`

export const Text = styled.p`
  ${MainTextCss};
`

export const Quantity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Button = styled.button`
  width: 21.5px;
  height: 21.5px;
  background-color: transparent;
  outline: none;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid ${({ theme }) => theme.colors.graphics};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.graphics};
  cursor: pointer;
`

export const ButtonPlus = styled(Button)`
  background-image: url(${iconPlusSmall});
`

export const ButtonMinus = styled(Button)`
  background-image: url(${iconMinus});
`
export const ButtonCross = styled(Button)`
  width: 25px;
  height: 25px;
  background-size: contain;
  background-image: url(${iconCrossSmall});
  border: none;
`

export const QuantityValue = styled.p`
  width: 60px;
  text-align: center;
  ${MainTextCss};
`

export const Bar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`

export const Price = styled.p`
  font-size: 26px;
  font-weight: 400;

  @media (${({ theme }) => theme.media.mobile}) {
    font-size: 21px;
    justify-self: end;
  }
`

export const PriceText = styled.div`
  font-size: 20px;
  font-weight: 400;
  text-align: end;

  @media (${({ theme }) => theme.media.mobile}) {
    justify-self: end;
  }
`
