import { IOrderProduct } from '../Types/IOrder'

export const requestPriceValue: string = 'Price on request'

export const getProductsPrice = (
    products: IOrderProduct[],
    deliveryCost: string = ''
): string => {
    let result = 0
    for (const product of products) {
        if (!Number(product.cost)) {
            return requestPriceValue
        }
        if (!!Number(product.sale)) {
            result += Number(product.cost) * ((100 - Number(product.sale)) / 100) * Number(product.quantity)
        } else {
            result += Number(product.cost) * Number(product.quantity)
        }

    }
    if (deliveryCost) {
        result += Number(deliveryCost)
    }
    return `£ ${result.toFixed(2)}`
}