import styled from 'styled-components'
import mapPng from '../../../../images/main/delivery.webp'
import { MainText, MainTitle } from '../../../../UI/Common/common'

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (${({ theme }) => theme.media.mobile}) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
`

export const Title = styled(MainTitle)`
    margin: 0 0 20px 0;
    @media (${({ theme }) => theme.media.mobile}) {
        width: 100%;
    }
`

export const Text = styled(MainText)`
    font-size: 16px;
`

export const Container = styled.div`
    margin: 0 40px 0 0;
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (${({ theme }) => theme.media.mobile}) {
        margin: 0;
        width: 100%;
        align-items: center;
    }
`

export const Image = styled.img.attrs({
    alt: 'Delivery',
    src: mapPng
})`
    width: 50%;

    @media (${({ theme }) => theme.media.mobile}) {
        margin: 30px 0 0 0;
        width: 100%;
    }
`
