import { FC } from 'react'
import { Container, Named, Text, Title, Wrapper } from '../../UI/Text/TextPage'
import { privacyPolicyData } from '../../Constants/PagesTexts/privacy&Policy'
import { LineLink } from '../../UI/Common/common'

const PrivacyPolicyPage: FC = () => {
    return (
        <Wrapper>
            <Title>Privacy Policy</Title>
            {privacyPolicyData.map((privacy, privacyIndex) => (
                <Container key={privacyIndex}>
                    {privacy.title && <Named>{privacy.title}</Named>}
                    {privacy.text.map((text, textIndex) => <Text key={textIndex}>{text}</Text>)}
                </Container>
            ))}
            <Named>Contact Us</Named>
            <Text>If you have any questions about this Privacy Policy, You can contact us:</Text>
            <Text>By email: <LineLink href='mailto:info@jelenascake.co.uk'>info@jelenascake.co.uk</LineLink></Text>
        </Wrapper>
    )
}

export default PrivacyPolicyPage
