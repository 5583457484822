import styled from 'styled-components'

import { MainText, MainTitle } from '../Common/common'
import { NavLink } from 'react-router-dom'

export const Wrapper = styled.div`
    margin: 60px 0 0 0;
    overflow: hidden;
`

export const Line = styled.p`
    max-width: calc(100vw - 40px);
    height: 0;
    opacity: 0;
`

export const Title = styled(MainTitle)`
    margin: 0 0 40px 0;
    text-align: center;
`

export const SubTitle = styled(MainText)`
    margin: 60px 0 60px 0;
    text-align: center;
`

export const Container = styled.div`
    margin: 0 0 60px 0;
`

export const LineContainer = styled.div`
    margin: 0 0 60px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (${({ theme }) => theme.media.mobile}) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
`

export const Named = styled.div`
    margin: 30px 0 10px 0;
    font-size: 21px;
`

export const Text = styled(MainText)`
    margin: 0 0 10px 0;
`

export const Link = styled.a`
    margin: 0 0 10px 0;
    white-space: pre;
    color: ${({ theme }) => theme.colors.graphics};
`

export const InternalLink = styled(NavLink)`
    margin: 0 0 10px 0;
    white-space: pre;
    color: ${({ theme }) => theme.colors.graphics};
`

export const Span = styled(MainText)`
    opacity: 0;
    margin: 0;
`

export const List = styled.div`
    margin: 0 20px 0 0;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (${({ theme }) => theme.media.mobile}) {
        margin: 20px 0 0 0;
        width: 100%;
    }

`

export const ListTitle = styled(MainText)`
    margin: 0 0 20px 0;
    text-transform: uppercase;
`

export const ListItem = styled(MainText)`
    margin: 0 0 7px 0;
`

export const Message = styled(MainText)`
    margin: 40px 0 40px 0;
    font-size: 21px;
`
