import styled, { css } from 'styled-components'
import { MainTextCss } from '../../../../../UI/Text/Text'
import { ButtonMain } from '../../../../../UI/Buttons/ButtonMain'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 30px 0;
`

export const Header = styled.div`
  display: grid;
  grid-template-columns: max-content min-content;
  grid-template-rows: 1fr;
  grid-gap: 0 20px;
  align-items: center;
  cursor: pointer;
`

interface ContainerProps {
    isHidden?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 10px 0;
  visibility: visible;
  opacity: 1;

  ${({ isHidden }) =>
          isHidden &&
          css`
            display: none;
          `}
`

export const InfoTitle = styled.div`
  font-size: 21px;
  font-weight: 500;
`

export const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
`

export const Link = styled.a`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.graphics};
  text-decoration: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graphics};
`

export const DropDownContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 10px 0;
`

export const Title = styled.div`
  ${MainTextCss};
`

export const ButtonAdd = styled.div`
  margin: 34px 0 0 0;
  width: 44px;
  height: 44px;
  border: 1px solid ${({ theme }) => theme.colors.graphics};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 300;
  cursor: pointer;
`

export const ButtonAddMobile = styled(ButtonMain)`
  margin: 20px 0 0 0;
  width: 100%;
`
