import styled, { css } from 'styled-components'
import {
    iconArrowRightLight,
    iconCrossDark,
    iconCrossLight,
    iconDropDownDark,
    iconDropDownLight,
    iconPlus
} from '../Icons/icons'

export const ButtonIcon = styled.button.attrs({
    type: 'button'
})`
    width: 20px;
    height: 20px;
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 50%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
`

export const ButtonPlus = styled(ButtonIcon)`
    border: 1px solid ${({ theme }) => theme.colors.graphics};
    background-image: url(${iconPlus});
    background-size: 11px;
`

export const ButtonCross = styled(ButtonIcon)`
    background-image: url(${iconCrossLight});
`

export const ButtonCrossDark = styled(ButtonIcon)`
    background-image: url(${iconCrossDark});
`

interface ButtonDropDownProps {
    isOpen: boolean
    isDark?: boolean
}

export const ButtonDropDown = styled(ButtonIcon)<ButtonDropDownProps>`
    position: relative;
    background-image: url(${iconDropDownLight});

    ${({ isDark }) =>
        isDark &&
        css`
            background-image: url(${iconDropDownDark});
        `}

    ${({ isOpen }) =>
        isOpen &&
        css`
            transform: rotate(180deg);
        `}
`

export const ButtonMore = styled.div`
    width: 19px;
    height: 19px;
    background-image: url(${iconArrowRightLight});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`
