import styled from 'styled-components'
import { MainTitle } from '../../UI/Common/common'

export const Wrapper = styled.div`
  margin: 150px 0 40px 0;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 60px 0 40px 0;
  }
`

export const Title = styled(MainTitle)`
  text-align: center;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 0 0 30px 0;
  }
`

export const Container = styled.div`
  margin: 30px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ImageWrapper = styled.div`
  position: relative;
  padding: 32% 0 0 0;
  width: 24%;
  cursor: pointer;


  @media (${({ theme }) => theme.media.mobile}) {
    padding: 134% 0 0 0;
    width: 100%;
  }
`

export const Image = styled.img.attrs({
    alt: 'Product'
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`
