import React, { FC, useEffect, useState } from 'react'
import '../../Styles/fonts.css'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { Wrapper } from './LayoutStyled'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import Message from '../../components/Message/Message'
import SignIn from './Components/SignIn/SighIn'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { getProductsSections } from '../../store/reducers/ProductsSlice/ActionCreators'
import { getOptions } from '../../store/reducers/AppOptions/ActionCreators'
import { ICardPersistState } from '../../store/reducers/CartSlice/CartSlice'

export type TabKey = 'login' | 'signup' | 'recovery' | 'close' | 'change'

const Layout: FC = () => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [openTab, setOpenTab] = useState<TabKey>('close')
    const { user } = useAppSelector((state) => state.userReducer)
    const { products } = useAppSelector((state) => state.persistCardReducer as ICardPersistState)

    const profileClickHandler = () => {
        if (user) {
            navigate('/account/orders')
            return
        }
        setOpenTab('login')
    }

    useEffect(() => {
        const loadData = async () => {
            dispatch(getProductsSections(!products.length))
            dispatch(getOptions())
        }

        loadData()
        const interval = setInterval(() => {
            loadData()
        }, 30000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <Wrapper>
            <SignIn openTab={openTab} setOpenTab={setOpenTab} />
            <Message />
            <Header onProfileClick={profileClickHandler} />
            {location.pathname !== '/' && <Breadcrumbs />}
            <Outlet />
            <Footer />
        </Wrapper>
    )
}

export default Layout
