import { FC } from 'react'
import { useFormik } from 'formik'
import {
    ButtonSubmit,
    Fields,
    Form,
    FormTitle
} from '../../../../UI/Form/FormStyled'
import FieldAddress, {
    IFieldAddressData
} from '../../../../components/Fields/FieldAddress/FieldAddress'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { IAppOptionsState } from '../../../../store/reducers/AppOptions/AppOptionsSlice'
import Modal from '../../../../components/Modal/Modal'
import styled from 'styled-components'
import FieldNumber from '../../../../components/Fields/FieldNumber/FieldNumber'
import FieldText from '../../../../components/Fields/FieldText/FieldText'
import { IAddressData } from '../../../../Types/IUser'
import { createAddress } from '../../../../store/reducers/User/ActionCreators'

export const Line = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  grid-gap: 0 40px;

  @media (${({ theme }) => theme.media.mobile}) {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: initial;
    grid-gap: 20px 40px;
  }
`

interface FormAddAddressProps {
    clientId: string
    isVisible: boolean
    onClose: () => void
    onAddAddress: (address: IAddressData) => void
}

const FormAddAddress: FC<FormAddAddressProps> = ({
                                                     clientId,
                                                     isVisible,
                                                     onClose,
                                                     onAddAddress
                                                 }) => {
    const dispatch = useAppDispatch()
    const { originDeliveryAddress } = useAppSelector(
        (state) => (state.appOptionsReducer as IAppOptionsState).appDelivery
    )

    const formik = useFormik({
        initialValues: {
            address: '',
            postCode: '',
            flat: '',
            floor: '',
            isCongestionZone: false,
            deliveryDistance: {
                origin: originDeliveryAddress,
                distance: ''
            }
        },
        onSubmit: async (values, { resetForm }) => {
            const result = await dispatch(
                createAddress({ clientId, ...values })
            )
            if (result) {
                onAddAddress(result)
                onClose()
                resetForm({})
            }
        }
    })

    const addressChoiceHandler = (data: IFieldAddressData) => {
        formik.setValues({
            ...formik.values,
            address: data.address,
            postCode: data.postCode,
            isCongestionZone: data.isCongestionZone,
            deliveryDistance: {
                ...formik.values.deliveryDistance,
                distance: data.deliveryDistance
            }
        })
    }

    return (
        <Modal isVisible={isVisible} onClose={onClose}>
            <Form onSubmit={formik.handleSubmit}>
                <FormTitle>Add New Address</FormTitle>
                <Fields>
                    <FieldAddress
                        name='address'
                        value={formik.values.address}
                        placeholder='Address'
                        onChoice={addressChoiceHandler}
                        isTouched={formik.touched.address}
                        error={formik.errors.address}
                    />
                    <Line>
                        <FieldNumber
                            name='floor'
                            value={formik.values.floor}
                            placeholder='Floor'
                            onChange={formik.handleChange}
                            isTouched={formik.touched.floor}
                            error={formik.errors.floor}
                        />
                        <FieldNumber
                            name='flat'
                            value={formik.values.flat}
                            placeholder='Flat'
                            onChange={formik.handleChange}
                            isTouched={formik.touched.flat}
                            error={formik.errors.flat}
                        />
                    </Line>
                    <Line>
                        <FieldText
                            name='postCode'
                            value={formik.values.postCode}
                            placeholder='Postal Code'
                            onChange={formik.handleChange}
                            isTouched={formik.touched.postCode}
                            error={formik.errors.postCode}
                        />
                    </Line>
                </Fields>
                <ButtonSubmit type='submit'>Submit</ButtonSubmit>
            </Form>
        </Modal>
    )
}

export default FormAddAddress
