import styled from 'styled-components'
import { Link } from 'react-router-dom'
import arrowSvg from '../../images/icons/arrow_bread.svg'

export const Wrapper = styled.div`
  margin: 70px 0 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 40px 0 0 0;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const LinkItem = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.graphics};
  outline: none;
  border: none;
`

export const Arrow = styled.div`
  margin: 0 20px;
  width: 10px;
  height: 10px;
  background-image: url(${arrowSvg});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 0 10px;
  }
`
