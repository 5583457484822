import { FC } from 'react'
import { useFormikContext } from 'formik'
import FieldText from '../../../../components/Fields/FieldText/FieldText'
import { ICartInitialValues } from '../../Components/CartFormik/CartFormik'
import { ModuleTitle, Title, Wrapper } from '../ModuleStyled'
import { Container } from './CartPersonalStyled'

const CartPersonal: FC = () => {
    const { values, handleChange, touched, errors } =
        useFormikContext<ICartInitialValues>()

    return (
        <Wrapper>
            <ModuleTitle>Personal Information</ModuleTitle>
            <Container>
                <FieldText
                    name="firstName"
                    value={values.firstName}
                    placeholder="First Name"
                    onChange={handleChange}
                    isTouched={touched.firstName}
                    error={errors.firstName}
                />
                <FieldText
                    name="lastName"
                    value={values.lastName}
                    placeholder="Last Name"
                    onChange={handleChange}
                    isTouched={touched.lastName}
                    error={errors.lastName}
                />
                <FieldText
                    name="email"
                    value={values.email}
                    placeholder="Email"
                    onChange={handleChange}
                    isTouched={touched.email}
                    error={errors.email}
                    type="email"
                />
                <FieldText
                    name="phone"
                    value={values.phone}
                    placeholder="Phone"
                    onChange={handleChange}
                    isTouched={touched.phone}
                    error={errors.phone}
                    type="phone"
                />
            </Container>
        </Wrapper>
    )
}

export default CartPersonal
