import { FC, useState } from 'react'
import { ButtonDropDown } from '../../../../../../UI/Buttons/ButtonsIcon'
import { originPlaceUrl } from '../../Constants/originPlaceUrl'
import { Container, Header, Link, InfoTitle, Wrapper, Text } from '../MethodsStyled'

const UberMethod: FC = () => {
    const [containerIsOpen, setContainerIsOpen] = useState<boolean>(false)

    return (
        <Wrapper>
            <Header onClick={() => setContainerIsOpen(!containerIsOpen)}>
                <InfoTitle> How it works </InfoTitle>
                <ButtonDropDown isOpen={containerIsOpen} />
            </Header>
            <Container isHidden={!containerIsOpen}>
                <Text>
                    • When your order is ready for collection we’ll contact
                    you sending a text message with collection info
                    <br />
                    • You have to arrange order collection using the Uber
                    app with our location as a pickup point
                    <br />
                    • Send us information of the vehicle Vehicle Reg Number,
                    Vehicle Mark and Time of arriving
                    <br />
                    • We’ll meet the driver, pass them your order with
                    instruction of delivery*
                    <br />
                    • Track the driver trough Uber app and meet them upon
                    arrival
                    <br />
                    * In that case, Jelena's Cake Ltd is not responsible for
                    the safety of the products in transit, since we are
                    unable to control all the aspects of transportation
                    <br />
                </Text>
                <InfoTitle>Our location</InfoTitle>
                <Text>
                    <Link href={originPlaceUrl} target='_blank'>66 Bradfield Dr, Barking IG11 9AS, UK</Link>
                </Text>
            </Container>
        </Wrapper>
    )
}

export default UberMethod
