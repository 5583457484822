import styled from 'styled-components'
import { MainTextCss } from '../../../UI/Text/Text'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 30px 0;

  @media (${({ theme }) => theme.media.mobile}) {
    grid-gap: 20px 0;
  }
`

export const ModuleTitle = styled.div`
  font-size: 29px;
  font-weight: 400;

  @media (${({ theme }) => theme.media.mobile}) {
    font-size: 21px;
  }
`

export const Title = styled.div`
  ${MainTextCss};
`

export const Price = styled.p`
  justify-self: end;
  font-size: 26px;
  line-height: 30px;
  font-weight: 400;

  @media (${({ theme }) => theme.media.mobile}) {
    font-size: 21px;
  }
`
