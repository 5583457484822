import { FC } from 'react'
import { iconFacebook, iconInstagram, iconTelegram, iconWhatsapp } from '../../../../UI/Icons/icons'
import Modal from '../../../../components/Modal/Modal'
import {
    Container,
    Message,
    Social,
    SocialContainer, SocialLink, SocialLinkIcon,
    SocialLinks, SocialLinkTitle,
    SocialTitle,
    Subtitle,
    Title
} from './CartMessageStyled'

interface CartMessageProps {
    isVisible: boolean
    onHide: () => void
}

const CartMessage: FC<CartMessageProps> = ({ isVisible, onHide }) => {
    return (
        <Modal isVisible={isVisible} onClose={onHide}>
            <Container>
                <Title>Thanks for your order</Title>
                <Subtitle>We will get back to you Monday to Friday from 10AM to 6PM
                    Any enquiries send after 12PM on Friday or at any time during Saturday and Sunday will only get seen
                    to on Monday
                </Subtitle>
                <Message>
                    If you have been in contact with us for your event and waiting on a response, please do not submit
                    another order. This is for “Initial Enquiries” only
                </Message>
                <Social>
                    <SocialContainer>
                        <SocialTitle>Follow Us</SocialTitle>
                        <SocialLinks>
                            <SocialLink href='https://instagram.com/jelenas_cake_ltd' target='_blank'>
                                <SocialLinkIcon src={iconInstagram} alt='Instagram' />
                                <SocialLinkTitle>Instagram</SocialLinkTitle>
                            </SocialLink>
                            <SocialLink href='https://www.facebook.com/pg/jelenascake.co.uk' target='_blank'>
                                <SocialLinkIcon src={iconFacebook} alt='Facebook' />
                                <SocialLinkTitle>Facebook</SocialLinkTitle>
                            </SocialLink>
                        </SocialLinks>
                    </SocialContainer>
                    <SocialContainer>
                        <SocialTitle>In Messengers</SocialTitle>
                        <SocialLinks>
                            <SocialLink href='https://wa.me/447824191144' target='_blank'>
                                <SocialLinkIcon src={iconWhatsapp} alt='Instagram' />
                                <SocialLinkTitle>Whats App</SocialLinkTitle>
                            </SocialLink>
                            <SocialLink href='https://tgtg.su/Jelenas_Cake_Ltd' target='_blank'>
                                <SocialLinkIcon src={iconTelegram} alt='Facebook' />
                                <SocialLinkTitle>Telegram</SocialLinkTitle>
                            </SocialLink>
                        </SocialLinks>
                    </SocialContainer>
                </Social>
            </Container>
        </Modal>
    )
}

export default CartMessage