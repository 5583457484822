import { IOrderProduct } from '../Types/IOrder'

export const getProductSum = (product: IOrderProduct): string => {
    const { cost, sale, quantity } = product
    if (!Number(cost)) {
        return ''
    }
    if (Number(sale)) {
        return (Number(cost) * ((100 - Number(sale)) / 100) * Number(quantity)).toFixed(2)
    }
    return (Number(cost) * Number(quantity)).toFixed(2)
}