import React, { FC } from 'react'
import { Container, LineContainer, List, ListTitle, Map, Title, Wrapper } from './ContactsPageStyled'
import SocialLink from '../../UI/Links/SocialLink/SocialLink'

import whatsappIcon from '../../images/social/icon_whatsapp.svg'
import telegramIcon from '../../images/social/icon_telegram.svg'
import facebookIcon from '../../images/social/icon_facebook.svg'
import instagramIcon from '../../images/social/icon_instagram.svg'
import { Line } from '../../UI/Text/TextPage'

const ContactsPage: FC = () => {
    return (
        <Wrapper>
            <Title>Contact Us</Title>
            <Container>
                <List>
                    <ListTitle>In Messengers</ListTitle>
                    <SocialLink url='https://wa.me/447824191144' icon={whatsappIcon}>
                        WhatsApp
                    </SocialLink>
                    <SocialLink
                        url='https://tgtg.su/Jelenas_Cake_Ltd'
                        icon={telegramIcon}
                    >
                        Telegram
                    </SocialLink>
                </List>
                <List>
                    <ListTitle>Phone</ListTitle>
                    <SocialLink url='tel:+44 7824 191144'>+44 7824 191144</SocialLink>
                </List>
                <List>
                    <ListTitle>Email</ListTitle>
                    <SocialLink url='mailto:jelenascake@yahoo.co.uk'>
                        jelenascake@yahoo.co.uk
                    </SocialLink>
                </List>
                <List>
                    <ListTitle>Follow Us</ListTitle>
                    <LineContainer>
                        <SocialLink
                            url='https://www.facebook.com/pg/jelenascake.co.uk'
                            icon={facebookIcon}
                        />
                        <SocialLink
                            url='https://instagram.com/jelenas_cake_ltd'
                            icon={instagramIcon}
                        />
                    </LineContainer>
                </List>
            </Container>
            <Map
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.1427426035416!2d0.10419582685313324!3d51.54728151121705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a5d01cdaf33f%3A0x9b0701b5d50464e5!2s66%20Bradfield%20Dr%2C%20Barking%20IG11%209AS%2C%20UK!5e0!3m2!1sen!2sru!4v1671967633495!5m2!1sen!2sru'
                width='600'
                height='450'
                loading='lazy'
                referrerPolicy='no-referrer-when-downgrade'
            />
            <Line>
                ddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd
                ddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd
            </Line>
        </Wrapper>
    )
}

export default ContactsPage
