import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { iconBurgerLight, iconCrossLight } from '../../UI/Icons/icons'

export const Wrapper = styled.div`
    margin: 30px 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (${({ theme }) => theme.media.mobile}) {
        display: grid;
        grid-template-columns: 70px 1fr 70px;
        grid-template-rows: 1fr;
        grid-gap: 0 10%;
    }
`

interface MenuButtonProps {
    isOpen: boolean
}

export const MenuBurgerMobile = styled.button.attrs({
    type: 'button'
})<MenuButtonProps>`
    //position: absolute;
    //top: 45px;
    //right: 20px;
    width: 30px;
    height: 30px;
    display: none;
    outline: none;
    border: none;
    background-color: transparent;
    background-image: url(${iconBurgerLight});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    ${({ isOpen }) =>
        isOpen &&
        css`
            background-image: url(${iconCrossLight});
        `} @media (${({ theme }) => theme.media.mobile}) {
        display: block;
    }
`

export const Menu = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1000;

    .active {
        opacity: 1;
    }
`

interface MenuMobileProps {
    isOpen: boolean
}

export const MenuMobile = styled.div<MenuMobileProps>`
    position: fixed;
    top: 100px;
    left: 0;
    width: 100vw;
    height: calc(100dvh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: ${({ theme }) => theme.colors.background};
    visibility: hidden;
    opacity: 0;
    transition: 0.125s;

    ${({ isOpen }) =>
        isOpen &&
        css`
            visibility: visible;
            opacity: 1;
        `}

    z-index: 1000;
`

export const MenuLinks = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (${({ theme }) => theme.media.mobile}) {
        padding: 0 20px;
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;
    }
`

export const MenuBar = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0 10px;
`

export const MenuLink = styled(NavLink)`
    margin: 0 30px 0 0;
    outline: none;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.graphics};
    font-size: clamp(12px, 1.6vw, 16px);
    opacity: 0.8;
    transition: 0.125s;

    :hover {
        opacity: 1;
    }

    @media (${({ theme }) => theme.media.mobile}) {
        margin: 0 0 20px 0;
        font-size: 24px;
    }
`

export const MenuButton = styled.div`
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
`

export const MenuButtonIcon = styled.img`
    width: 100%;
    height: 100%;
`

export const MenuButtonIndicator = styled.div`
    position: absolute;
    top: -8px;
    right: -8px;
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.graphics};
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.background};
    font-size: 12px;
    font-weight: 600;
`

export const MenuInfoMobile = styled.div`
    display: none;

    @media (${({ theme }) => theme.media.mobile}) {
        width: 100%;
        margin: auto 0 0 0;
        padding: 20px 0 20px 10px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        border-top: 1px solid ${({ theme }) => theme.colors.graphics};
        a,
        p {
            margin: 0 0 10px 0;
            text-decoration: none;
            color: ${({ theme }) => theme.colors.graphics};
            font-size: 16px;
            opacity: 1;
        }
    }
`
