import styled from 'styled-components'
import { MainTextCss } from '../../UI/Text/Text'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;

`

export const Title = styled.div`
  margin: 0 0 30px 0;
  ${MainTextCss};

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 0 0 10px 0;
    font-size: 18px;
    font-weight: 500;
  }
`
export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;

  @media (${({ theme }) => theme.media.mobile}) {
    grid-gap: 15px 0;
  }
`