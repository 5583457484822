import { FC, useState } from 'react'
import styled, { css } from 'styled-components'
import { IPrice } from '../../../../../../Types/IProduct'
import { ButtonDropDown } from '../../../../../../UI/Buttons/ButtonsIcon'

export const Wrapper = styled.div`
  position: relative;
  height: 50px;
  width: 360px;
`

export const Header = styled.div`
  position: relative;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: 2;
`

export const Title = styled.div`
  font-size: 21px;
`

interface ContainerProps {
    isOpen: boolean
}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 10px 10px 10px;
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 10px 0;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.graphics};
  border-radius: 4px;
  z-index: 1;
  overflow: hidden;

  ${({ isOpen }) => isOpen && css`
    height: auto;
  `}
`

export const Item = styled.p`
  font-size: 18px;
`

interface PriceIntervalProps {
    price: IPrice
}

const PriceInterval: FC<PriceIntervalProps> = ({ price }) => {

    const [containerIsOpen, setContainerIsOpen] = useState<boolean>(false)

    return (
        <Wrapper>

            <Container isOpen={containerIsOpen}>
                <Header onClick={() => setContainerIsOpen(!containerIsOpen)}>
                    <Title>From {price.currency} {price.intervals[0].cost} per {price.unit}</Title>
                    <ButtonDropDown isOpen={containerIsOpen} />
                </Header>
                {price.intervals.map((item, i) =>
                    <Item key={i}>
                        From {item.from} To {item.to} - {price.currency} {item.cost} per {price.unit}
                    </Item>)}
            </Container>
        </Wrapper>
    )
}

export default PriceInterval