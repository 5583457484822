import { ILink } from '../../../Types/ILink'
import { IProductsSection } from '../../../Types/IProduct'

export const generateLink = (pathname: string, productsSections: IProductsSection[]): ILink[] => {
    const paths = pathname.split('/')
    paths.shift()
    const result: ILink[] = []
    if (paths[0] === 'sections') {
        const section = productsSections.find(({ id }) => id === paths[1])
        if (section) {
            result.push({ title: section.title, path: `sections/${section.id}` })
            if (paths[2]) {
                const product = section.products.find(({ id }) => id === paths[2])
                if (product) {
                    result.push({ title: product.title, path: `sections/${section.id}/${product.id}` })
                }
            }
        }
    } else {
        paths.forEach(
            (path, i) => {
                if (paths[i - 1] === 'cart') {
                    return
                }
                const strArray = path.replace(/_/g, ' ').split(' ')
                let title = ''
                let routePath = ''
                strArray.forEach(item => {
                    title += item.charAt(0).toUpperCase() + item.slice(1) + ' '
                })
                if (i === 0) {
                    routePath = `/${path}`
                } else {
                    paths.forEach(item => {
                        routePath += `/${item}`
                    })
                }
                result.push({ title, path: routePath })
            }
        )
    }

    return result
}