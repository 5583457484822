export const getTimeIntervals = (
    date: Date | null
): string[] => {
    let result: string[] = []
    if (!date) return result

    const getInterval = (startHours: number, endHours: number) => {
        const intervals = ['00', '15', '30', '45']
        const timeArr: string[] = []
        for (let i = startHours; i <= endHours; i++) {
            if (i === endHours) {
                const hour = i < 10 ? `0${i}` : `${i}`
                timeArr.push(`${hour}:${intervals[0]}`)
            } else {
                for (let j = 0; j < 4; j++) {
                    const hour = i < 10 ? `0${i}` : `${i}`
                    timeArr.push(`${hour}:${intervals[j]}`)
                }
            }
        }
        return timeArr
    }

    if (date.getDay() === 0) {
        result = getInterval(10, 12)
    } else {
        const firstInterval = getInterval(11, 13)
        const secondInterval = getInterval(19, 21)
        result = [...firstInterval, ...secondInterval]
    }

    return result
}
