export class GoogleMaps {
    private distanceMatrix: google.maps.DistanceMatrixService
    private placesService: google.maps.places.PlacesService
    private autocompleteService: google.maps.places.AutocompleteService
    private originAddress: string

    constructor(originAddress: string) {
        this.originAddress = originAddress
        this.autocompleteService = new google.maps.places.AutocompleteService()
        this.placesService = new google.maps.places.PlacesService(
            document.createElement('div')
        )
        this.distanceMatrix = new google.maps.DistanceMatrixService()
    }

    public async getPredictions(text: string) {
        if (!text) return
        const predictionsResponse =
            await this.autocompleteService.getPlacePredictions({
                input: text,
                componentRestrictions: { country: 'gb' }
            })
        return predictionsResponse.predictions
    }

    public async getPostcode(placeId: string): Promise<string | null> {
        return new Promise((resolve, reject) => {
            if (!placeId) reject()

            try {
                this.placesService.getDetails(
                    {
                        placeId,
                        fields: ['address_components']
                    },
                    (details) => {
                        let postcode = null
                        details?.address_components?.forEach((entry) => {
                            if (
                                entry.types?.[0] === 'postal_code' ||
                                entry.types?.[0] === 'postal_code_prefix'
                            ) {
                                postcode = entry.long_name
                            }
                        })
                        return resolve(postcode)
                    }
                )
            } catch (e: any) {
                reject(e)
            }
        })
    }

    async getDistance(destinationId: string): Promise<string> {
        return new Promise((resolve, reject) => {
            try {
                this.distanceMatrix.getDistanceMatrix(
                    {
                        origins: [this.originAddress],
                        destinations: [{ placeId: destinationId }],
                        travelMode: google.maps.TravelMode.DRIVING,
                        unitSystem: google.maps.UnitSystem.IMPERIAL,
                        avoidHighways: false,
                        avoidTolls: false
                    },
                    (data) => {
                        if (data) {
                            if (data.rows[0].elements[0].status === 'OK') {
                                let distance = '0'
                                const result =
                                    data.rows[0].elements[0].distance.text.split(
                                        ' '
                                    )
                                if (result[1] === 'ft') {
                                    distance = `${(
                                        Number(result[0]) * 0.00019
                                    ).toFixed(2)}`
                                }
                                if (result[1] === 'mi') {
                                    distance = result[0]
                                }
                                resolve(distance)
                            }
                        }
                    }
                )
            } catch (e: any) {
                reject(e)
            }
        })
    }
}
