import styled from 'styled-components'
import { MainTextCss, TitleCss } from '../../../../UI/Text/Text'
import { ButtonMain } from '../../../../UI/Buttons/ButtonMain'

export const Wrapper = styled.div`
  padding: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 180px;
  grid-template-rows: 1fr;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graphics};

  @media (${({ theme }) => theme.media.mobile}) {
    padding: 10px;
    grid-template-columns: 1fr;
    grid-gap: 10px 0;
    background-color: ${({ theme }) => theme.colors.graphics};
    border-radius: 4px;
  }
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 10px 0;

  @media (${({ theme }) => theme.media.mobile}) {
    grid-gap: 0 0;
  }
`

export const Title = styled.p`
  ${TitleCss};
  font-weight: 500;

  @media (${({ theme }) => theme.media.mobile}) {
    color: ${({ theme }) => theme.colors.background};
  }
`
export const Value = styled.p`
  ${MainTextCss};

  @media (${({ theme }) => theme.media.mobile}) {
    color: ${({ theme }) => theme.colors.background};
  }
`

export const Info = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: 1fr;
  grid-gap: 0 7px;
`

export const Button = styled(ButtonMain)`
  width: auto;

  @media (${({ theme }) => theme.media.mobile}) {

    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.graphics};
  }
`
