import React, { FC } from 'react'
import styled from 'styled-components'

import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { IProductsPersistState } from '../../../../store/reducers/ProductsSlice/ProductsSlice'
import { getImageUrl } from '../../../../utils/getImageUrl'
import { MainText, MainTitle } from '../../../../UI/Common/common'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ButtonMain, ButtonMainClear, ButtonMainClearCss, ButtonMainCss } from '../../../../UI/Buttons/ButtonMain'
import { Navigation } from 'swiper'
import 'swiper/css'
import { iconArrowLeftLight, iconArrowRightLight } from '../../../../UI/Icons/icons'
import { getProductPrice } from '../../../../Helpers/getProductPrice'
import { addProduct } from '../../../../store/reducers/CartSlice/CartSlice'
import { v4 as uuidv4 } from 'uuid'
import { showMessage } from '../../../../store/reducers/AppCondition/AppConditionSlice'
import { IProduct, IProductsSection } from '../../../../Types/IProduct'
import { IOrderProduct } from '../../../../Types/IOrder'
import { MobileSlide } from '../../../../components/sliders/mobileSlider/MobileSliderStyled'
import MobileSlider from '../../../../components/sliders/mobileSlider/MobileSlider'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  grid-gap: 60px 0;
`

export const Title = styled.p`
  font-family: 'Theonory', sans-serif;
  font-weight: 200;
  font-size: 46px;
  text-align: center;
`

const Slider = styled(Swiper)`
  padding: 0 0 60px;
  width: 100%;

  .swiper-button-prev, .swiper-button-next {
    position: absolute;
    bottom: 10px;
    width: 30px;
    height: 30px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .swiper-button-prev {
    background-image: url(${iconArrowLeftLight});
    left: calc(50% - 50px);
  }

  .swiper-button-next {
    background-image: url(${iconArrowRightLight});
    right: calc(50% - 50px);
  }

  .swiper-button-prev {
    background-image: url(${iconArrowLeftLight});
    left: calc(50% - 50px);
  }

  .swiper-button-next {
    background-image: url(${iconArrowRightLight});
    right: calc(50% - 50px);
  }

  .swiper-button-disabled {
    opacity: 0.4;
    cursor: initial;
  }


`


const Slide = styled(SwiperSlide)`
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0 20px;
  background-color: ${({ theme }) => theme.colors.graphics};
  border-radius: 4px;

  @media (${({ theme }) => theme.media.mobile}) {
    padding: 2px;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    grid-gap: 20px 0;
    background-color: ${({ theme }) => theme.colors.background};
  }
`

export const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const SlideContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content min-content;
  grid-gap: 10px 0;


`

export const SlideTitle = styled.h3`
  font-family: 'Theonory', sans-serif;
  font-weight: 300;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.background};

  @media (${({ theme }) => theme.media.mobile}) {
    color: ${({ theme }) => theme.colors.graphics};
  };
`

export const SlideDescription = styled.div`
  ${MainText};
  color: ${({ theme }) => theme.colors.background};

  @media (${({ theme }) => theme.media.mobile}) {
    color: ${({ theme }) => theme.colors.graphics};
  };
`

export const SlidePrice = styled.p`
  font-weight: 300;
  font-size: 26px;
  text-align: end;
  color: ${({ theme }) => theme.colors.background};

  @media (${({ theme }) => theme.media.mobile}) {
    color: ${({ theme }) => theme.colors.graphics};
  };
`

export const ButtonAddToCart = styled.button`
  ${ButtonMainClearCss};
  width: 100%;

  @media (${({ theme }) => theme.media.mobile}) {
    ${ButtonMainCss};
    width: 100%;
  };
`

const getProductData = (treadsSection: IProductsSection, product: IProduct): IOrderProduct => {
    return {
        id: uuidv4(),
        status: 'created',
        sectionId: treadsSection.id,
        sectionName: treadsSection.title,
        name: product.title,
        quantity: String(product.minimalQuantity),
        cost: getProductPrice(
            product.price,
            product.price.variants[0] ? product.price.variants[0] : null
        ),
        productId: product.id,
        options: [],
        sale: ''
    }
}


const Treats: FC = () => {
    const dispatch = useAppDispatch()
    const treadsSection = useAppSelector((state) => (state.productReducer as IProductsPersistState).treadsSections[0])
    const { mobile } = useAppSelector((state) => state.appConditionReducer)

    const addToCardHandler = (product: IProduct) => {
        if (!treadsSection) return
        const productData = getProductData(treadsSection, product)
        dispatch(
            addProduct(productData)
        )
        dispatch(showMessage(`${product.title} added to cart`))
    }

    if (!treadsSection || !treadsSection.products.length) return null
    if (!mobile) {
        return (
            <Wrapper>
                <Title>Special Offer</Title>
                <Slider
                    slidesPerView={treadsSection.products.length > 1 ? 2 : 1}
                    spaceBetween={30}
                    modules={[Navigation]}
                    navigation={true}
                >
                    {treadsSection.products.map(product => (
                        <Slide>
                            <SlideImage src={getImageUrl(product.photo[0])} />
                            <SlideContainer>
                                <SlideTitle>{product.treadTitle}</SlideTitle>
                                <SlideDescription>{product.description}</SlideDescription>
                                <SlidePrice>£ {product.price.value}</SlidePrice>
                                <ButtonAddToCart onClick={() => addToCardHandler(product)}>Add to cart</ButtonAddToCart>
                            </SlideContainer>
                        </Slide>
                    ))}
                </Slider>
            </Wrapper>
        )
    } else {
        return (
            <Wrapper>
                <MainTitle>Special Offer</MainTitle>
                <MobileSlider>
                    {treadsSection.products.map(product => (
                        <MobileSlide>
                            <Slide>
                                <SlideImage src={getImageUrl(product.photo[0])} />
                                <SlideContainer>
                                    <SlideTitle>{product.treadTitle}</SlideTitle>
                                    <SlideDescription>{product.description}</SlideDescription>
                                    <SlidePrice>£ {product.price.value}</SlidePrice>
                                    <ButtonAddToCart
                                        onClick={() => addToCardHandler(product)}
                                    >
                                        Add to cart
                                    </ButtonAddToCart>
                                </SlideContainer>
                            </Slide>
                        </MobileSlide>
                    ))}
                </MobileSlider>
            </Wrapper>
        )
    }

}

export default Treats
