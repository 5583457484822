import { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import {
    Bar,
    Container,
    Error,
    Header,
    Item,
    List,
    Title,
    Wrapper
} from '../../../../components/DropDown/DropDownStyled'
import {
    ButtonCrossDark,
    ButtonDropDown
} from '../../../../UI/Buttons/ButtonsIcon'
import { IAddressData } from '../../../../Types/IUser'
import useOutsideClick from '../../../../hooks/useOutsideClick'

interface DropDownProps {
    title: string
    value: string
    options: IAddressData[]
    onChange: (value: IAddressData) => void
    onClear: () => void
    isTouched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
    error?:
        | string
        | string[]
        | FormikErrors<any>
        | FormikErrors<any>[]
        | undefined
}

const AddressesDropDown: FC<DropDownProps> = ({
                                                  value,
                                                  options,
                                                  title,
                                                  onChange,
                                                  onClear,
                                                  isTouched = false,
                                                  error = undefined
                                              }) => {
    const [containerIsOpen, setContainerIsOpen] = useState<boolean>(false)
    const [isChosen, setIsChosen] = useState<boolean>(false)
    const [currentTitle, setCurrentTitle] = useState(title)
    const wrapperRef = useRef<HTMLDivElement>(null)

    useOutsideClick(wrapperRef, () => setContainerIsOpen(false))

    useEffect(() => {
        if (value) {
            setCurrentTitle(value)
            setIsChosen(true)
        }
    }, [value])

    const clickHandler = (option: IAddressData) => {
        setCurrentTitle(option.address)
        setContainerIsOpen(false)
        setIsChosen(true)
        onChange(option)
    }

    const clearHandle = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setIsChosen(false)
        setContainerIsOpen(false)
        setCurrentTitle(title)
        onClear()
    }

    return (
        <Wrapper isChosen={isChosen} isOpen={containerIsOpen} ref={wrapperRef}>
            <Container isOpen={containerIsOpen} isChosen={isChosen}>
                <Header
                    onClick={() => setContainerIsOpen(!containerIsOpen)}
                    isChosen={isChosen}
                >
                    <Title>{currentTitle}</Title>
                    <Bar>
                        {isChosen && <ButtonCrossDark onClick={clearHandle} />}
                        <ButtonDropDown
                            isOpen={containerIsOpen}
                            isDark={isChosen}
                        />
                    </Bar>
                </Header>
                <List>
                    {!!options.length ? (
                        options.map((option, i) => (
                            <Item
                                onClick={() => clickHandler(option)}
                                isChosen={isChosen}
                                selected={currentTitle === option.address}
                                key={i}
                            >
                                {option.address}
                            </Item>
                        ))
                    ) : (
                        <Title>Address List Is Empty</Title>
                    )}
                </List>
            </Container>
            {isTouched && error && <Error>{String(error)}</Error>}
        </Wrapper>
    )
}

export default AddressesDropDown
