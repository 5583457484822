import { FC, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/store'
import {
    removeProduct,
    setProduct,
    setProductOption,
    setProductQuantity
} from '../../../../../../store/reducers/CartSlice/CartSlice'
import {
    IPriceVariant,
    IProduct,
    IProductOptions,
    IProductsSection
} from '../../../../../../Types/IProduct'
import { getImageUrl } from '../../../../../../utils/getImageUrl'
import {
    Bar,
    ButtonCross,
    ButtonMinus,
    ButtonPlus,
    Section,
    Quantity,
    QuantityValue,
    Title,
    Wrapper,
    Image,
    Text,
    SubTitle,
    Price,
    Container,
    PriceText,
    Info,
    InfoMain,
    InfoHeader,
    WrapperMobile,
    ContainerMobile
} from './CartProductStyled'
import { getIntervalPrice } from '../../../../../../utils/getIntervalPrice'
import DropDown, {
    IChangeDropDownData,
    IDropDownOption
} from '../../../../../../components/DropDown/DropDown'
import { IOption } from '../../../../../ProductPage/Types/Types'
import { IProductsPersistState } from '../../../../../../store/reducers/ProductsSlice/ProductsSlice'
import { IOrderProduct } from '../../../../../../Types/IOrder'

const getDropDownOptions = (product: IProduct | undefined, orderOptions: IOption[]) => {
    if (!product) return []
    const { filings, extraFilings, variants, price } = product
    const result = []
    if (price.type === 'variants') {
        const { currency } = price
        const options = price.variants.map((variant) => ({
            title: `${currency}${variant.cost} / ${variant.unit}`,
            value: variant.unit
        }))

        let defaultValue: IDropDownOption | null = null

        const priceOption = orderOptions.find(
            ({ title }) => title === 'priceVariant'
        )

        if (priceOption) {
            defaultValue = options.find(
                ({ value }) => value === priceOption.value
            ) as IDropDownOption
        }

        result.push({
            title: 'Variants',
            optionName: 'priceVariant',
            notClear: true,
            defaultValue,
            options
        })
    }
    if (!!filings.length) {
        let defaultValue: IDropDownOption | null = null

        const options = filings.map((filing) => ({
            title: filing.title,
            value: filing.title
        }))

        const filingOption = orderOptions.find(
            ({ title }) => title === 'filings'
        )

        if (filingOption) {
            defaultValue = options.find(
                ({ value }) => value === filingOption.value
            ) as IDropDownOption
        }

        result.push({
            title: 'Filings',
            optionName: 'filings',
            notClear: false,
            defaultValue,
            options
        })
    }

    if (!!extraFilings.filings.length) {
        let defaultValue: IDropDownOption | null = null

        const options = extraFilings.filings.map((filing) => ({
            title: filing.title,
            value: filing.title
        }))

        const filingOption = orderOptions.find(
            ({ title }) => title === 'extraFilings'
        )

        if (filingOption) {
            defaultValue = options.find(
                ({ value }) => value === filingOption.value
            ) as IDropDownOption
        }

        result.push({
            title: 'Filings',
            optionName: 'extraFilings',
            defaultValue,
            notClear: false,
            options
        })
    }

    if (!!variants.length) {
        let defaultValue: IDropDownOption | null = null

        const options = variants.map((variant) => ({
            title: variant.title,
            value: variant.title
        }))

        const variantOption = orderOptions.find(
            ({ title }) => title === 'variants'
        )

        if (variantOption) {
            defaultValue = options.find(
                ({ value }) => value === variantOption.value
            ) as IDropDownOption
        }

        result.push({
            title: 'Options',
            optionName: 'variants',
            defaultValue,
            options
        })
    }

    return result
}

interface CardProductProps {
    product: IOrderProduct
}

const CartProduct: FC<CardProductProps> = ({ product }) => {
    const { mobile } = useAppSelector((state) => state.appConditionReducer)
    const dispatch = useAppDispatch()

    const productPrice = useMemo(() => {
        return String((Number(product.cost) * Number(product.quantity)).toFixed(2))
    }, [product.quantity, product.cost])

    const sectionData = useAppSelector((state) => {
            const productsState = (state.productReducer as IProductsPersistState)
            const sections = [...productsState.treadsSections, ...productsState.productsSections]
            return sections.find(
                ({ title }) => title === product.sectionName
            )
        }
    )

    const productData = sectionData?.products.find(
        ({ id }) => id === product.productId
    )

    const dropDownOptions = getDropDownOptions(productData, product.options)

    const priceIsCalculated = !productData ? false :
        productData.price.type === 'interval' ||
        productData.price.type === 'only' ||
        productData.price.type === 'variants'

    const removeProductHandler = () => {
        dispatch(removeProduct(product.id))
    }

    const setQuantity = (quantityValue: string) => {
        if (!productData) return
        dispatch(
            setProductQuantity({
                quantity: quantityValue,
                productId: product.id
            })
        )
    }

    const increaseQuantity = () => {
        const quantityValue = String(Number(product.quantity) + 1)
        setQuantity(quantityValue)
    }

    const reduceQuantity = () => {
        if (!productData) return
        if (Number(product.quantity) <= productData.minimalQuantity) return
        const quantityValue = String(Number(product.quantity) - 1)
        setQuantity(quantityValue)
    }

    const setOption = (option: IChangeDropDownData) => {
        if (!productData) return
        let newCost = product.cost
        let newOptions = product.options

        if (option.optionName === 'priceVariant') {
            const priceVariant = productData.price.variants.find(
                (item) => item.unit === option.value
            ) as IPriceVariant
            newCost = priceVariant.cost
        }

        const currentOption = product.options.find(
            ({ title }) => title === option.optionName
        )

        if (!currentOption) {
            newOptions = [...newOptions,
                {
                    title: option.optionName as
                        | 'priceVariant'
                        | keyof IProductOptions,
                    value: option.value
                }]
        }

        dispatch(setProduct({
            ...product,
            cost: newCost,
            options: newOptions
        }))
    }

    const removeOption = (optionName: string) => {
        dispatch(
            setProduct({
                ...product,
                options: product.options.filter(
                    ({ title }) => title !== optionName
                )
            })
        )
    }

    if (!mobile) {
        return (
            <Wrapper>
                <Image src={productData ? getImageUrl(productData.photo[0]) : ''} />
                <Section>
                    <Title>{product.name}</Title>
                    <Container>
                        <SubTitle>Section: {product.sectionName}</SubTitle>
                    </Container>
                </Section>
                <Section>
                    <Title>Options</Title>
                    <Container>
                        {dropDownOptions.map((item, i) => (
                            <DropDown
                                title={item.title}
                                options={item.options}
                                optionName={item.optionName}
                                key={i}
                                defaultValue={item.defaultValue}
                                onChange={setOption}
                                onClear={removeOption}
                                notClear={true}
                            />
                        ))}
                    </Container>
                </Section>
                <Bar>
                    <ButtonCross type='button' onClick={removeProductHandler} />
                    <Quantity>
                        <ButtonMinus type='button' onClick={reduceQuantity} />
                        <QuantityValue>{product.quantity}</QuantityValue>
                        <ButtonPlus type='button' onClick={increaseQuantity} />
                    </Quantity>
                    {priceIsCalculated ? (
                        <Price>£ {productPrice}</Price>
                    ) : (
                        <PriceText>{productData ? productData.price.value : ''}</PriceText>
                    )}
                </Bar>
            </Wrapper>
        )
    } else {
        return (
            <WrapperMobile>
                <ContainerMobile>
                    <Image src={productData ? getImageUrl(productData.photo[0]) : ''} />
                    <Info>
                        <InfoMain>
                            <InfoHeader>
                                <Title>{product.name}</Title>
                                <SubTitle>
                                    Section: {product.sectionName}
                                </SubTitle>
                            </InfoHeader>
                            <Quantity>
                                <ButtonMinus
                                    type='button'
                                    onClick={reduceQuantity}
                                />
                                <QuantityValue>
                                    {product.quantity}
                                </QuantityValue>
                                <ButtonPlus
                                    type='button'
                                    onClick={increaseQuantity}
                                />
                            </Quantity>
                        </InfoMain>
                        <ButtonCross
                            type='button'
                            onClick={removeProductHandler}
                        />
                    </Info>
                </ContainerMobile>
                <Section>
                    <Title>Options</Title>
                    {dropDownOptions.map((item, i) => (
                        <DropDown
                            title={item.title}
                            options={item.options}
                            optionName={item.optionName}
                            key={i}
                            defaultValue={item.defaultValue}
                            onChange={setOption}
                            onClear={removeOption}
                            notClear={item.notClear}
                        />
                    ))}
                    {priceIsCalculated ? (
                        <Price>£ {productPrice}</Price>
                    ) : (
                        <PriceText>{productData ? productData.price.value : ''}</PriceText>
                    )}
                </Section>
            </WrapperMobile>
        )
    }
}

export default CartProduct
