import { AppDispatch } from '../../store'
import { instance } from '../../../API/baseAPI'
import { ICreateOrder } from '../User/Types'
import { setFlowLink } from './CartSlice'
import { errorHandler } from '../../Helpers/errorHandler'

export const createTransaction = (data: ICreateOrder) => async (dispatch: AppDispatch) => {
    try {
        const response = await instance.post<string>('/payments/customer/transactions/clear', data)
        return response.data
    } catch (err: any) {
        dispatch(errorHandler(err))
    }
}