import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { iconArrowLeftLight, iconArrowRightLight } from '../../../UI/Icons/icons'

export const Slider = styled(Swiper)`
  padding: 100px 0 100px 0;
  width: 100%;
  max-width: calc(${({ theme }) => theme.size.pageWidth});

  .swiper-wrapper {
    display: flex;
    align-items: stretch;
  }

  .swiper-button-prev, .swiper-button-next {
    position: absolute;
    bottom: 20px;
    width: 30px;
    height: 30px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .swiper-button-prev {
    background-image: url(${iconArrowLeftLight});
    left: calc(50% - 50px);
  }

  .swiper-button-next {
    background-image: url(${iconArrowRightLight});
    right: calc(50% - 50px);
  }

  .swiper-button-disabled {
    opacity: 0.4;
    cursor: initial;
  }

  .swiper-slide-active {
    transform: scale(1.2);
    z-index: 100;
  }

  .swiper-slide-next {
    z-index: 1;
  }
`

export const Slide = styled(SwiperSlide)`
  height: auto;
  transition: 0.3s;
  z-index: 2;
`
