import styled, { css } from 'styled-components'
import { MainText } from '../../UI/Common/common'
import { ButtonCross } from '../../UI/Buttons/ButtonsIcon'

interface WrapperProps {
    isVisible: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  padding: 30px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 400px;
  border: 1px solid ${({ theme }) => theme.colors.graphics};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  visibility: hidden;
  opacity: 0;

  ${({ isVisible }) => isVisible && css`
    visibility: visible;
    opacity: 1;
  `} @media (${({ theme }) => theme.media.mobile}) {
    width: calc(100vw - 20px);
  }
`

export const Text = styled(MainText)`
  margin: 0;
`

export const ButtonClose = styled(ButtonCross)`
  position: absolute;
  top: 7px;
  right: 7px;
`