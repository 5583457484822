import { IPrice, IPriceVariant } from '../Types/IProduct'

export const getProductPrice = (
    price: IPrice,
    variant: IPriceVariant | null
): string => {
    if (price.type === 'request' || price.type === 'custom') {
        return ''
    }

    const { type, value, intervals } = price

    if (type === 'only') {
        return value ? value : ''
    }
    if (type === 'interval') {
        return ''
    }

    if (type === 'variants') {
        return variant ? variant.cost : ''
    }
    return ''
}