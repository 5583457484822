import { FC } from 'react'
import OrderCard from './Components/OrderCard/OrderCard'
import { useAppSelector } from '../../hooks/store'
import { Container, Title, Wrapper } from './OrdersStyled'
import { IOrder } from '../../Types/IOrder'

const getTargetOrders = (orders: IOrder[], isComplete: boolean) => {
    return orders.filter(({ status }) => {
        if (isComplete) {
            return status === 'complete'
        }
        return status !== 'complete'
    }).sort((a, b) => {
        if (a.delivery.date === b.delivery.date) {
            if (a.delivery.time > b.delivery.time) {
                return 1
            }
            return -1
        }
        if (a.delivery.date > b.delivery.date) {
            return 1
        }
        return -1
    })
}

const Orders: FC = () => {
    const { user } = useAppSelector((state) => state.userReducer)

    if (!user) return null

    return (
        <Wrapper>
            <Container>
                <Title>
                    {user.orders.length!! ? 'Current Orders' : 'Orders list is empty'}
                </Title>
                {getTargetOrders(user.orders, false).map(order => (
                    <OrderCard order={order} key={order.id} />
                ))}
            </Container>
            {!!user.orders.find(({ status }) => status === 'complete') && (
                <Container>
                    <Title>Completed Orders</Title>
                    {getTargetOrders(user.orders, true).map(order => (
                        <OrderCard order={order} key={order.id} />
                    ))}
                </Container>
            )}
        </Wrapper>
    )
}

export default Orders
