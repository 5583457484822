import { FC, useState } from 'react'
import { IPrice, IPriceVariant } from '../../../../../../Types/IProduct'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    grid-gap: 10px 0;
`

interface VariantProps {
    isActive: boolean
}

export const Variant = styled.div<VariantProps>`
    padding: 5px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.graphics};
    border-radius: 4px;
    font-size: 21px;
    cursor: pointer;

    ${({ isActive }) =>
        isActive &&
        css`
            background-color: ${({ theme }) => theme.colors.graphics};
            color: ${({ theme }) => theme.colors.background};
        `} @media (${({ theme }) => theme.media.mobile}) {
        font-size: 18px;
    }
`

interface PriceVariantsProps {
    price: IPrice
    onChangeVariant: (value: IPriceVariant) => void
}

const PriceVariants: FC<PriceVariantsProps> = ({ price, onChangeVariant }) => {
    const [activeIndex, setActiveIndex] = useState(0)

    const clickHandler = (variant: IPriceVariant, index: number) => {
        onChangeVariant(variant)
        setActiveIndex(index)
    }

    return (
        <Wrapper>
            {price.variants.map((variant, i) => (
                <Variant
                    isActive={activeIndex === i}
                    onClick={() => clickHandler(variant, i)}
                    key={i}
                >
                    {price.currency}
                    {variant.cost} / {variant.unit}
                </Variant>
            ))}
        </Wrapper>
    )
}

export default PriceVariants
