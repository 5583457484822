function getObjectDifference(initialData: any, currentData: any) {
    const result: any = {}
    Object.entries(currentData).forEach(([key, value]) => {
        if (value === initialData[key]) {
            return
        } else {
            result[key] = value
        }
    })
    if (Object.entries(result).length === 0) {
        return false
    } else {
        return result
    }
}

export default getObjectDifference
