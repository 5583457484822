import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import storage from 'reduxjs-toolkit-persist/lib/storage'
import { persistReducer } from 'reduxjs-toolkit-persist'
import {
    ISetProductOption,
    ISetProductQuantity
} from './Types/Types'
import { IProductsSectionBD } from '../../../Types/IProduct'
import { IOrderProduct } from '../../../Types/IOrder'

const persistCardConfig = {
    key: 'card',
    storage
}

export interface ICardPersistState {
    products: IOrderProduct[]
    flowLink: string
}

const initialState: ICardPersistState = {
    products: [],
    flowLink: ''
}

export const cartSlice = createSlice({
    name: 'card',
    initialState,
    reducers: {
        addProduct(state, action: PayloadAction<IOrderProduct>) {
            state.products = [...state.products, action.payload]
        },
        removeProduct(state, action: PayloadAction<string>) {
            state.products = state.products.filter(
                ({ id }) => id !== action.payload
            )
        },
        setProductQuantity(state, action: PayloadAction<ISetProductQuantity>) {
            const { productId, quantity } = action.payload
            state.products = state.products.map((product) =>
                product.id === productId
                    ? {
                        ...product,
                        quantity
                    }
                    : product
            )
        },
        setProductOption(state, action: PayloadAction<ISetProductOption>) {
            const { productId, option } = action.payload
            const product = state.products.find(({ id }) => id === productId)
            if (product) {
                product.options = product.options.map((productOption) =>
                    productOption.title === option.title
                        ? { ...productOption, value: option.value }
                        : productOption
                )
            }
        },
        setProduct(state, action: PayloadAction<IOrderProduct>) {
            state.products = state.products.map((product) =>
                product.id === action.payload.id ? action.payload : product
            )
        },
        clearProducts(state) {
            state.products = []
        },
        checkProducts(state, action: PayloadAction<IProductsSectionBD[]>) {
            const products: string[] = []
            action.payload.forEach(sections => {
                sections.products.forEach(product => products.push(product.id))
            })
            state.products = state.products.filter(({ productId }) => products.includes(productId))
        },
        setFlowLink(state, action: PayloadAction<string>) {
            state.flowLink = action.payload
        }
    }
})

export const {
    addProduct,
    setProduct,
    removeProduct,
    setProductQuantity,
    setProductOption,
    clearProducts,
    checkProducts,
    setFlowLink
} = cartSlice.actions

export default persistReducer(persistCardConfig, cartSlice.reducer)
