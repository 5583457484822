import styled from 'styled-components'

export const Wrapper = styled.a`
    margin: 0 0 10px 0;
    padding: 0;
    text-decoration: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, max-content);
    grid-auto-flow: column;
    grid-template-rows: 1fr;
    grid-gap: 0 10px;
    align-items: center;
    text-decoration: none;
`

interface IconProps {
    url: string
}

export const Icon = styled.div<IconProps>`
    width: 20px;
    height: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${({ url }) => url});
`

export const Title = styled.p`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.graphics};
`
