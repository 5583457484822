import { FC } from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import { TextArea, Label, Placeholder, Error } from '../FieldsStyled'

interface FieldProps {
    name: string
    value: string
    placeholder: string
    onChange: any
    isTouched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
    error: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined
}

const FieldText: FC<FieldProps> = ({
                                       name,
                                       value,
                                       onChange,
                                       placeholder,
                                       isTouched,
                                       error
                                   }) => {

    return (
        <Label>
            <Placeholder>{placeholder}</Placeholder>
            <TextArea
                name={name}
                value={value}
                onChange={onChange}
                autoComplete='offf'
            />
            {isTouched && error && <Error>{String(error)}</Error>}
        </Label>
    )
}

export default FieldText
