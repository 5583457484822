import React, { FC, ReactNode } from 'react'
import { Navigation } from 'swiper'
import 'swiper/css'
import { Slider } from './LaptopSliderStyled'

interface SliderProps {
  children: ReactNode;
}

const LaptopSlider: FC<SliderProps> = ({ children }) => {
  return (
    <Slider
      initialSlide={1}
      spaceBetween={20}
      centeredSlides={true}
      slidesPerView={3}
      modules={[Navigation]}
      navigation={true}
    >
      {children}
    </Slider>
  );
};

export default LaptopSlider;
