import { FC } from 'react'
import { Wrapper } from './TimeItemStyled'

interface TimeItemProps {
    value: string
    isActive: boolean
    isNoActive: boolean
    onClick: (value: string) => void
}

const TimeItem: FC<TimeItemProps> = ({
    value,
    isActive,
    isNoActive,
    onClick
}) => {
    const clickHandler = () => {
        if (isNoActive) return
        onClick(value)
    }

    return (
        <Wrapper
            isActive={isActive}
            isNoActive={isNoActive}
            onClick={clickHandler}
        >
            {value}
        </Wrapper>
    )
}

export default TimeItem
