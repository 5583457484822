import styled from 'styled-components'
import { MainText, MainTitle } from '../../../../UI/Common/common'

export const Wrapper = styled.div`
    margin: 0 auto 0 auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (${({ theme }) => theme.media.mobile}) {
        margin: 0 0 0 0;
        width: 100%;
    }
`

export const Container = styled.div`
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (${({ theme }) => theme.media.mobile}) {
        margin: 0 0 40px 0;
        align-items: flex-start;
    }
`

export const Title = styled(MainTitle)`
    margin: 0 0 70px 0;

    @media (${({ theme }) => theme.media.mobile}) {
        width: 100%;
        margin: 0 0 60px 0;
    }
`

export const Question = styled(MainText)`
    margin: 0 0 20px 0;
    font-size: 24px;

    @media (${({ theme }) => theme.media.mobile}) {
        margin: 0 0 20px 0;
        line-height: 24px;
        font-size: 18px;
        left: 18px;
        font-weight: 500;
    }
`

export const Answer = styled(MainText)``
