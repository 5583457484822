import React, { FC } from 'react'
import { Container, Location, Name, Person, Text, Wrapper } from './FeedBackCardStyled'
import { IFeedBack } from '../../Types/IFeedBack'

interface FeedBackCardProps {
    data: IFeedBack
}

const FeedBackCard: FC<FeedBackCardProps> = ({ data }) => {
    return (
        <Wrapper>
            <Text>{data.text}</Text>
            <Container>
                <Person>
                    <Name>{data.name}</Name>
                    <Location>{data.location}</Location>
                </Person>
            </Container>
        </Wrapper>
    )
}

export default FeedBackCard
