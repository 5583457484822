import { FC } from 'react'
import '../../Styles/fonts.css'
import AnimateImage from './Components/AnimateImage/AnimateImage'
import { Container, SubTitle, Title, Wrapper } from './LoaderStyled'

const Loader: FC = () => {
    return (
        <Wrapper>
            <Container>
                <Title>Welcome to Jlena's Cake</Title>
                <SubTitle>Please wait, app is loading...</SubTitle>
                <AnimateImage />
            </Container>
        </Wrapper>
    )
}

export default Loader
