import styled, { css } from 'styled-components'

interface WrapperProps {
    isActive: boolean
    isNoActive: boolean
}

export const Wrapper = styled.div<WrapperProps>`
    padding: 0 0 0 33%;
    color: ${({ theme }) => theme.colors.background};
    cursor: pointer;
    border-radius: 4px;

    :hover {
        outline: 1px solid ${({ theme }) => theme.colors.background};
    }

    ${({ isActive }) =>
        isActive &&
        css`
            background-color: ${({ theme }) => theme.colors.background};
            color: ${({ theme }) => theme.colors.graphics};

            :hover {
                background-color: ${({ theme }) => theme.colors.background};
            }
        `}
    ${({ isNoActive }) =>
        isNoActive &&
        css`
            opacity: 0.4;
            cursor: initial;

            :hover {
                background-color: ${({ theme }) => theme.colors.graphics};
            }
        `}
`
