import { IFeedBack } from '../../Types/IFeedBack'

export const feedBackList: IFeedBack[] = [
    {
        name: 'Shefali',
        location: 'London, UK',
        avatar: '',
        text: 'Hi Jelena – just wanted to say a huge thank you for the fabulous and delicious cake – everyone loved it especially the birthday girls! I’ll be back in touch for my other daughters cake in January if that’s ok! Thanks'
    },
    {
        name: 'Jackie, Little three birds events',
        location: 'London, UK',
        avatar: '',
        text: 'Hello Jelena’s Cake Seeing your message this morning has made me smile! I said first thing, I will message you to say THANK YOU! The day was amazing and the cake was WOW! The flavours delicious. People couldn’t stop complimenting Jelena’s Cake so I’ve sent them your details'
    },
    {
        name: 'Natali',
        location: 'London, UK',
        avatar: '',
        text: 'Thank you so much, I cannot thank you for the cake for my partner, the 40th birthday cake with the cherry chocolate was SO DELICIOUS So worth the calories'
    },
    {
        name: 'Serwa',
        location: 'London, UK',
        avatar: '',
        text: 'Good evening Jelena, I trust you are well. I just wanted to say a big thank you. The cake is delicious. Good job, the decoration was well executed. Definitely will recommend! Thank you so much'
    },
    {
        name: 'Stella',
        location: 'London, UK',
        avatar: '',
        text: 'Hi Jelena, I just want to say THANK YOU for the absolutely amazing cake. It was beyond delicious and the decoration was even better than I expected. It was one of the lighlights of the day. I will be back for many celebratory occasions in the future. Thanks again!'
    },
    {
        name: 'Наталия',
        location: 'London, UK',
        avatar: '',
        text: 'Елена, торт был просто офигительный! Ни я, никто на празднике такого не видел в жизни. И вкуснотища как всегда! Спасибо!'
    },
    {
        name: 'Hanyi Bao',
        location: 'London, UK',
        avatar: '',
        text: 'Hi Jelena Just want to say big thank you to your service! The cake was super delicious and the Delivery was on the exact time frame. It was so tasty so all the parents want the contact, I have passed on your website hope it will not make you too busy ;)'
    },
    {
        name: 'Катерина',
        location: 'London, UK',
        avatar: '',
        text: 'Елена, хотела вас поблагодарить за торт! Это просто чудо! Вкуснейший, все гости были в восторге. А ребёнок просто светился от счастья!'
    },
    {
        name: 'Ernesta',
        location: 'London, UK',
        avatar: '',
        text: 'Hello Jelena, thank you for the most beautiful cake and cake pops for Cassius’ birthday!'
    }

]
