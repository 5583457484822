import { FC, useState } from 'react'
import Parameter from '../Parametr/Parameter'
import { allergenic } from '../../../../Constants/PagesTexts/allergenic'
import { Wrapper } from './ParametersStyled'
import { Content } from '../Parametr/ParametrStyled'
import { LineLink } from '../../../../UI/Common/common'
import { IDeliveryMethod } from '../../../../Types/IProduct'

interface ParametersProps {
    ingredients: string
    storage: string
    delivery: IDeliveryMethod[]
}

const Parameters: FC<ParametersProps> = ({
    ingredients,
    storage,
    delivery
}) => {
    const [openedParameter, setOpenedParameter] = useState<number>(0)

    return (
        <Wrapper>
            <Parameter
                num={1}
                openedParameter={openedParameter}
                clickHandler={setOpenedParameter}
                title="Ingredients"
            >
                <Content>{ingredients}</Content>
            </Parameter>
            <Parameter
                num={2}
                openedParameter={openedParameter}
                clickHandler={setOpenedParameter}
                title="Allergens Present"
            >
                <Content>{allergenic}</Content>
            </Parameter>
            <Parameter
                num={3}
                openedParameter={openedParameter}
                clickHandler={setOpenedParameter}
                title="Delivery Information"
            >
                {delivery.map((item, i) => (
                    <Content key={i}>{item.title}</Content>
                ))}
                <LineLink href="/delivery">Learn more</LineLink>
            </Parameter>
            <Parameter
                num={4}
                openedParameter={openedParameter}
                clickHandler={setOpenedParameter}
                title="Storage"
            >
                <Content>{storage}</Content>
            </Parameter>
        </Wrapper>
    )
}

export default Parameters
