import { IAskedQuestion, IShortQuestion } from '../../Types/IAskedQuestion'
import { externalLinks } from '../externalLinks'

export const askedQuestionsData: IAskedQuestion[] = [
    {
        title: 'What cake flavours do you offer?',
        text: [
            'Chocolate Vanilla Cake*',
            'Chocolate Honey Cake*',
            'Chocolate Cake*',
            'Yogurt Cake*',
            'Honey Cake*',
            'Vanilla Cake*',
            'Natural Colour Velvet ',
            'Chocolate Cherry Cake',
            'Rainbow Cake',
            'Caramel Cake',
            'Arabian Night',
            'Oreo Cake',
            '*- Included in #link$internal$/shop/tasting_box$Tasting box#'
        ]
    },
    {
        title: 'How far in advance do I need to place an order?',
        text: [
            'Usually we recommend placing an order 3-6 weeks in advance to secure your place and allow us time to confirm all the details. However, order placement also depends on the availability of the date requested'
        ]
    },
    {
        title: 'How can I try the cake flavours?',
        text: [
            'We have a tasting box with the most requested and popular cake flavours on sale, just place an order through our website in the section #link$internal$/shop/tasting_box$Tasting box#'
        ]
    },
    {
        title: 'How much do your cakes and desserts cost?',
        text: [
            'CAKES',
            'The price of the cake depends on the complexity of the design / decoration and starts from £60.00',
            'One tire cake:',
            '8 inches (8-12 portions)',
            '9 inches (12-16 portions)',
            '10 inches (16-20 portions)',
            'span',
            'Two tires cake:',
            '8 inches + 6 inches (20-25 portions)',
            '9 inches + 7 inches (25-30 portions)',
            '10 inches + 8 inches (30-35 portions)',
            'span',
            'Three and Four tire cakes are also available if requested',
            'span',
            'CUPCAKES',
            'Order quantity is 6 / 9 / 12 pcs in the box',
            'The price of the cupcakes depends on the complexity of the design / decoration and starts from £4.00 per 1 pcs',
            'span',
            'CAKE POPS',
            'Minimal order quantity is 12 pcs',
            'The price of the cake pops depends on the complexity of the design / decoration and starts from £4.00 per 1 pcs',
            'span',
            'GLAZED COOKIES',
            'Minimal order quantity is 12 pcs',
            'The price of the cake pops depends on the complexity of the design / decoration and starts from £4.00 per 1 pcs'
        ]
    },
    {
        title: 'Delivery, Order collection & Transportation?',
        text: [
            'We can deliver your order for a fee, which depends on the location of your venue. The price of the Delivery will be announced whilst you place your order. Aprox. info of the Delivery prices can be found in the Delivery section on our website',
            'Order collection is also available and is free of charge. In that case,  Jelena\'s  Cake Ltd is not responsible for the safety of the products in transit, since we are unable to control all the aspects of transportation and all responsibility rests on the person who collected the order',
            'We recommend most cakes or desserts go flat on the floor or boot space of the vehicle during transportation time. Any special requirements for travel will be given at time of collection. Please note that once the cake or desserts has left Jelena’s Cake Ltd we cannot take any responsibility for the damage caused in relation to the travel process'
        ]
    },
    {
        title: 'Will my cake or desserts come on a board and in a box?',
        text: ['Yes, all our products come on a cake board and in a box']
    },
    {
        title: 'Changes to my order?',
        text: [
            'It happens. We understand. What we do tend to ask is that you try to keep all changes prior to the 7 days leading up to the Delivery or collection date. Customers will be notified of any price changes in regards to order changes, before they are confirmed'
        ]
    },
    {
        title: 'Can I see more of your work?',
        text: [
            `Yes, the best way to keep up to date with our work and latest offers is our instagram #link$external$${externalLinks.instagram}$@jelenas_cake_ltd#`
        ]
    },
    {
        title: 'I’ve sent through an email, when will I hear back?',
        text: [
            'We will be in touch with you as soon as possible, usually we would email you back from 10:00-18:00 on Monday – Friday.',
            'Any enquiries sent after 12:00 on Friday or at any time during Saturday and Sunday will only get seen to on Monday'
        ]
    }
]

export const mainAskedQuestion: IShortQuestion[] = [
    {
        question: 'How far in advance do I need to place an order?',
        answer:
            'Usually we recommend placing an order 3-6 weeks in advance to secure your place and allow us time to confirm all the details. However, order placement also depends on the availability of the date requested'
    },
    {
        question: 'How can I try the cake flavours?',
        answer:
            'We have a tasting box with the most requested and popular cake flavours on sale, just contact us through our website in the section “Tasting box”'
    },
    {
        question: 'Changes to my order?',
        answer:
            'It happens. We understand. What we do tend to ask is that you try to keep all changes prior to the 7 days leading up to the Delivery or collection date. Customers will be notified of any price changes in regards to order changes, before they are confirmed'
    },
    {
        question: 'Can I see more of your work?',
        answer:
            'Yes, the best way to keep up to date with our work and latest offers is our instagram @jelenas_cake_ltd'
    }
]
