import React, { FC, useState } from 'react'
import { Container, Header, Title, Wrapper } from './ExtraFilingsStyled'
import ExtraFiling from './ExtraFiling'
import { IExtraFiling } from '../../../../Types/IProduct'
import { IOption } from '../../Types/Types'
import { defaultIngredients } from '../../Constants/defaultIngredients'
import { ButtonDropDown } from '../../../../UI/Buttons/ButtonsIcon'

interface ExtraFilingsProps {
    filings: IExtraFiling[]
    title: string
    onSetIngredients: (value: string) => void
    addOption: (option: IOption) => void
    setOption: (option: IOption) => void
    removeOption: (option: IOption) => void
}

const ExtraFilings: FC<ExtraFilingsProps> = ({
                                                 filings,
                                                 title,
                                                 onSetIngredients,
                                                 addOption,
                                                 setOption,
                                                 removeOption
                                             }) => {

    const [containerIsOpen, setContainerIsOpen] = useState<boolean>(false)
    const [currentFiling, setCurrentFiling] = useState<IExtraFiling | null>(null)


    const optionsClickHandler = () => {
        setContainerIsOpen(!containerIsOpen)
    }

    const filingClickHandler = (filing: IExtraFiling, isActive: boolean) => {

        if (isActive) {
            setCurrentFiling(null)
            removeOption({ title: 'extraFilings', value: filing.title })
            onSetIngredients(defaultIngredients)
            return
        }

        setCurrentFiling(filing)
        if (!currentFiling) {
            addOption({ title: 'extraFilings', value: filing.title })
        } else {
            setOption({ title: 'extraFilings', value: filing.title })
        }
        setContainerIsOpen(false)
        onSetIngredients(filing.ingredients)
        if (window.scrollY + 300 > window.screen.height) {
            window.scrollTo({
                top: 76,
                behavior: 'smooth'
            })
        }
    }

    if (!filings.length) return null

    return (
        <Wrapper>
            <Header onClick={optionsClickHandler}>
                <Title>{title}</Title>
                <ButtonDropDown isOpen={containerIsOpen} />
            </Header>
            {currentFiling ? (
                <ExtraFiling
                    filing={currentFiling}
                    isActive={true}
                    clickHandler={filingClickHandler}
                />
            ) : null}
            <Container isOpen={containerIsOpen}>
                {filings.filter(({ title }) => title !== (currentFiling ? currentFiling.title : '')).map((item, i) => (
                    <ExtraFiling
                        key={i}
                        filing={item}
                        clickHandler={filingClickHandler}
                        isActive={false}
                    />
                ))}
            </Container>
        </Wrapper>
    )
}

export default ExtraFilings
