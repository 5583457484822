import axios from 'axios'

export const baseAPI = process.env.REACT_APP_API_URL

export const instance = axios.create({
    withCredentials: true,
    baseURL: baseAPI
})

// import axios from 'axios'
//
// let api: any = 'http://192.168.0.108:5000'
// if (navigator.platform === 'Win32') {
//     api = process.env.REACT_APP_API_URL
// }
//
// export const baseAPI = api
//
// // export const baseAPI = process.env.REACT_APP_API_URL
//
// // export const baseAPI = 'http://192.168.0.166:5000'
//
// export const instance = axios.create({
//     withCredentials: true,
//     baseURL: baseAPI
// })
