import { FC, useState } from 'react'
import styled from 'styled-components'
import FormProfile from './Components/Forms/FormProfile/FormProfile'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import Addresses from './Components/Addresses/Addresses'
import FormChangePassword from './Components/Forms/FormChangePassword/FormChangePassword'
import { logOut } from '../../store/reducers/User/ActionCreators'
import { useNavigate } from 'react-router'
import { Bar, BarButton, Wrapper } from './ProfileStyled'
import RemoveAccount from './Components/RemoveAccount/RemoveAccount'

const Profile: FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [formChangePasswordIsVisible, setFormChangePasswordIsVisible] =
        useState<boolean>(false)
    const [removeAccountIsVisible, setRemoveAccountIsVisible] = useState<boolean>(false)
    const { user } = useAppSelector((state) => state.userReducer)

    const logoutClickHandler = async () => {
        await dispatch(logOut())
        navigate('/')
    }

    if (!user) return null

    return (
        <Wrapper>
            <FormChangePassword
                userId={user.id}
                isVisible={formChangePasswordIsVisible}
                onClose={() => setFormChangePasswordIsVisible(false)}
            />
            <FormProfile profile={user} />
            <RemoveAccount
                isVisible={removeAccountIsVisible}
                onClose={() => setRemoveAccountIsVisible(false)}
                userId={user.id}
            />
            <Addresses userId={user.id} addresses={user.addressesData} />
            <Bar>
                <BarButton onClick={() => setFormChangePasswordIsVisible(true)}>
                    Change Password
                </BarButton>
                <BarButton onClick={logoutClickHandler}>Log Out</BarButton>
                <BarButton onClick={() => setRemoveAccountIsVisible(true)}>Delete Account</BarButton>
            </Bar>
        </Wrapper>
    )
}

export default Profile
