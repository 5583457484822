import styled from 'styled-components'
import { MainTitle } from '../../UI/Common/common'

export const TitleCenter = styled(MainTitle)`
    margin: 40px;
    text-align: center;
`

export const Wrapper = styled.div`
    height: 40vh;
`
