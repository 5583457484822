import { useAppSelector } from '../../hooks/store'
import { IAppOptionsState } from '../../store/reducers/AppOptions/AppOptionsSlice'
import { GoogleMaps } from './GoogleMaps'

export const useGoogleMap = () => {
    const { originDeliveryAddress, distanceRatio, congestionChargeZone } =
        useAppSelector(
            (state) => (state.appOptionsReducer as IAppOptionsState).appDelivery
        )

    const mapService = new GoogleMaps(originDeliveryAddress)

    const getPredictions = async (value: string) => {
        const newPredictions = await mapService.getPredictions(value)
        if (newPredictions) {
            return newPredictions
        } else {
            return []
        }
    }

    const getPostCode = async (place_id: string) => {
        const postCode = await mapService.getPostcode(place_id)
        let isCongestionZone = false
        if (postCode) {
            const postcodeDistrict = postCode.split(' ')[0]
            if (congestionChargeZone.includes(postcodeDistrict)) {
                isCongestionZone = true
            }
        }
        return {
            postCode,
            isCongestionZone
        }
    }

    const getDistance = async (place_id: string) => {
        return await mapService.getDistance(place_id)
    }

    return { getPredictions, getPostCode, getDistance }
}
