import { FC, ReactNode } from 'react'
import { Formik } from 'formik'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import * as Yup from 'yup'
import { yupValidation } from '../../../../Constants/yupValidation'
import { addOrder, createOrder } from '../../../../store/reducers/User/ActionCreators'
import { clearProducts, ICardPersistState } from '../../../../store/reducers/CartSlice/CartSlice'
import { IDateForCreateOrder } from '../../../../Types/IOrder'
import { getProductsPrice, requestPriceValue } from '../../../../Helpers/getProductsPrice'
import { createTransaction } from '../../../../store/reducers/CartSlice/ActionCreators'

export interface ICartInitialValues extends Omit<IDateForCreateOrder, 'products'> {
    firstName: string
    lastName: string
    email: string
    phone: string
    message: string
}

interface CartFormikProps {
    onShowMessage: () => void
    children: ReactNode
}

const CartFormik: FC<CartFormikProps> = ({ children, onShowMessage }) => {
    const { user } = useAppSelector((state) => state.userReducer)
    const dispatch = useAppDispatch()
    const { products } = useAppSelector(
        (state) => state.persistCardReducer as ICardPersistState
    )

    const initialValues: ICartInitialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        delivery: {
            method: '',
            date: '',
            time: '',
            secondTime: '',
            cost: ''
        },
        deliveryPlace: {
            address: '',
            postCode: '',
            floor: '',
            flat: '',
            deliveryDistance: '',
            isPaidParking: false
        }
    }

    const validationSchema = Yup.object({
        firstName: !user ? yupValidation.onlyText : Yup.string(),
        lastName: !user ? yupValidation.onlyText : Yup.string(),
        email: !user ? yupValidation.email : Yup.string(),
        phone: !user ? yupValidation.phone : Yup.string(),
        delivery: Yup.object({
            method: Yup.string().required('The field is required'),
            date: Yup.string().required('The field is required'),
            time: Yup.string().required('The field is required')
        }),
        deliveryPlace: Yup.object().when(
            'delivery.method',
            (deliveryMethod, schema) => {
                if (
                    deliveryMethod === 'delivery' ||
                    deliveryMethod === 'royalMail'
                )
                    return Yup.object().shape({
                        address: Yup.string().required('The field is required')
                    })
                return Yup.object().shape({
                    address: Yup.string()
                })
            }
        )
    })

    const createOrderHandler = async (values: ICartInitialValues): Promise<boolean | undefined> => {
        if (user) {
            const { firstName, lastName, email, phone, ...rest } = values
            return await dispatch(
                addOrder({
                    ...rest,
                    products: products,
                    clientId: user.id
                })
            )
        } else {
            return await dispatch(
                createOrder({ ...values, products: products })
            )
        }
    }

    const createTransactionHandler = async (values: ICartInitialValues): Promise<boolean> => {
        let transactionData = { ...values, products: products }
        if (user) {
            transactionData = {
                ...transactionData,
                email: user.email,
                phone: user.phone,
                firstName: user.firstName,
                lastName: user.lastName
            }
        }
        const flowLink = await dispatch(
            createTransaction(transactionData)
        )
        if (flowLink) {
            window.location.replace(flowLink)
            return true
        }
        return false
    }

    const submitHandler = async (values: ICartInitialValues) => {
        if (getProductsPrice(products) === requestPriceValue) {
            const isCreated = await createOrderHandler(values)
            if (isCreated) {
                dispatch(clearProducts())
                onShowMessage()
            }
        } else {
            await createTransactionHandler(values)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
        >
            {children}
        </Formik>
    )
}

export default CartFormik
