import { FC, useRef } from 'react'
import TimeItem from './Components/TimeItem'
import {
    Container,
    Header,
    HeaderTitle,
    Line,
    Wrapper
} from './TimePickerStyled'
import { getTimeIntervals } from './Helpers/getTimeIntervals'
import useOutsideClick from '../../hooks/useOutsideClick'

interface TimePickerProps {
    selectedDate: string
    isOpen: boolean
    onClose: () => void
    firstValue: string
    secondValue: string
    firstName: string
    secondName: string
    onChange: (field: string, value: any, shouldValidate?: boolean) => void
}

const TimePicker: FC<TimePickerProps> = ({
                                             isOpen,
                                             onClose,
                                             firstValue,
                                             secondValue,
                                             firstName,
                                             secondName,
                                             onChange,
                                             selectedDate
                                         }) => {
    const firstClickHandler = (value: string) => {
        onChange(firstName, value)
        onChange(secondName, value)
    }

    const secondClickHandler = (value: string) => {
        onChange(secondName, value)
        onClose()
    }

    return (
        <Wrapper isOpen={isOpen}>
            <Header>
                <HeaderTitle>From</HeaderTitle>
                <HeaderTitle>To</HeaderTitle>
            </Header>
            <Container>
                {getTimeIntervals(new Date(selectedDate)).map((item, i) => (
                    <Line key={i}>
                        <TimeItem
                            value={item}
                            isActive={item === firstValue}
                            isNoActive={false}
                            onClick={firstClickHandler}
                        />
                        <TimeItem
                            value={item}
                            isActive={item === secondValue}
                            isNoActive={!firstValue || item < firstValue}
                            onClick={secondClickHandler}
                        />
                    </Line>
                ))}
            </Container>
        </Wrapper>
    )
}

export default TimePicker
