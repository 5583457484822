import React, { FC, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../hooks/store'
import { IProductsSection } from '../../../../Types/IProduct'
import { linkList } from '../../Constants/linkList'
import { useLocation, useNavigate } from 'react-router'
import {
    MenuBar,
    MenuBurgerMobile,
    MenuButton,
    MenuButtonIcon, MenuButtonIndicator, MenuInfoMobile,
    MenuLink,
    MenuLinks, MenuMobile,
    Wrapper
} from '../../HeaderStyled'
import { Link } from 'react-router-dom'
import { Logo } from '../../../../UI/Common/common'
import { iconCart, iconProfile } from '../../../../UI/Icons/icons'
import { ICardPersistState } from '../../../../store/reducers/CartSlice/CartSlice'

interface HeaderProps {
    onProfileClick: () => void
}

const Header: FC<HeaderProps> = ({ onProfileClick }) => {

    const { products } = useAppSelector((state) => state.persistCardReducer as ICardPersistState)
    const productsSections = useAppSelector((state) => state.productReducer.productsSections as IProductsSection[])
    const productsLinks = productsSections.map(section => ({
        title: section.title,
        path: `sections/${section.id}`
    }))
    const menuLinks = [...productsLinks, ...linkList]


    const navigate = useNavigate()
    const location = useLocation()

    const [menuIsOpen, setMenuIsOpen] = useState(false)

    useEffect(() => {
        setMenuIsOpen(false)
        document.body.style.overflow = 'unset'
    }, [location])

    const openMenuClickHandler = () => {
        if (menuIsOpen) {
            document.body.style.overflow = 'unset'
            setMenuIsOpen(false)
            return
        }
        document.body.style.overflow = 'hidden'
        setMenuIsOpen(true)
    }

    const cardClickHandler = () => {
        navigate('/cart')
    }

    return (
        <Wrapper>
            <MenuBurgerMobile onClick={openMenuClickHandler} isOpen={menuIsOpen} />
            <Link to='/'>
                <Logo />
            </Link>
            <MenuBar>
                <MenuButton onClick={cardClickHandler}>
                    <MenuButtonIcon src={iconCart} />
                    {!!products.length && <MenuButtonIndicator>{products.length}</MenuButtonIndicator>}
                </MenuButton>
                <MenuButton onClick={onProfileClick}>
                    <MenuButtonIcon src={iconProfile} />
                </MenuButton>
            </MenuBar>
            <MenuMobile isOpen={menuIsOpen}>

                <MenuLinks>
                    {menuLinks.map((link, i) => (
                        <MenuLink
                            to={link.path}
                            className={({ isActive }) => isActive ? 'active' : 'noActive'}
                            key={i}
                        >{link.title}</MenuLink>
                    ))}
                </MenuLinks>
                <MenuInfoMobile>
                    <Link to='/privacy_policy'>Privacy Policy</Link>
                    <Link to='/terms'>Terms & Conditions</Link>
                    <p>2022. All rights reserved </p>
                </MenuInfoMobile>
            </MenuMobile>
        </Wrapper>
    )
}

export default Header