import { FC } from 'react'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 15px);
  grid-template-rows: 1fr;
  grid-gap: 0 25px;
  user-select: none;
`

export const Button = styled.p`
  cursor: pointer;
  font-size: 21px;
`

export const QuantityValue = styled.p`
  font-size: 21px;
`

export const Message = styled.p`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 300px;
  font-size: 12px;
  opacity: 0.8;
`

interface QuantityProps {
    quantity: string
    onSetQuantity: (num: string) => void
    minimalQuantity: number
}

const Quantity: FC<QuantityProps> = ({ quantity, onSetQuantity, minimalQuantity }) => {

    const increaseQuantity = () => {
        const quantityNum = Number(quantity)
        onSetQuantity(String(quantityNum + 1))
    }

    const reduceQuantity = () => {
        if (Number(quantity) <= minimalQuantity) return
        const quantityNum = Number(quantity)
        onSetQuantity(String(quantityNum - 1))
    }

    return (
        <Wrapper>
            <Button onClick={reduceQuantity}>-</Button>
            <QuantityValue>{quantity}</QuantityValue>
            <Button onClick={increaseQuantity}>+</Button>
            {minimalQuantity > 1 && <Message>Minimum order quantity {minimalQuantity}</Message>}
        </Wrapper>
    )
}

export default Quantity