import React, { FC, useState } from 'react'
import { ICollection } from '../../../../Types/IProduct'
import styled, { css } from 'styled-components'
import { defaultIngredients } from '../../Constants/defaultIngredients'
import { Container, Item, Title, Wrapper } from './CollectionStyled'


interface CollectionProps {
    collection: ICollection[]
    onSetIngredients: (value: string) => void
    activeFilling: number
    changeActiveFilling: (num: number, filing: string) => void
}

const Collection: FC<CollectionProps> = ({
                                             collection,
                                             onSetIngredients,
                                             activeFilling,
                                             changeActiveFilling
                                         }) => {

    const [activeNum, setActiveNum] = useState<number | null>(null)

    const clickHandler = (num: number, ingredients: string) => {
        if (num === activeNum) {
            setActiveNum(null)
            onSetIngredients(defaultIngredients)
            return
        }
        setActiveNum(num)
        onSetIngredients(ingredients)
    }

    if (!collection.length) return null

    return (
        <Wrapper>
            <Title>The collection will include the following flavours:</Title>
            <Container>
                {collection.map((item, i) => (
                    <Item
                        isActive={i === activeNum}
                        key={i}
                        onClick={() => clickHandler(i, item.ingredients)}
                    >
                        {item.title}
                    </Item>
                ))}
            </Container>

        </Wrapper>
    )
}

export default Collection