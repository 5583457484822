import { FC, useState } from 'react'
import styled, { css } from 'styled-components'
import { IImage } from '../../../../Types/IImage'
import { getImageUrl } from '../../../../utils/getImageUrl'
import { ButtonCross } from '../../../../UI/Buttons/ButtonsIcon'
import { ButtonRemove, Wrapper, Image } from './OrderImageStyled'

interface OrderImageProps {
    image: IImage
    onRemove?: ((image: IImage) => void) | null
    isRemoved?: boolean
}

const OrderImage: FC<OrderImageProps> = ({
    image,
    onRemove = null,
    isRemoved = true
}) => {
    const [removeIsVisible, setRemoveIsVisible] = useState(false)

    const removeHandler = () => {
        if (!onRemove || !isRemoved) return
        onRemove(image)
    }

    return (
        <Wrapper
            onMouseEnter={() => setRemoveIsVisible(true)}
            onMouseLeave={() => setRemoveIsVisible(false)}
            isRemoved={isRemoved}
        >
            <Image src={getImageUrl(image)} />
            {isRemoved && (
                <ButtonRemove
                    isVisible={removeIsVisible}
                    onClick={removeHandler}
                />
            )}
        </Wrapper>
    )
}

export default OrderImage
