import { FC } from 'react'
import { ITransaction } from '../../../../Types/ITransactions'
import { getStringDate } from '../../../../Helpers/getStrungDate'
import { getProductsPrice } from '../../../../Helpers/getProductsPrice'
import { firstToUpper } from '../../../../utils/firstToUpper'
import {
    Bar, Button,
    FinalLine,
    Info,
    Line,
    Products,
    Table,
    TextCenter,
    TextDark,
    Wrapper,
    Text, TextContainer
} from './TransactionCardStyled'
import { useNavigate } from 'react-router'
import { getProductSum } from '../../../../utils/getProductSum'
import { IPaymentMethod } from '../../../../Types/IPaymentMethods'


const sliceId = (id: string): string => {
    return id.slice(id.length - 6, id.length)
}

const getPrice = (quantity: string, cost: string) => {
    return String((Number(quantity) * Number(cost)).toFixed(2))
}

const getTransactionMethod = (method: IPaymentMethod): string => {
    switch (method) {
        case 'bankTransfer':
            return 'Bank Transfer'
        case 'gocardless':
            return 'Go Cardless'
        default:
            return ''
    }
}

interface TransactionCardProps {
    transaction: ITransaction
}

const TransactionCard: FC<TransactionCardProps> = ({ transaction }) => {
    const navigate = useNavigate()

    const finalPrice = getProductsPrice(transaction.products)

    const showOrderHandler = () => {
        navigate('/account/order', {
            state: { orderId: transaction.orderId }
        })
    }

    const payHandler = () => {
        window.location.replace(transaction.flowLink)
    }

    return (
        <Wrapper>
            <Info>
                <Text>Transaction ID: {sliceId(transaction.id)}</Text>
                <Text>{getTransactionMethod(transaction.method)}</Text>
                <Text isExcel={transaction.status === 'pending'}>Status: {firstToUpper(transaction.status)}</Text>
                <Text>Creation Date: {getStringDate(transaction.createdAt)}</Text>
            </Info>
            <Products>
                <Table>
                    {transaction.products.map(product => (
                        <Line key={product.id}>
                            <Text>{product.name}</Text>
                            <TextCenter>{product.quantity}</TextCenter>
                            <TextContainer>
                                <Text
                                    crossed={!!Number(product.sale)}>£ {getPrice(product.quantity, product.cost)}</Text>
                                {Number(product.sale) && (
                                    <Text>£ {getProductSum(product)}</Text>
                                )}
                            </TextContainer>

                        </Line>
                    ))}
                </Table>
                <FinalLine>
                    <TextDark>Final</TextDark>
                    <Text></Text>
                    <TextDark>{finalPrice}</TextDark>
                </FinalLine>
            </Products>
            <Bar>
                {(transaction.status === 'pending' && transaction.flowLink) &&
                    <Button onClick={payHandler}>Pay: {finalPrice}</Button>}
                <Button onClick={showOrderHandler}>Show Order</Button>
            </Bar>
        </Wrapper>
    )
}

export default TransactionCard