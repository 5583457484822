import { ChangeEvent, FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import DropDown, {
    IDropDownOption
} from '../../../../components/DropDown/DropDown'
import FieldText from '../../../../components/Fields/FieldText/FieldText'
import { useFormik, useFormikContext } from 'formik'
import FieldTextArea from '../../../../components/Fields/FieldTextArea/FieldTextArea'
import FieldOrderDate from '../../../../components/Fields/FieldOrderDate/FieldOrderDate'
import FieldTime from '../../../../components/Fields/FieldTime/FieldTime'
import FieldAddress, {
    IFieldAddressData
} from '../../../../components/Fields/FieldAddress/FieldAddress'
import FieldNumber from '../../../../components/Fields/FieldNumber/FieldNumber'
import { useDeliveryPrice } from '../../Hooks/useDeliveryPrice'
import { ICartInitialValues } from '../../Components/CartFormik/CartFormik'
import { ModuleTitle, Price, Title, Wrapper } from '../ModuleStyled'
import DeliveryMethod from './Components/DeliveryMethod/DeliveryMethod'
import {
    Container,
    DropDownContainer,
    Info,
    InfoText,
    Line
} from './CartDeliveryStyled'
import UberMethod from './Components/UberMethod/UberMethod'
import RoyalMailMethod from './Components/RoiyalMailMethod/RoyalMailMethod'
import OrderCollectionMethod from './Components/OrderCollectionMethod/OrderCollectionMethod'

import { useAppSelector } from '../../../../hooks/store'


interface CartDeliveryProps {
    deliveryMethods: IDropDownOption[]
    onOpenAddAddressForm: () => void
}

const CartDelivery: FC<CartDeliveryProps> = ({ deliveryMethods, onOpenAddAddressForm }) => {
    const { mobile } = useAppSelector((state) => state.appConditionReducer)

    const { values, setFieldValue, touched, errors, handleChange } =
        useFormikContext<ICartInitialValues>()
    const { getDeliveryPrice, addCongestionPrice } = useDeliveryPrice()

    const changeMethodHandler = (option: IDropDownOption) => {
        setFieldValue('delivery.method', option.value)
    }

    const clearMethodHandler = () => {
        setFieldValue('delivery.method', '')
    }


    useEffect(() => {
        if (values.delivery.method !== 'delivery' || !values.deliveryPlace.address) {
            setFieldValue('delivery.cost', '')
            return
        }
        const { delivery, deliveryPlace } = values
        if (!deliveryPlace.address || !delivery.date) return
        let deliveryPrice = getDeliveryPrice(deliveryPlace.deliveryDistance)
        if (deliveryPlace.isPaidParking) {
            deliveryPrice = addCongestionPrice(
                deliveryPrice,
                new Date(delivery.date),
                delivery.time,
                delivery.secondTime
            )
        }

        setFieldValue('delivery.cost', deliveryPrice)
    }, [
        values.delivery.time,
        values.delivery.secondTime,
        values.delivery.date,
        values.delivery.method,
        values.deliveryPlace,
        values.delivery.method
    ])

    if (!mobile) {
        return (
            <Wrapper>
                <ModuleTitle>Delivery Information</ModuleTitle>
                <Container>
                    <Line>
                        <DropDownContainer>
                            <Title>Delivery method</Title>
                            <DropDown
                                title='Delivery method'
                                options={deliveryMethods}
                                onChange={changeMethodHandler}
                                onClear={clearMethodHandler}
                                isTouched={touched.delivery?.method}
                                error={errors.delivery?.method}
                            />
                        </DropDownContainer>
                        <Line>
                            <FieldOrderDate
                                name='delivery.date'
                                value={values.delivery.date}
                                placeholder='Delivery Date'
                                onChange={setFieldValue}
                                isTouched={touched.delivery?.date}
                                error={errors.delivery?.date}
                            />
                            <FieldTime
                                selectedDate={values.delivery.date}
                                firstName='delivery.time'
                                secondName='delivery.secondTime'
                                firstValue={values.delivery.time}
                                secondValue={values.delivery.secondTime}
                                placeholder='Delivery Time'
                                onChange={setFieldValue}
                                isTouched={touched.delivery?.time}
                                error={errors.delivery?.time}
                            />
                        </Line>
                    </Line>
                    {values.delivery.method === 'delivery' ? (
                        <DeliveryMethod
                            onOpenForm={onOpenAddAddressForm}
                        />
                    ) : values.delivery.method === 'uberTaxi' ? (
                        <UberMethod />
                    ) : values.delivery.method === 'royalMail' ? (
                        <RoyalMailMethod
                            onOpenForm={onOpenAddAddressForm}
                        />
                    ) : values.delivery.method === 'orderCollection' ? (
                        <OrderCollectionMethod />
                    ) : null}
                    {values.delivery.method === 'delivery' &&
                        (!!values.delivery.cost ||
                            values.deliveryPlace.isPaidParking) && (
                            <Info>
                                <InfoText href='https://tfl.gov.uk/modes/driving/congestion-charge' target='_blank'>
                                    {values.deliveryPlace.isPaidParking
                                        ? 'Delivery address is in congestion charge zone'
                                        : ''}
                                </InfoText>
                                <Price>
                                    {values.delivery.cost ? `Delivery: £ ${values.delivery.cost}` : ''}
                                </Price>
                            </Info>
                        )}
                    <FieldTextArea
                        name='message'
                        value={values.message}
                        placeholder='Message'
                        onChange={handleChange}
                        isTouched={touched.message}
                        error={errors.message}
                    />
                </Container>
            </Wrapper>
        )
    } else {
        return (
            <Wrapper>
                <ModuleTitle>Delivery Information</ModuleTitle>
                <DropDownContainer>
                    <Title>Delivery method</Title>
                    <DropDown
                        title='Delivery method'
                        options={deliveryMethods}
                        onChange={changeMethodHandler}
                        onClear={clearMethodHandler}
                        isTouched={touched.delivery?.method}
                        error={errors.delivery?.method}
                    />
                </DropDownContainer>
                <FieldOrderDate
                    name='delivery.date'
                    value={values.delivery.date}
                    placeholder='Delivery Date'
                    onChange={setFieldValue}
                    isTouched={touched.delivery?.date}
                    error={errors.delivery?.date}
                />
                <FieldTime
                    selectedDate={values.delivery.date}
                    firstName='delivery.time'
                    secondName='delivery.secondTime'
                    firstValue={values.delivery.time}
                    secondValue={values.delivery.secondTime}
                    placeholder='Delivery Time'
                    onChange={setFieldValue}
                    isTouched={touched.delivery?.time}
                    error={errors.delivery?.time}
                />
                {values.delivery.method === 'delivery' ? (
                    <DeliveryMethod
                        onOpenForm={onOpenAddAddressForm}
                    />
                ) : values.delivery.method === 'uberTaxi' ? (
                    <UberMethod />
                ) : values.delivery.method === 'royalMail' ? (
                    <RoyalMailMethod
                        onOpenForm={onOpenAddAddressForm}
                    />
                ) : values.delivery.method === 'orderCollection' ? (
                    <OrderCollectionMethod />
                ) : null}
                {values.delivery.method === 'delivery' &&
                    (!!values.delivery.cost ||
                        values.deliveryPlace.isPaidParking) && (
                        <Info>
                            {values.deliveryPlace.isPaidParking && (
                                <InfoText href='https://tfl.gov.uk/modes/driving/congestion-charge' target='_blank'>Delivery
                                    address in congestion charge zone</InfoText>
                            )}
                            <Price>{values.delivery.cost ? `Delivery: £ ${values.delivery.cost}` : ''}</Price>
                        </Info>
                    )}
                <FieldTextArea
                    name='message'
                    value={values.message}
                    placeholder='Message'
                    onChange={handleChange}
                    isTouched={touched.message}
                    error={errors.message}
                />
            </Wrapper>
        )
    }
}

export default CartDelivery
