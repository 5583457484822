import { FC } from 'react'
import { useParams } from 'react-router'

const Payment: FC = () => {
    const params = useParams()

    return (
        <div>dwq</div>
    )
}

export default Payment