import { FC, useState } from 'react'
import { IVariant } from '../../../../Types/IProduct'
import { getImageUrl } from '../../../../utils/getImageUrl'
import { IOption } from '../../Types/Types'
import { Container, Title, Variant, VariantImage, VariantTitle, Wrapper } from './VariantStyled'


interface VariantsProps {
    variants: IVariant[]
    addOption: (option: IOption) => void
    setOption: (option: IOption) => void
    removeOption: (option: IOption) => void
}

const Variants: FC<VariantsProps> = ({
                                         variants,
                                         addOption,
                                         setOption,
                                         removeOption
                                     }) => {
    const [activeNum, setActiveNum] = useState<number | null>(null)

    const clickHandler = (filingTitle: string, num: number) => {
        if (num === activeNum) {
            setActiveNum(null)
            removeOption({ title: 'variants', value: filingTitle })
            return
        }
        setActiveNum(num)
        if (activeNum === null) {
            addOption({ title: 'variants', value: filingTitle })
        } else {
            setOption({ title: 'variants', value: filingTitle })
        }
    }


    if (!variants.length) return null

    return (
        <Wrapper>
            <Title>Options</Title>
            <Container>
                {variants.map((variant, i) => (
                    <Variant
                        isActive={i === activeNum}
                        key={i}
                        onClick={() => clickHandler(variant.title, i)}
                    >
                        <VariantImage src={getImageUrl(variant.photo[0])}></VariantImage>
                        <VariantTitle>{variant.title}</VariantTitle>
                    </Variant>
                ))}
            </Container>
        </Wrapper>
    )
}

export default Variants