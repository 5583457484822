import styled, { css } from 'styled-components'
import { MainText } from '../../../../UI/Common/common'
import { ButtonMain, ButtonMainClear } from '../../../../UI/Buttons/ButtonMain'

export const Wrapper = styled.div`
  margin: 0 0 60px 0;
  display: grid;
  grid-template-columns: 30% calc(70% - 50px);
  grid-template-rows: min-content;
  grid-gap: 0 50px;

  @media (${({ theme }) => theme.media.mobile}) {
    grid-template-columns: 40% calc(60% - 10px);
    grid-template-rows: 1fr min-content;
    grid-template-areas:
            'a b'
            'c c';
    grid-gap: 0 10px;
  }
`

export const ImageContainer = styled.div`
  align-self: start;
  position: relative;
  width: 100%;
  padding: 133% 0 0 0;
  cursor: pointer;

  @media (${({ theme }) => theme.media.mobile}) {
    grid-area: a;
  }
`

export const Image = styled.img.attrs({
    alt: 'Product'
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Container = styled.div`
  padding: 10px 0 30px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (${({ theme }) => theme.media.mobile}) {
    grid-area: b;
    padding: 5px 0 0 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content 1fr min-content;
    grid-gap: 5px 0;
    overflow: hidden;
  }
`

export const Title = styled(MainText)`
  margin: 0;
  font-size: 38px;
  line-height: 38px;
  @media (${({ theme }) => theme.media.mobile}) {
    font-size: 21px;
    line-height: 22px;
    font-weight: 400;
  }
`

export const Price = styled(MainText)`
  margin: 15px 0 0 0;
  font-size: 21px;
  line-height: 21px;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 5px 0 0 0;
    font-size: 18px;
    line-height: 16px;
  }
`

export const Description = styled(MainText)`
  margin: 20px 0 0 0;
  white-space: pre-wrap;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 10px 0 0 0;
    overflow: hidden;
    font-size: 12px;
    line-height: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    -moz-line-clamp: 6;
    -ms-line-clamp: 6;
    line-clamp: 6;
  }
`

export const Bar = styled.div`
  margin: 40px 0 0 0;
  display: grid;
  grid-template-columns: min-content min-content;
  grid-template-rows: 1fr;
  grid-gap: 0 20px;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 20px 0 0 0;
    grid-area: c;
    grid-template-columns: 1fr 1fr;
  }

`

export const ButtonCart = styled(ButtonMain)`
  @media (${({ theme }) => theme.media.mobile}) {
    width: 100%;
    height: 60px;
  }
`

export const ButtonMore = styled(ButtonMainClear)`
  @media (${({ theme }) => theme.media.mobile}) {
    width: 100%;
    height: 60px;
  }
`