import { FC } from 'react'
import { useFormik } from 'formik'
import { Button, Fields, Form } from './FormProfileStyled'
import FieldText from '../../../../../components/Fields/FieldText/FieldText'
import { useAppDispatch } from '../../../../../hooks/store'
import getObjectDifference from '../../../../../utils/getObjectDifference'
import { changeProfile } from '../../../../../store/reducers/User/ActionCreators'
import FieldDate from '../../../../../components/Fields/FieldDate/FieldDate'
import styled from 'styled-components'
import { IUser } from '../../../../../Types/IUser'

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
`

interface FormProfileProps {
    profile: IUser

}

const FormProfile: FC<FormProfileProps> = ({ profile }) => {
    const dispatch = useAppDispatch()

    const initialValues = {
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        phone: profile.phone,
        birthDate: profile.birthDate ? new Date(profile.birthDate) : null
    }

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { resetForm }) => {
            const difference = getObjectDifference(initialValues, values)
            if (difference) {
                const result = await dispatch(changeProfile({ clientId: profile.id, ...difference }))
                if (result) {
                    resetForm({ values })
                    return
                }
            }
        }
    })

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Title>Personal information</Title>
            <Fields>
                <FieldText
                    name='firstName'
                    value={formik.values.firstName}
                    placeholder='First Name'
                    onChange={formik.handleChange}
                    isTouched={formik.touched.firstName}
                    error={formik.errors.firstName}
                />
                <FieldText
                    name='lastName'
                    value={formik.values.lastName}
                    placeholder='Last Name'
                    onChange={formik.handleChange}
                    isTouched={formik.touched.lastName}
                    error={formik.errors.lastName}
                />
                <FieldText
                    name='email'
                    value={formik.values.email}
                    placeholder='Email'
                    onChange={formik.handleChange}
                    isTouched={formik.touched.email}
                    error={formik.errors.email}
                    type='email'
                />
                <FieldText
                    name='phone'
                    value={formik.values.phone}
                    placeholder='Phone'
                    onChange={formik.handleChange}
                    isTouched={formik.touched.phone}
                    error={formik.errors.phone}
                    type='phone'
                />
                <FieldDate
                    name='birthDate'
                    value={formik.values.birthDate}
                    placeholder='B-day Date'
                    onChange={formik.setFieldValue}
                    isTouched={formik.touched.birthDate}
                    error={formik.errors.birthDate}
                />
            </Fields>
            <Button type='submit' noActive={!(formik.isValid && formik.dirty)}>Save</Button>
        </Form>
    )
}

export default FormProfile