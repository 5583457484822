import { FC, useEffect, useMemo, useState, memo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { FormikErrors, useFormikContext } from 'formik'
import { ICartInitialValues } from '../CartFormik/CartFormik'
import CartProducts from '../../Modules/CartProducts/CartProducts'
import { Button, CartTitle, Wrapper } from './CartMainStyled'
import CartPersonal from '../../Modules/CartPersonal/CartPersonal'
import CartDelivery from '../../Modules/CartDelivery/CartDelivery'
import { Price } from '../../Modules/ModuleStyled'
import FormAddAddress from '../FormAddAddress/FormAddAddress'
import { IAddressData } from '../../../../Types/IUser'
import { IProductsPersistState } from '../../../../store/reducers/ProductsSlice/ProductsSlice'
import {
    IDeliveryMethod,
    IProduct,
    IProductsSection
} from '../../../../Types/IProduct'
import { ICardPersistState } from '../../../../store/reducers/CartSlice/CartSlice'
import { IOrderProduct } from '../../../../Types/IOrder'
import { getProductsPrice, requestPriceValue } from '../../../../Helpers/getProductsPrice'
import { showMessage } from '../../../../store/reducers/AppCondition/AppConditionSlice'

const CartMain: FC = () => {

    const dispatch = useAppDispatch()
    const [isSending, setIsSending] = useState<boolean>(false)
    const { user } = useAppSelector((state) => state.userReducer)
    const { products } = useAppSelector(
        (state) => state.persistCardReducer as ICardPersistState
    )

    const formik = useFormikContext<ICartInitialValues>()

    const allProducts = useAppSelector((state) => {
        const productsState = (state.productReducer as IProductsPersistState)
        const sections: IProductsSection[] = [...productsState.productsSections, ...productsState.treadsSections]
        let result: IProduct[] = []
        sections.forEach((section) => {
            result = [...result, ...section.products]
        })
        return result
    })

    const finalPrice = useMemo(() => {
        return getProductsPrice(products, formik.values.delivery.cost)
    }, [products, formik.values.delivery.cost])

    const deliveryMethods = useMemo(() => {
        return products.reduce(
            (result: IDeliveryMethod[], product: IOrderProduct, i: number) => {
                const deliveryMethods = allProducts.find(
                    ({ id }) => id === product.productId
                )?.delivery
                if (!deliveryMethods) return result
                if (i === 0) {
                    return [...result, ...deliveryMethods]
                }
                result.forEach((method) => {
                    if (
                        deliveryMethods.find(
                            ({ value }) => value === method.value
                        )
                    ) {
                        return
                    } else {
                        result = result.filter(
                            ({ value }) => value !== method.value
                        )
                    }
                })
                return result
            },
            []
        )
    }, [allProducts, products])

    const [addAddressIsVisible, setAddAddressIsVisible] =
        useState<boolean>(false)


    const addAddressHandler = (address: IAddressData) => {
        formik.setFieldValue('address', {
            address: address.address,
            postCode: address.postCode,
            floor: address.floor,
            flat: address.flat,
            isCongestionZone: address.isCongestionZone,
            deliveryDistance: address.deliveryDistance.distance
        })
    }

    const createOrderHandler = async () => {
        if (!formik.isValid) {
            dispatch(showMessage('Fill out of the fields'))
        }
        if (isSending) return
        setIsSending(true)
        await formik.submitForm()
        setIsSending(false)
    }

    return (
        <>
            {user && (
                <FormAddAddress
                    clientId={user.id}
                    isVisible={addAddressIsVisible}
                    onClose={() => setAddAddressIsVisible(false)}
                    onAddAddress={addAddressHandler}
                />
            )}
            <Wrapper onSubmit={formik.handleSubmit}>
                <CartTitle>Cart</CartTitle>
                <CartProducts />
                {!user && <CartPersonal />}
                <CartDelivery
                    onOpenAddAddressForm={() => setAddAddressIsVisible(true)}
                    deliveryMethods={deliveryMethods}
                />
                {finalPrice === requestPriceValue ? <Price>{finalPrice}</Price> : <Price>Final: {finalPrice}</Price>}
                <Button type='button' onClick={createOrderHandler}>
                    {isSending ? 'Order created...' : finalPrice === requestPriceValue ? 'Create Order' : 'Pay'}
                </Button>
            </Wrapper>
        </>
    )
}

export default CartMain
