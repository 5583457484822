import React, { FC } from 'react'
import styled from 'styled-components'

import Promo from './Modules/Promo/Promo'
import Showcase from './Modules/Showcase/Showcase'
import About from './Modules/About/About'
import Delivery from './Modules/Delivery/Delivery'
import Feedback from './Modules/Feedback/Feedback'
import Gift from './Modules/Gift/Gift'
import FAQ from './Modules/FAQ/FAQ'
import Treats from './Modules/Treats/Treats'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 190px 0;

  @media (${({ theme }) => theme.media.mobile}) {
    grid-gap: 60px 0;
  }
`

const HomePage: FC = () => {
    return (
        <Wrapper>
            <Promo />
            <Showcase />
            <Treats />
            <About />
            <Delivery />
            <Feedback />
            <Gift />
            <FAQ />
        </Wrapper>
    )
}

export default HomePage
