import { ButtonMain } from '../../../../../UI/Buttons/ButtonMain'
import styled from 'styled-components'
import { MainTextCss } from '../../../../../UI/Text/Text'

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 30px 0;
`

export const Title = styled.p`
  ${MainTextCss};
`

export const Fields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  grid-gap: 20px 20px;

  @media (${({ theme }) => theme.media.mobile}) {
    grid-template-columns: 1fr;
  }
`

export const Button = styled(ButtonMain)`
  justify-self: end;
  width: 180px;
  height: 40px;
  border-radius: 4px;
  
`
