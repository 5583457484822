import styled from 'styled-components'

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 50px 0;
`

export const Bar = styled.div`
    padding: 40px 0 0 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 10px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.graphics};
`

export const BarButton = styled.p`
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
`
