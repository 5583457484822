import { FC } from 'react'
import { useNavigate } from 'react-router'
import { getStringDate } from '../../../../Helpers/getStrungDate'
import { useAppSelector } from '../../../../hooks/store'
import {
    Button,
    Container,
    Info,
    Title,
    Value,
    Wrapper
} from './OrderCardsStyled'
import { IOrder, IOrderProduct, IOrderStatus } from '../../../../Types/IOrder'

const getStatus = (status: IOrderStatus): string => {
    switch (status) {
        case 'created':
            return 'Created'
        case 'current':
            return 'Current'
        case 'delivery':
            return 'Delivery'
        case 'complete':
            return 'Complete'
        default:
            return ''
    }
}

const sortProducts = (products: IOrderProduct[]) => {
    return [...products].sort((product) => product.name === 'Delivery' ? 1 : -1)

}

interface OrderCardProps {
    order: IOrder
}

const OrderCard: FC<OrderCardProps> = ({ order }) => {
    const navigate = useNavigate()
    const { mobile } = useAppSelector((state) => state.appConditionReducer)
    const transactions = useAppSelector((state) => state.transactionsReducer.transactions.filter(({ orderId }) => orderId === order.id))

    const toOrderClickHandler = () => {
        navigate('/account/order', {
            state: { orderId: order.id }
        })
    }

    return (
        <Wrapper>
            <Container>
                <Info>
                    <Title>Creation Date:</Title>
                    <Value>{getStringDate(order.createdAt)}</Value>
                </Info>
                <Info>
                    <Title>State: </Title>
                    <Value>{getStatus(order.status)}</Value>
                </Info>
            </Container>
            {mobile ? (
                <Container>
                    <Title>Products:</Title>
                    {sortProducts(order.products).map((product) => (
                        <Value key={product.id}>{product.name}</Value>
                    ))}
                </Container>
            ) : (
                <Container>
                    {sortProducts(order.products).map((product) => (
                        <Title key={product.id}>{product.name}</Title>
                    ))}
                </Container>
            )}

            <Button type='button' onClick={toOrderClickHandler}>
                Show
            </Button>
        </Wrapper>
    )
}

export default OrderCard
