import styled, { css } from 'styled-components'
import { MainText } from '../../../../UI/Common/common'

export const Wrapper = styled.div`
    padding: 10px 0;
    position: relative;
    width: 100%;
    cursor: pointer;
`

interface HeaderProps {
    isOpen: boolean
}

export const Header = styled.div<HeaderProps>`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ isOpen }) =>
        isOpen &&
        css`
            z-index: 3;
        `}
`

export const Title = styled(MainText)`
    margin: 0;
    position: relative;
    font-size: 21px;
`

interface ContainerProps {
    isOpen: boolean
}

export const Container = styled.div<ContainerProps>`
    content: '';
    position: absolute;
    top: 0;
    left: -15px;
    padding: 50px 25px 20px 13px;
    width: calc(100% + 30px);
    border: 1px solid ${({ theme }) => theme.colors.graphics};
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    z-index: 2;

    ${({ isOpen }) =>
        isOpen &&
        css`
            visibility: visible;
            opacity: 1;
        `}
`

export const Content = styled(MainText)`
    margin: 0 0 5px 0;
    font-size: 16px;
    line-height: 20px;
`
