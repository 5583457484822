import { FC } from 'react'
import image1 from '../../../../images/loader/1.svg'
import image2 from '../../../../images/loader/2.svg'
import image3 from '../../../../images/loader/3.svg'
import image4 from '../../../../images/loader/4.svg'
import { Image1, Image2, Image3, Image4, Wrapper } from './AnimateImageStyled'

const AnimateImage: FC = () => {
    return (
        <Wrapper>
            <Image1 src={image1} />
            <Image2 src={image2} />
            <Image3 src={image3} />
            <Image4 src={image4} />
        </Wrapper>
    )
}

export default AnimateImage
