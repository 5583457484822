import styled from 'styled-components'

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 40px 0;

    @media (${({ theme }) => theme.media.mobile}) {
        grid-gap: 20px 0;
    }
`

export const DropDownContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 10px 0;
`

export const Line = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    grid-gap: 0 40px;
`

export const Info = styled.div`
    margin: 10px 0 0 0;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-auto-rows: 1fr;
    grid-gap: 0 40px;
    align-items: center;

    @media (${({ theme }) => theme.media.mobile}) {
        grid-template-columns: 1fr;
    }
`

export const InfoText = styled.a`
    font-size: 20px;
    font-weight: 400;
    text-decoration: underline;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.graphics};

    @media (${({ theme }) => theme.media.mobile}) {
        font-size: 16px;
    }
`
