import styled, { css } from 'styled-components'

interface ButtonMainProps {
    noActive?: boolean
}

export const ButtonCss = css`
  width: 140px;
  height: 50px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.graphics};
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
`

export const ButtonMainCss = css`
  ${ButtonCss};
  background-color: ${({ theme }) => theme.colors.graphics};
  color: ${({ theme }) => theme.colors.background};
`

export const ButtonMainClearCss = css`
  ${ButtonCss};
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.graphics};
`

export const ButtonMain = styled.button<ButtonMainProps>`
  ${ButtonMainCss};

  ${({ noActive }) =>
          noActive &&
          css`
            opacity: 0.6;
            cursor: initial;
          `};

  @media (${({ theme }) => theme.media.mobile}) {
    width: 135px;
    height: 45px;
    font-size: 17px;
    font-weight: 600;
  }
`

export const ButtonMainClear = styled.button<ButtonMainProps>`
  ${ButtonMainClearCss};

  ${({ noActive }) =>
          noActive &&
          css`
            opacity: 0.6;
            cursor: initial;
          `};
  
  @media (${({ theme }) => theme.media.mobile}) {
    width: 135px;
    height: 45px;
    font-size: 17px;
    font-weight: 600;
  }
`
