import { FC } from 'react'
import { IProduct } from '../../../../Types/IProduct'
import {
    Bar,
    ButtonCart,
    ButtonMore,
    Container,
    Description,
    Image,
    ImageContainer,
    Price,
    Title,
    Wrapper
} from './ProductCardStyled'
import { useNavigate } from 'react-router'
import { getImageUrl } from '../../../../utils/getImageUrl'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { iconBadImage } from '../../../../UI/Icons/icons'
import { getProductPrice } from '../../../../Helpers/getProductPrice'
import { addProduct } from '../../../../store/reducers/CartSlice/CartSlice'
import { v4 as uuidv4 } from 'uuid'
import { showMessage } from '../../../../store/reducers/AppCondition/AppConditionSlice'
import { IOrderProduct } from '../../../../Types/IOrder'

interface ProductCardProps {
    product: IProduct
    sectionName: string
    sectionId: string
}

const ProductCard: FC<ProductCardProps> = ({ product, sectionName, sectionId }) => {
    const dispatch = useAppDispatch()
    const { mobile } = useAppSelector((state) => state.appConditionReducer)
    const navigate = useNavigate()

    const goToProduct = () => {
        navigate(`/sections/${sectionId}/${product.id}`)
    }

    const addProductToCart = () => {
        const productData: IOrderProduct = {
            id: uuidv4(),
            status: 'created',
            sectionId: sectionId,
            sectionName: sectionName,
            name: product.title,
            quantity: String(product.minimalQuantity),
            cost: getProductPrice(
                product.price,
                product.price.variants[0] ? product.price.variants[0] : null
            ),
            productId: product.id,
            options: [],
            sale: ''
        }

        dispatch(
            addProduct(productData)
        )
        dispatch(showMessage(`${product.title} added to cart`))
    }

    const getDisplayedPrice = () => {
        const { type, value, intervals, currency, unit, variants } =
            product.price
        if (type === 'only') {
            return `${currency}${value} ${unit ? unit : ''}`
        }
        if (type === 'custom' || type === 'request') {
            return value
        }
        if (type === 'interval') {
            if (mobile) {
                return `From ${currency}${intervals[0].cost}`
            }
            return `From ${currency}${intervals[0].cost}  ${unit ? unit : ''}`
        }
        if (type === 'variants' && variants[0]) {
            if (mobile) {
                return `From ${currency}${variants[0].cost}`
            }
            return `From ${currency}${variants[0].cost}  ${variants[0].unit}`
        }
    }

    if (!mobile) {
        return (
            <Wrapper>
                <ImageContainer onClick={goToProduct}>
                    {product.photo[0] && (
                        <Image
                            src={getImageUrl(product.photo[0])}
                            onError={(e) =>
                                (e.currentTarget.src = iconBadImage)
                            }
                        />
                    )}
                </ImageContainer>
                <Container>
                    <Title>{product.title}</Title>
                    <Price>{getDisplayedPrice()}</Price>
                    <Description>{product.description}</Description>
                    <Bar>
                        <ButtonCart onClick={goToProduct}>
                            Learn More
                        </ButtonCart>
                    </Bar>
                </Container>
            </Wrapper>
        )
    } else {
        return (
            <Wrapper>
                <ImageContainer onClick={goToProduct}>
                    {product.photo[0] && (
                        <Image
                            src={getImageUrl(product.photo[0])}
                            onError={(e) =>
                                (e.currentTarget.src = iconBadImage)
                            }
                        />
                    )}
                </ImageContainer>
                <Container>
                    <Title>{product.title}</Title>
                    <Price>{getDisplayedPrice()}</Price>
                    <Description>{product.description}</Description>
                </Container>
                <Bar>
                    <ButtonCart onClick={goToProduct}>
                        Learn More
                    </ButtonCart>
                </Bar>

            </Wrapper>
        )
    }
}

export default ProductCard
