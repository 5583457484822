import { FC } from 'react'
import { Text } from '../../../../UI/Text/TextPage'
import { v4 as uuidv4 } from 'uuid'
import { TextLink } from '../Link/Link'

export interface ITextData {
    id: string,
    type: 'link' | 'text',
    isInternal?: boolean,
    text: string,
    path: string
}

const getTextData = (text: string): ITextData[] => {
    if (text.includes('#link$')) {
        return text.split('#').map(item => {
            if (item.startsWith('link$')) {
                const itemArr = item.split('$')
                return {
                    id: uuidv4(),
                    type: 'link',
                    isInternal: itemArr[1] === 'internal',
                    text: itemArr[3],
                    path: itemArr[2]
                }
            }
            return {
                id: uuidv4(),
                type: 'text',
                text: item,
                path: ''
            }
        })
    }
    return [{
        id: uuidv4(),
        type: 'text',
        text: text,
        path: ''
    }]
}

interface TextItemProps {
    textData: string
}

export const TextItem: FC<TextItemProps> = ({ textData }) => {

    return (
        <Text>
            {getTextData(textData).map((item) => item.type === 'text' ? item.text : (
                <TextLink data={item} key={item.id} />
            ))}
        </Text>
    )
}