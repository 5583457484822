import { Dispatch, FC, SetStateAction, useState } from 'react'
import Modal from '../../../../components/Modal/Modal'
import FormLogin from '../Forms/FormLogin'
import FormSignUp from '../Forms/FormSignUp'
import { TabKey } from '../../Layout'
import FormRecovery from '../Forms/FormRecovery'
import FormChangePassword from '../Forms/FormChangePassword'

interface SignInProps {
    openTab: TabKey
    setOpenTab: Dispatch<SetStateAction<TabKey>>
}

const SignIn: FC<SignInProps> = ({ openTab, setOpenTab }) => {
    return (
        <Modal
            isVisible={openTab !== 'close'}
            onClose={() => setOpenTab('close')}
        >
            <FormLogin openTab={openTab} setOpenTab={setOpenTab} />
            <FormSignUp openTab={openTab} setOpenTab={setOpenTab} />
            <FormRecovery openTab={openTab} setOpenTab={setOpenTab} />
            <FormChangePassword openTab={openTab} setOpenTab={setOpenTab} />
        </Modal>
    )
}

export default SignIn
