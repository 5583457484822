import styled from 'styled-components'
import { MainText, MainTitle } from '../../UI/Common/common'

export const Wrapper = styled.div`
  width: 100%;
`

export const Title = styled(MainTitle)`
  margin: 60px 0 30px 0;
  text-align: center;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 30px 0 60px 0;
  }
`

export const Description = styled.p`
  max-width: 600px;
  margin: 0 auto 40px auto;
  ${MainText};
  text-align: center;

  @media (${({ theme }) => theme.media.mobile}) {
    max-width: 100%;
    text-align: start;
  }
`
