import styled from 'styled-components'

export const Container = styled.div`
  padding: 50px 200px;
  max-width: ${({ theme }) => theme.size.pageWidth};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.graphics};
  border-radius: 4px;

  @media (${({ theme }) => theme.media.mobile}) {
    padding: 40px 20px;
    max-width: 100%;
  }
`

export const Title = styled.p`
  font-family: Theonory;
  font-size: 36px;
  font-weight: 400;
  text-align: center;

  @media (${({ theme }) => theme.media.mobile}) {
    font-size: 21px;
  }
`

export const Subtitle = styled.p`
  margin: 30px 0 0 0;
  font-size: 16px;
  font-weight: 400;
  text-align: center;

  @media (${({ theme }) => theme.media.mobile}) {
    font-size: 13px;
  }
`

export const Message = styled(Subtitle)`
  margin: 20px 0 0 0;
  font-size: 16px;
  font-weight: 400;

  @media (${({ theme }) => theme.media.mobile}) {
    font-size: 13px;
  }
`

export const Social = styled.div`
  margin: 30px 0 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0 50px;

  @media (${({ theme }) => theme.media.mobile}) {
    display: none;
  }
`

export const SocialContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr min-content;
  grid-gap: 20px 0;
  justify-items: center;
`

export const SocialTitle = styled.p`
  font-size: 29px;
  font-weight: 400;
`

export const SocialLinks = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  grid-gap: 0 30px;
`

export const SocialLink = styled.a`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0 20px;
  text-decoration: none;
`

export const SocialLinkIcon = styled.img`
  width: 25px;
  height: 25px;
  object-fit: contain;
`

export const SocialLinkTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.graphics};
`