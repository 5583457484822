import styled from 'styled-components'
import { MainTitle } from '../../../../UI/Common/common'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
`
export const Title = styled(MainTitle)`
  text-align: center;
  @media (${({ theme }) => theme.media.mobile}) {
    width: 100%;
    margin: 0 0 40px 0;
  }
`
