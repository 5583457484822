import { AppDispatch } from '../../store'
import { instance } from '../../../API/baseAPI'
import { IProductsSectionBD } from '../../../Types/IProduct'
import { setProductSections } from './ProductsSlice'
import { checkProducts } from '../CartSlice/CartSlice'
import { errorHandler } from '../../Helpers/errorHandler'

export const getProductsSections = (cartIsEmpty: boolean) => async (dispatch: AppDispatch) => {
    try {
        const response = await instance.get<IProductsSectionBD[]>('/products-sections/client')
        if (!cartIsEmpty) {
            dispatch(checkProducts(response.data))
        }
        dispatch(setProductSections(response.data))
    } catch (err: any) {
        dispatch(errorHandler(err))
    }
}