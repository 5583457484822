import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITransaction } from '../../../Types/ITransactions'

interface IInitialState {
    transactions: ITransaction[]
}

const initialState: IInitialState = {
    transactions: []
}

const transactionSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        setTransactions(state, action: PayloadAction<ITransaction[]>) {
            state.transactions = action.payload
        }
    }
})

export const { setTransactions } = transactionSlice.actions

export default transactionSlice.reducer