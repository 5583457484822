import { IDropDownOption } from '../components/DropDown/DropDown'

export const deliveryMethods: IDropDownOption[] = [
    {
        title: 'Delivery',
        value: 'delivery'
    },
    {
        title: 'Uber taxi',
        value: 'uberTaxi'
    },
    {
        title: 'Order collection',
        value: 'orderCollection'
    },
    {
        title: 'Royal Mail',
        value: 'royalMail'
    }
]