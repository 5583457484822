import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
    margin: 40px 0 0 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 10px 0;
`

export const Title = styled.div`
    font-size: 21px;
`

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 10px 0;
    justify-items: start;
`

interface FilingTitleProps {
    isActive: boolean
}

export const FilingTitle = styled.div<FilingTitleProps>`
    padding: 5px 7px;
    font-size: 16px;
    cursor: pointer;
    display: inline-block;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.colors.graphics};

    ${({ isActive }) =>
        isActive &&
        css`
            background-color: ${({ theme }) => theme.colors.graphics};
            color: ${({ theme }) => theme.colors.background};
        `}
`
