import { Dispatch, FC, SetStateAction } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { yupValidation } from '../../../../Constants/yupValidation'
import {
    ButtonSubmit,
    Fields,
    Form,
    FormLink,
    FormMessage,
    FormStringButton,
    FormTitle
} from '../../../../UI/Form/FormStyled'
import FieldText from '../../../../components/Fields/FieldText/FieldText'
import { useAppDispatch } from '../../../../hooks/store'
import {
    login,
    recoveryChange
} from '../../../../store/reducers/User/ActionCreators'
import { useNavigate } from 'react-router'
import { TabKey } from '../../Layout'

interface FormChangePasswordProps {
    openTab: TabKey
    setOpenTab: Dispatch<SetStateAction<TabKey>>
}

const FormChangePassword: FC<FormChangePasswordProps> = ({
    openTab,
    setOpenTab
}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { values, touched, errors, handleChange, handleSubmit, resetForm } =
        useFormik({
            initialValues: {
                newPassword: '',
                checkPassword: ''
            },
            validationSchema: Yup.object({
                newPassword: yupValidation.password,
                checkPassword: Yup.string()
                    .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
                    .required('Password confirm is required')
            }),
            onSubmit: async (values, { resetForm }) => {
                await dispatch(recoveryChange(values.newPassword))
                resetForm()
                navigate('/account')
                setOpenTab('close')
            }
        })

    if (openTab !== 'change') return null
    return (
        <Form onSubmit={handleSubmit}>
            <FormTitle>Change Password</FormTitle>
            <Fields>
                <FieldText
                    name="newPassword"
                    value={values.newPassword}
                    placeholder="New Password"
                    onChange={handleChange}
                    isTouched={touched.newPassword}
                    error={errors.newPassword}
                    type="password"
                />
                <FieldText
                    name="checkPassword"
                    value={values.checkPassword}
                    placeholder="New Password Confirm"
                    onChange={handleChange}
                    isTouched={touched.checkPassword}
                    error={errors.checkPassword}
                    type="password"
                />
            </Fields>
            <ButtonSubmit type="submit">Submit</ButtonSubmit>
            <FormMessage>
                Back to{' '}
                <FormLink onClick={() => setOpenTab('login')}>log in</FormLink>
            </FormMessage>
        </Form>
    )
}

export default FormChangePassword
