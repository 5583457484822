import styled, { css } from 'styled-components'
import { MainTextCss } from '../../UI/Text/Text'
import { ButtonMainCss } from '../../UI/Buttons/ButtonMain'

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 60px 0;

    @media (${({ theme }) => theme.media.mobile}) {
        grid-gap: 30px 0;
    }
`

export const Section = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 20px 0;
`

export const Title = styled.p`
    font-size: 21px;
    font-weight: 500;
`

export const Price = styled.p`
    font-size: 21px;
    font-weight: 500;
`

export const Table = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
`

interface TableLineProps {
    isBright?: boolean
}

export const TableLine = styled.div<TableLineProps>`
    padding: 30px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 110px 100px 100px;
    grid-template-rows: min-content;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graphicsOpacity};
    grid-gap: 0 10px;

    ${({ isBright }) =>
            isBright &&
            css`
                border-bottom: 1px solid ${({ theme }) => theme.colors.graphics};
            `};

    @media (${({ theme }) => theme.media.mobile}) {
        padding: 15px 0;
        grid-template-columns: 1fr 1fr 0.5fr 1fr 1fr;
    }
`

export const TableSubLine = styled.div`
    padding: 30px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

export const TextContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 10px 0;
`


export const TableContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    justify-items: center;
    grid-gap: 10px 0;
`

interface TableTextProps {
    crossed?: boolean
}

export const TableText = styled.div<TableTextProps>`
    ${MainTextCss};

    :nth-of-type(3) {
        text-align: center;
    }

    :nth-of-type(4) {
        text-align: center;
    }

    :nth-of-type(5) {
        text-align: end;
    }

    ${({ crossed }) => crossed && css`
        opacity: 0.5;
        text-decoration: line-through;
    `};

    @media (${({ theme }) => theme.media.mobile}) {
        font-size: 12px;
    }
`

export const Media = styled.div`
    min-height: 194px;
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0 30px;
    justify-content: space-between;
    overflow: hidden;

    @media (${({ theme }) => theme.media.mobile}) {
        grid-template-columns: 1fr;
        grid-template-rows: min-content 1fr;
        grid-gap: 20px 0;
    }
`

export const MediaImages = styled.div`
    padding: 15px;
    display: inline-grid;
    width: fit-content;
    max-width: 100%;
    grid-template-columns: repeat(auto-fit, 122px);
    grid-gap: 15px 15px;
    border: 1px solid ${({ theme }) => theme.colors.graphics};
    border-radius: 4px;
`

export const MediaImage = styled.img`
    width: 122px;
    height: 162px;
    border-radius: 4px;
    background: #e6d2aa;
`

export const MediaButton = styled.div`
    ${ButtonMainCss};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;

    @media (${({ theme }) => theme.media.mobile}) {
        width: 100%;
    }
`

export const Delivery = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
`

export const DeliveryLine = styled.div`
    padding: 30px 0;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 0 15px;

    border-bottom: 1px solid ${({ theme }) => theme.colors.graphicsOpacity};

    @media (${({ theme }) => theme.media.mobile}) {
        padding: 15px 0;
    }
`

export const DeliveryText = styled.div`
    ${MainTextCss};

    @media (${({ theme }) => theme.media.mobile}) {
        font-size: 12px;
    }
`

export const DeliveryValue = styled(DeliveryText)`
    text-align: end;
`
