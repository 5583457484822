import { ButtonMain } from '../../../../UI/Buttons/ButtonMain'
import styled from 'styled-components'

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 20px 0;

  @media (${({ theme }) => theme.media.mobile}) {
    padding: 0 0 40px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graphics};

    :last-of-type {
      border-bottom: none;
    }
  }
`

export const Line = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  grid-gap: 0 40px;

  @media (${({ theme }) => theme.media.mobile}) {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: initial;
    grid-gap: 20px 20px;
  }
`

export const Bar = styled.div`
  margin: 10px 0 0 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  grid-gap: 0 40px;

  @media (${({ theme }) => theme.media.mobile}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 20px;
  }
`

export const Button = styled(ButtonMain)`
  justify-self: end;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  line-height: 16px;
`
