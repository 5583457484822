import styled, { css } from 'styled-components'
import { ButtonCross } from '../../UI/Buttons/ButtonsIcon'

interface WrapperProps {
    isVisible: boolean
}

export const Wrapper = styled.div<WrapperProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.66);
    opacity: 0;
    transition: 0.1s;
    z-index: 10000;

    ${({ isVisible }) =>
        isVisible &&
        css`
            visibility: visible;
            opacity: 1;
            transform: scale(1);
        `}
`

interface ContainerProps {
    isVisible: boolean
}

export const Container = styled.div<ContainerProps>`
    position: relative;
    transform: scale(0.85);
    transition: 0.125s;

    ${({ isVisible }) =>
        isVisible &&
        css`
            transform: scale(1);
        `}

    @media (${({ theme }) => theme.media.mobile}) {
        padding: 10px;
        width: 100%;
    }
`

export const ButtonClose = styled(ButtonCross)`
    position: absolute;
    width: 25px;
    height: 25px;
    top: 20px;
    right: 20px;

    @media (${({ theme }) => theme.media.mobile}) {
        top: 20px;
        right: 20px;
    }
`
