import React, { FC } from 'react'
import {
    Container,
    Description,
    FooterLogo,
    Info,
    List,
    ListLink,
    Social,
    SocialLink,
    SocialLinkImage,
    SocialMessage,
    Wrapper
} from './FooterStyled'
import faceBookIcon from '../../images/social/icon_facebook.svg'
import instagramIcon from '../../images/social/icon_instagram.svg'
import { navigationLinks, rightsLinks } from './Constants/footerLinks'
import { externalLinks } from '../../Constants/externalLinks'

const Footer: FC = () => {
    return (
        <Wrapper>
            <Container>
                <FooterLogo />
                <Description>
                    We are very delighted to welcome you into the world of sweet
                    art
                </Description>
            </Container>
            <List>
                {navigationLinks.map((link, i) => (
                    <ListLink to={link.path} key={i}>
                        {link.title}
                    </ListLink>
                ))}
            </List>
            <List>
                {rightsLinks.map((link, i) => (
                    <ListLink to={link.path} key={i}>
                        {link.title}
                    </ListLink>
                ))}
                <Social>
                    <SocialMessage>Follow us</SocialMessage>
                    <SocialLink
                        href={externalLinks.faceBook}
                        target='_blank'
                    >
                        <SocialLinkImage src={faceBookIcon} />
                    </SocialLink>
                    <SocialLink
                        href={externalLinks.instagram}
                        target='_blank'
                    >
                        <SocialLinkImage src={instagramIcon} />
                    </SocialLink>
                </Social>
                <div>
                    <SocialLink
                        href={externalLinks.googleReviews}
                        target='_blank'
                    >
                        Google reviews
                    </SocialLink>
                </div>

            </List>
            <Info>2022 All rights reserved</Info>
        </Wrapper>
    )
}

export default Footer
