import React, { FC } from 'react'
import { Title, Wrapper } from './FeedbackStyled'
import { feedBackList } from '../../../../Constants/PagesTexts/feedBack'
import FeedBackCar from '../../../../components/FeedBackCard/FeedBackCard'
import LaptopSlider from '../../../../components/sliders/LaptopSlider/LaptopSlider'
import { Slide } from '../../../../components/sliders/LaptopSlider/LaptopSliderStyled'
import { useAppSelector } from '../../../../hooks/store'
import MobileSlider from '../../../../components/sliders/mobileSlider/MobileSlider'
import { MobileSlide } from '../../../../components/sliders/mobileSlider/MobileSliderStyled'

const Feedback: FC = () => {
    const { mobile } = useAppSelector((state) => state.appConditionReducer)

    if (!mobile) {
        return (
            <Wrapper>
                <Title>What our customers say</Title>
                <LaptopSlider>
                    {feedBackList.map((item, i) => (
                        <Slide key={i}>
                            <FeedBackCar data={item} />
                        </Slide>
                    ))}
                </LaptopSlider>
            </Wrapper>
        )
    } else {
        return (
            <Wrapper>
                <Title>
                    What our <br></br> customers say
                </Title>
                <MobileSlider>
                    {feedBackList.map((item, i) => (
                        <MobileSlide key={i}>
                            <FeedBackCar data={item} />
                        </MobileSlide>
                    ))}
                </MobileSlider>
            </Wrapper>
        )
    }
}

export default Feedback
