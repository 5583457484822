import { FC, useState, MouseEvent, useRef } from 'react'
import { ButtonCrossDark, ButtonDropDown } from '../../UI/Buttons/ButtonsIcon'
import { FormikErrors, FormikTouched } from 'formik'
import {
    Bar,
    Container,
    Header,
    Item,
    List,
    Title,
    Wrapper,
    Error
} from './DropDownStyled'
import useOutsideClick from '../../hooks/useOutsideClick'

export interface IDropDownOption {
    title: string
    value: string
}

export interface IChangeDropDownData {
    title: string
    value: string
    optionName: string | null
}

interface DropDownProps {
    title: string
    defaultValue?: IDropDownOption | null
    options: IDropDownOption[]
    onChange: (value: IChangeDropDownData) => void
    optionName?: string | null
    onClear?: null | ((value: any) => void)
    notClear?: boolean
    isTouched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
    error?:
        | string
        | string[]
        | FormikErrors<any>
        | FormikErrors<any>[]
        | undefined
}

const DropDown: FC<DropDownProps> = ({
                                         options,
                                         title,
                                         defaultValue = null,
                                         onChange,
                                         optionName = null,
                                         onClear = null,
                                         notClear = false,
                                         isTouched = false,
                                         error = undefined
                                     }) => {
    const [containerIsOpen, setContainerIsOpen] = useState<boolean>(false)
    const [isChosen, setIsChosen] = useState<boolean>(() => !!defaultValue)
    const [currentTitle, setCurrentTitle] = useState(() =>
        defaultValue ? defaultValue.title : title
    )
    const wrapperRef = useRef<HTMLDivElement>(null)

    useOutsideClick(wrapperRef, () => setContainerIsOpen(false))

    const clickHandler = (option: IDropDownOption) => {
        setCurrentTitle(option.title)
        setIsChosen(true)
        onChange({ ...option, optionName })
        setContainerIsOpen(false)
    }

    const clearHandle = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setIsChosen(false)
        setContainerIsOpen(false)
        setCurrentTitle(title)
        if (onClear) {
            onClear(optionName)
        }
    }

    return (
        <Wrapper isChosen={isChosen} isOpen={containerIsOpen} ref={wrapperRef}>
            <Container isOpen={containerIsOpen} isChosen={isChosen}>
                <Header
                    onClick={() => setContainerIsOpen(!containerIsOpen)}
                    isChosen={isChosen}
                >
                    <Title>{currentTitle}</Title>
                    <Bar>
                        {isChosen && !notClear && (
                            <ButtonCrossDark onClick={clearHandle} />
                        )}
                        <ButtonDropDown
                            isOpen={containerIsOpen}
                            isDark={isChosen}
                        />
                    </Bar>
                </Header>
                <List>
                    {options.map((option, i) => (
                        <Item
                            onClick={() => clickHandler(option)}
                            isChosen={isChosen}
                            selected={currentTitle === option.title}
                            key={i}
                        >
                            {option.title}
                        </Item>
                    ))}
                </List>
            </Container>
            {isTouched && error && <Error>{String(error)}</Error>}
        </Wrapper>
    )
}

export default DropDown
