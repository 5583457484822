import styled, { css } from 'styled-components'

interface WrapperProps {
    isOpen: boolean
    isChosen: boolean
}

export const Wrapper = styled.div<WrapperProps>`
    position: relative;
    min-width: 290px;
    height: 44px;

    ${({ isChosen }) =>
        isChosen &&
        css`
            color: ${({ theme }) => theme.colors.background};
        `}

    ${({ isOpen }) =>
        isOpen &&
        css`
            z-index: 10000;
        `}
`

interface HeaderProps {
    isChosen: boolean
}

export const Header = styled.div<HeaderProps>`
    padding: 0 0 0 10px;
    height: 44px;
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: 1fr;
    grid-gap: 0 10px;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graphics};

    ${({ isChosen }) =>
        isChosen &&
        css`
            border-bottom: 1px solid ${({ theme }) => theme.colors.background};
        `}
`

export const Bar = styled.div`
    display: grid;
    grid-auto-columns: min-content;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    grid-gap: 0 7px;
`

export const Title = styled.p`
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
`

interface ContainerProps {
    isOpen: boolean
    isChosen: boolean
}

export const Container = styled.div<ContainerProps>`
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 10px 10px 10px;
    width: 100%;
    height: 44px;
    grid-gap: 15px 0;
    border: 1px solid ${({ theme }) => theme.colors.graphics};
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 4px;
    overflow: hidden;
    z-index: 2;

    ${({ isOpen }) =>
        isOpen &&
        css`
            height: auto;
            box-shadow: 0px 0px 4px 3px rgba(1, 48, 61, 0.2);
        `}

    ${({ isChosen }) =>
        isChosen &&
        css`
            background-color: ${({ theme }) => theme.colors.graphics};
        `}
`

export const List = styled.div`
    padding: 15px 3px 2px 2px;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 5px 0;
    max-height: 250px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 3px;
    }

    @media (${({ theme }) => theme.media.mobile}) {
        grid-gap: 15px 0;
    }
`

interface ItemProps {
    isChosen: boolean
    selected: boolean
}

export const Item = styled.p<ItemProps>`
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.background};

    :hover {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.graphics};
    }

    ${({ isChosen }) =>
        isChosen &&
        css`
            box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.graphics};

            :hover {
                box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.background};
            }
        `}

    ${({ isChosen, selected }) => {
        if (selected) {
            if (isChosen) {
                return css`
                    background-color: ${({ theme }) => theme.colors.background};
                    color: ${({ theme }) => theme.colors.graphics};
                    box-shadow: 0 0 0 1px
                        ${({ theme }) => theme.colors.background};
                `
            }
            return css`
                background-color: ${({ theme }) => theme.colors.graphics};
                color: ${({ theme }) => theme.colors.background};
                box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.graphics};
            `
        }
        return null
    }}
  
  @media (${({ theme }) => theme.media.mobile}) {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.graphics};
    }
`

export const Error = styled.p`
    padding: 0 0 0 10px;
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.error};
    z-index: 1;
`
