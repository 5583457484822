import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100vw;
    height: 100dvh;
    padding: 20px;
`

export const Container = styled.div`
    width: 100%;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.colors.graphics};
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 20px 0;
    align-content: center;
    justify-items: center;
`

export const Title = styled.div`
    font-family: 'Theonory', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;

    @media (${({ theme }) => theme.media.mobile}) {
        font-size: 28px;
        text-align: center;
    }
`

export const SubTitle = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    @media (${({ theme }) => theme.media.mobile}) {
        font-size: 14px;
        text-align: center;
    }
`
