import React, { FC } from 'react'
import { Icon, Title, Wrapper } from './SocialLinkStyled'

interface SocialLinkProps {
    url: string
    icon?: string
    children?: string
}

const SocialLink: FC<SocialLinkProps> = ({url, children, icon}) => {
    return (
        <Wrapper href={url} target="_blank">
            {icon && <Icon url={icon}/>}
            {children && <Title>{children}</Title>}
        </Wrapper>
    )
}

export default SocialLink