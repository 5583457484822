import styled from 'styled-components'
import { MainText, MainTitle } from '../../UI/Common/common'

export const Wrapper = styled.div`
    margin: 60px 0 0 0;
`

export const Title = styled(MainTitle)`
    margin: 0 auto;
    text-align: center;
`

export const Text = styled(MainText)`
    margin: 60px 0 0 0;

    @media (${({ theme }) => theme.media.mobile}) {
        text-align: center;
    }
`

export const Container = styled.div`
    margin: 100px 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (${({ theme }) => theme.media.mobile}) {
        margin: 30px 0 0 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
`

export const List = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (${({ theme }) => theme.media.mobile}) {
        margin: 30px 0 0 0;
    }
`

export const ListTitle = styled.h3`
    margin: 0 0 15px 0;
    font-size: 21px;
    font-weight: 400;

    @media (${({ theme }) => theme.media.mobile}) {
        font-size: 18px;
        font-weight: 500;
        margin: 0 0 10px 0;
    }
`

export const LineContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, max-content);
    grid-auto-flow: column;
    grid-template-rows: 1fr;
    grid-gap: 0 20px;
    align-items: center;
`

export const Map = styled.iframe`
    margin: 100px 0 0 0;
    width: 100%;
    height: 400px;
    outline: none;
    border: 2px solid ${({ theme }) => theme.colors.graphics};
`
