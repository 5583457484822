import React, { FC } from 'react'
import { Answer, Container, Question, Title, Wrapper } from './FAQStyled'
import LinkMore from '../../../../UI/Links/linkMore/LinkMore'
import { mainAskedQuestion } from '../../../../Constants/PagesTexts/askedQuestions'

const FAQ: FC = () => {
    return (
        <Wrapper>
            <Title>Frequently Asked Questions</Title>
            {mainAskedQuestion.map((item, i) => (
                <Container key={i}>
                    <Question>{item.question}</Question>
                    <Answer>{item.answer}</Answer>
                </Container>
            ))}
            <LinkMore message='Read more' path='/f_a_q' />
        </Wrapper>
    )
}

export default FAQ
