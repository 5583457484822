import { FC } from 'react'
import { ButtonSubmit, Fields, Form, FormTitle } from '../../../../../UI/Form/FormStyled'
import Modal from '../../../../../components/Modal/Modal'
import InputPassword from '../../../../../components/Inputs/InputPassword'
import { useFormik } from 'formik'
import { ButtonMain } from '../../../../../UI/Buttons/ButtonMain'
import FieldText from '../../../../../components/Fields/FieldText/FieldText'
import * as Yup from 'yup'
import { yupValidation } from '../../../../../Constants/yupValidation'
import { useAppDispatch } from '../../../../../hooks/store'
import { changePassword } from '../../../../../store/reducers/User/ActionCreators'

interface FormChangePasswordProps {
    userId: string
    isVisible: boolean
    onClose: () => void
}

const FormChangePassword: FC<FormChangePasswordProps> = ({ isVisible, onClose, userId }) => {
    const dispatch = useAppDispatch()

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            checkPassword: ''
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required('This field required'),
            newPassword: yupValidation.password,
            checkPassword: Yup.string()
                .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
                .required('Password confirm is required')
        }),
        onSubmit: async (values, { resetForm }) => {
            const { newPassword, oldPassword } = values
            const result = await dispatch(changePassword({ clientId: userId, newPassword, oldPassword }))
            if (result) {
                onClose()
                resetForm()
            }
        }
    })


    return (
        <Modal isVisible={isVisible} onClose={onClose}>
            <Form onSubmit={formik.handleSubmit}>
                <FormTitle>Change Password</FormTitle>
                <Fields>
                    <FieldText
                        name='oldPassword'
                        value={formik.values.oldPassword}
                        placeholder='Old Password'
                        onChange={formik.handleChange}
                        isTouched={formik.touched.oldPassword}
                        error={formik.errors.oldPassword}
                        type='password'
                    />
                    <FieldText
                        name='newPassword'
                        value={formik.values.newPassword}
                        placeholder='New Password'
                        onChange={formik.handleChange}
                        isTouched={formik.touched.newPassword}
                        error={formik.errors.newPassword}
                        type='password'
                    />
                    <FieldText
                        name='checkPassword'
                        value={formik.values.checkPassword}
                        placeholder='Confirm New Password'
                        onChange={formik.handleChange}
                        isTouched={formik.touched.checkPassword}
                        error={formik.errors.checkPassword}
                        type='password'
                    />
                </Fields>
                <ButtonSubmit type='submit' noActive={!(formik.isValid && formik.dirty)}>Submit</ButtonSubmit>
            </Form>
        </Modal>

    )
}

export default FormChangePassword