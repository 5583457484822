import * as Yup from 'yup'

export const yupValidation = {
    email: Yup.string()
        .email('Enter email')
        .required('The field is required'),
    password: Yup.string()
        .required('The field is required'),
    onlyText: Yup.string()
        .matches(/^[A-Za-zА-Яа-я0-9 ]*$/, 'Only letters and spaces')
        .required('The field is required'),
    phone: Yup.string()
        .matches(
            /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
            'Only numbers and a sign +'
        )
        .required('The field is required')
}