import styled from 'styled-components'
import aboutPng from '../../../../images/about/about-1.webp'
import { MainText, MainTitle } from '../../../../UI/Common/common'

export const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (${({ theme }) => theme.media.mobile}) {
        flex-direction: column;
    }
`

export const Title = styled(MainTitle)`
    margin: 0 0 40px 0;

    @media (${({ theme }) => theme.media.mobile}) {
        width: 100%;
        margin: 0 0 30px 0;
    }
`

export const Text = styled(MainText)``

export const Image = styled.img.attrs({
    alt: 'About',
    src: aboutPng
})`
    margin: 0 140px 0 0;
    width: 35%;

    @media (${({ theme }) => theme.media.mobile}) {
        margin: 0;
        width: 100%;
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (${({ theme }) => theme.media.mobile}) {
        margin: 30px 0 0 0;
        width: 100%;
    }
`
