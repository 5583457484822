import styled, { css } from 'styled-components'

export const FormTitleCss = css`
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.7px;
  text-transform: uppercase;
`

export const MainTextCss = css`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.graphics};
`

export const TitleCss = styled.div`
  font-size: 21px;
  letter-spacing: 0.7px;
`