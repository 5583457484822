import { FC, useMemo } from 'react'
import { useAppSelector } from '../../../../hooks/store'
import CartProduct from './Components/CartProduct/CartProduct'
import { ModuleTitle, Price, Title, Wrapper } from '../ModuleStyled'
import { Container } from './CartProductsStyled'
import { ICardPersistState } from '../../../../store/reducers/CartSlice/CartSlice'
import { IProductsPersistState } from '../../../../store/reducers/ProductsSlice/ProductsSlice'
import { getProductsPrice, requestPriceValue } from '../../../../Helpers/getProductsPrice'

const CartProducts: FC = () => {
    const { products } = useAppSelector(
        (state) => state.persistCardReducer as ICardPersistState
    )

    const finalPrice = useMemo(() => {
        return getProductsPrice(products)
    }, [products])

    return (
        <Wrapper>
            <ModuleTitle>Products</ModuleTitle>
            <Container>
                {products.map((product) => (
                    <CartProduct product={product} key={product.id} />
                ))}
            </Container>
            {(products.length > 1 && finalPrice !== requestPriceValue) && (
                <Price>{finalPrice}</Price>
            )}
        </Wrapper>
    )
}

export default CartProducts
