import { ITheme } from './IBaseTheme'

export const baseTheme: ITheme = {
    colors: {
        background: '#01303D',
        graphics: '#E6D2AA',
        graphicsOpacity: 'rgba(230,210,170,0.6)',
        error: '#de5959'
    },
    media: {
        mobile: 'max-width: 844px'
    },
    size: {
        pageWidth: '1040px',
        headerHeight: '105px'
    }
}
