import styled, { css } from 'styled-components'
import { MainText } from '../../UI/Common/common'
import { Link } from 'react-router-dom'
import { ButtonMain } from '../../UI/Buttons/ButtonMain'

export const Wrapper = styled.div`
  padding: 0 0 70px 0;
  margin: 70px 0 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 30px 0 0 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  margin: 0 100px 0 0;
  //padding: 39% 0 0 0;
  width: 50%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 30px 0 0 0;
    padding: 133% 0 0 0;
    width: calc(100vw - 40px);
  }
`

export const MainImageWrapper = styled.div`
  position: relative;
  padding: 133% 0 0 0;
  width: 100%;
  overflow: hidden;
`

export const MainImage = styled.img.attrs({
    alt: 'MainImage'
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const ImageContainer = styled.div`
  margin: 20px 0 0 0;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: repeat(auto-fill, max-content);
`

export const PreviewImage = styled.img.attrs({
    alt: 'PreviewImage'
})`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`

export const Container = styled.div`
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  max-width: 100%;
  min-height: 0;
  min-width: 0;
`

export const Line = styled.div`
  width: 100%;
  margin: 40px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ButtonCart = styled(ButtonMain)`
  margin: 40px 0 0 0;

  @media (${({ theme }) => theme.media.mobile}) {
    height: 60px;
    width: 100%;
  }
`

export const Title = styled(MainText)`
  margin: 0;
  font-size: 40px;
  line-height: 40px;

  @media (${({ theme }) => theme.media.mobile}) {
    width: 100%;
    font-size: 30px;
    line-height: 30px;
  }
`

export const PriceValue = styled(MainText)`
  margin: 0;
  font-size: 28px;
`

export const Description = styled(MainText)`
  margin: 20px 0 0 0;
  white-space: pre-wrap;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 10px 0 0 0;
    font-size: 14px;
  }
`

export const SectionName = styled(MainText)`
  margin: 20px 0 0 0;
  font-size: 21px;
`

interface FilingTextProps {
    num: number
    activeFilling: number
}

export const SelectedText = styled(MainText)<FilingTextProps>`
  margin: 10px 0 0 0;
  padding: 5px 7px;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
  border-radius: 10px;

  ${({ num, activeFilling }) =>
          num === activeFilling &&
          css`
            padding: 4px 6px;
            border: 1px solid ${({ theme }) => theme.colors.graphics};
          `}
`

export const MoreLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.graphics};
  font-size: 16px;
`
