import { ChangeEvent, FC } from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import { Input, Label, Placeholder, Error, Logo } from '../FieldsStyled'

export interface IInputLogo {
    light: string
    dark: string
}

interface FieldProps {
    name: string
    value: string
    placeholder: string
    logo?: IInputLogo | null
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    type?: string
    isActive?: boolean
    isTouched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
    error:
        | string
        | string[]
        | FormikErrors<any>
        | FormikErrors<any>[]
        | undefined
}

const FieldNumber: FC<FieldProps> = ({
    name,
    value,
    onChange,
    placeholder,
    isActive = false,
    logo = null,
    type = 'text',
    isTouched,
    error
}) => {
    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.match(/[A-Za-zА-Яа-яЁё]/)) {
            return
        } else onChange(e)
    }

    return (
        <Label>
            <Placeholder>{placeholder}</Placeholder>
            <Input
                isActive={isActive}
                name={name}
                value={value}
                onChange={changeHandler}
                type={type}
                autoComplete="offf"
                isLogo={!!logo}
            />
            {logo && (
                <Logo src={isActive ? logo.dark : logo.light} alt="logo" />
            )}
            {isTouched && error && <Error>{String(error)}</Error>}
        </Label>
    )
}

export default FieldNumber
