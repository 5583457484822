import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    margin: 15px 0 0 0;
    display: grid;
    grid-template-columns: max-content;
    grid-auto-rows: min-content;
    grid-gap: 20px 0;
`
export const Title = styled.div`
    font-size: 28px;
    line-height: 28px;

    @media (${({ theme }) => theme.media.mobile}) {
        margin: 5px 0 0 0;
        font-size: 18px;
        line-height: 16px;
    }
`
