import styled, { css } from 'styled-components'
import { MainTextCss } from '../../../UI/Text/Text'
import { ButtonCrossDark } from '../../../UI/Buttons/ButtonsIcon'
import { iconCrossDark, iconCrossLight } from '../../../UI/Icons/icons'

export const Wrapper = styled.div`
  position: relative;
`

interface PredictionsProps {
    isVisible: boolean
}

export const Predictions = styled.div<PredictionsProps>`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  padding: 4px;
  width: 100%;
  display: none;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 4px 0;
  background-color: ${({ theme }) => theme.colors.graphics};
  border-radius: 4px;
  z-index: 1000;

  ${({ isVisible }) =>
          isVisible &&
          css`
            display: grid;
          `}
}
`

export const Prediction = styled.div`
  padding: 4px 7px;
    // background-color: ${({ theme }) => theme.colors.background};
  border-radius: 4px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.background};

  :hover {
    outline: 1px solid ${({ theme }) => theme.colors.background};
  }

  @media (${({ theme }) => theme.media.mobile}) {
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.graphics};
  }
`

export const PoweredBy = styled.div`
  padding: 0 10px 5px 0;
  display: grid;
  grid-template-columns: max-content min-content;
  grid-template-rows: 1fr;
  grid-gap: 0 10px;
  justify-content: end;
  align-items: center;
`

export const PoweredByTitle = styled.div`
  ${MainTextCss};
  color: ${({ theme }) => theme.colors.background};

  @media (${({ theme }) => theme.media.mobile}) {
    font-size: 12px;
  }
`

export const PoweredByImage = styled.img`
  @media (${({ theme }) => theme.media.mobile}) {
    width: 50px;
  }
`

interface ButtonClearProps {
    isDark: boolean
}

export const ButtonClear = styled.div<ButtonClearProps>`
  position: absolute;
  bottom: 12px;
  right: 12px;
  width: 20px;
  height: 20px;
  background-image: url(${iconCrossLight});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;

  ${({ isDark }) => isDark && css`
    background-image: url(${iconCrossDark});
  `}
`
