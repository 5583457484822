import React, { FC } from 'react'
import { Container, Image, Text, Title, Wrapper } from './DeliveryStyled'
import LinkMore from '../../../../UI/Links/linkMore/LinkMore'

const Delivery: FC = () => {
    return (
        <Wrapper>
            <Container>
                <Title>Delivery</Title>
                <Text>
                    Delivery is available throughout all of London and beyond, we deliver orders safely and on time
                </Text>
                <LinkMore message='Read more' path='/delivery' />
            </Container>
            <Image />
        </Wrapper>
    )
}

export default Delivery
