import React, { FC } from 'react'
import { IProduct, IProductsSection } from '../../../../../../Types/IProduct'
import styled from 'styled-components'
import Card from '../Card/Card'
import { useNavigate } from 'react-router'
import { getImageUrl } from '../../../../../../utils/getImageUrl'
import { useAppSelector } from '../../../../../../hooks/store'
import { MobileSlide } from '../../../../../../components/sliders/mobileSlider/MobileSliderStyled'
import MobileSlider from '../../../../../../components/sliders/mobileSlider/MobileSlider'
import { isEven } from '../../../../../../utils/isEven'

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: max-content;
    grid-gap: 20px 20px;

    @media (${({ theme }) => theme.media.mobile}) {
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;
        grid-gap: 30px 0;
    }
`

export const ProductImage = styled.img.attrs({
    alt: 'Product'
})`
    width: 100%;
    cursor: pointer;
    transition: 0.3s;

    :hover {
        transform: scale(1.03);
    }
`

interface ISectionProps {
    section: IProductsSection
}

const Section: FC<ISectionProps> = ({ section }) => {
    const { mobile } = useAppSelector((state) => state.appConditionReducer)
    const navigate = useNavigate()
    const products: IProduct[] = section.products.slice(0, 3)

    const goToProduct = (sectionId: string, productId: string) => {
        navigate(`sections/${sectionId}/${productId}`)
    }

    if (!mobile) {
        if (!isEven(section.order)) {
            return (
                <Wrapper>
                    <Card
                        title={section.title}
                        description={section.description}
                        path={`sections/${section.id}`}
                    />
                    {products.map(product =>
                        <ProductImage
                            src={getImageUrl(product.photo[0])}
                            onClick={() => goToProduct(section.id, product.id)}
                            key={product.id}
                        />
                    )}
                </Wrapper>
            )
        } else {
            return (
                <Wrapper>
                    {products.map(product =>
                        <ProductImage
                            src={getImageUrl(product.photo[0])}
                            onClick={() => goToProduct(section.id, product.id)}
                            key={product.id}
                        />
                    )}
                    <Card
                        title={section.title}
                        description={section.description}
                        path={`sections/${section.id}`}
                    />
                </Wrapper>
            )
        }

    } else {
        return (
            <Wrapper>
                <Card
                    title={section.title}
                    description={section.description}
                    path={section.path}
                />
                <MobileSlider>
                    {section.products.map((product, i) => (
                        <MobileSlide key={i}>
                            {product.photo[0] && (
                                <ProductImage
                                    src={getImageUrl(product.photo[0])}
                                    onClick={() => goToProduct(section.id, product.id)}
                                />)}
                        </MobileSlide>
                    ))}
                </MobileSlider>
            </Wrapper>
        )
    }


}

export default Section