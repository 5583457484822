import React, { Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from 'react'
import { Container, Header, Title, Wrapper } from './ParametrStyled'
import { ButtonDropDown } from '../../../../UI/Buttons/ButtonsIcon'

interface ParameterProps {
    children?: ReactNode
    num: number
    openedParameter: number
    clickHandler: Dispatch<SetStateAction<number>>
    title: string
}

const Parameter: FC<ParameterProps> = ({
                                           children,
                                           num,
                                           openedParameter,
                                           clickHandler,
                                           title
                                       }) => {

    const [isOpen, setIsOpen] = useState<boolean>(false)

    useEffect(() => {
        if (num === openedParameter) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
    }, [openedParameter])

    function wrapperClickHandler() {
        if (openedParameter === num) {
            clickHandler(0)
            return
        }
        clickHandler(num)
    }

    return (
        <Wrapper onClick={wrapperClickHandler}>
            <Header isOpen={isOpen}>
                <Title>{title}</Title>
                <ButtonDropDown isOpen={isOpen} />
            </Header>

            <Container isOpen={isOpen}>
                {children}
            </Container>
        </Wrapper>
    )
}

export default Parameter
