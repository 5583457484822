import React, { FC } from 'react'
import { useAppSelector } from '../../hooks/store'
import { Container, Description, Image, Text, Title, Wrapper } from './AboutAsPageStyled'

import LaptopSlider from '../../components/sliders/LaptopSlider/LaptopSlider'
import { Slide } from '../../components/sliders/LaptopSlider/LaptopSliderStyled'
import FeedBackCard from '../../components/FeedBackCard/FeedBackCard'
import MobileSlider from '../../components/sliders/mobileSlider/MobileSlider'
import { MobileSlide } from '../../components/sliders/mobileSlider/MobileSliderStyled'

import { feedBackList } from '../../Constants/PagesTexts/feedBack'
import about1Png from '../../images/about/about-1.webp'
import about2Png from '../../images/about/about-2.webp'
import about3Png from '../../images/about/about-3.webp'


const AboutAsPage: FC = () => {

    const { mobile } = useAppSelector((state) => state.appConditionReducer)

    if (!mobile) {
        return (
            <Wrapper>
                <Title>About us</Title>
                <Container>
                    <Image src={about1Png} />
                    <Description>
                        <Text>
                            Let’s be honest, elaborate confectionary is an art form best left to an expert. It requires
                            experience and knowledge quite distinct from household cookery. Even the proudest French
                            home chefs will agree, it’s no disgrace, but quite the contrary to say they’ve ordered a
                            special cake from a celebrated pâtissier! Here in the U.K., good pastry shops are few and
                            far between.
                            Jelena’s Cake is that rare thing: a reliable supplier of the highest quality of bespoke
                            confectionary guaranteed to delight even the most discerning and demanding customer that can
                            be delivered direct to you or collected
                        </Text>
                    </Description>
                </Container>
                <Container>
                    <Description>
                        <Text>
                            Ever since Jelena’s mother introduced her to the traditional cake-making methods on her
                            ninth birthday, Jelena – founder and CEO, has developed a deep passion in both producing the
                            most attractive, delicious cakes from the best, purest and freshest ingredients and in
                            working with her customers towards delivering their exact requirements
                        </Text>
                    </Description>
                    <Image src={about2Png} />
                </Container>
                <Container>
                    <Image src={about3Png} />
                    <Description>
                        <Text>
                            Today Jelena, with over twenty years of experience as a professional confectioner,
                            deservedly enjoys repeat orders and outstanding reviews from a loyal and growing client base
                            – proving the old adage that “nothing succeeds like success”!
                            If you want that special occasion to be truly special, look no further.
                            See below a selection of exquisite cakes and cookies that strike the perfect balance between
                            appearance, texture and flavour, all baked to flawless perfection
                        </Text>
                    </Description>
                </Container>
                <Title>What our customers say</Title>
                <LaptopSlider>
                    {feedBackList.map((item, i) => (
                        <Slide key={i}>
                            <FeedBackCard data={item} />
                        </Slide>
                    ))}
                </LaptopSlider>
            </Wrapper>
        )
    } else {
        return (
            <Wrapper>
                <Title>About us</Title>
                <Container>
                    <Image src={about1Png} />
                    <Description>
                        <Text>
                            Let’s be honest, elaborate confectionary is an art form best left to an expert. It requires
                            experience and knowledge quite distinct from household cookery. Even the proudest French
                            home chefs will agree, it’s no disgrace, but quite the contrary to say they’ve ordered a
                            special cake from a celebrated pâtissier! Here in the U.K., good pastry shops are few and
                            far between.
                            Jelena’s Cake is that rare thing: a reliable supplier of the highest quality of bespoke
                            confectionary guaranteed to delight even the most discerning and demanding customer that can
                            be delivered direct to you or collected
                        </Text>
                    </Description>
                </Container>
                <Container>
                    <Image src={about2Png} />
                    <Description>
                        <Text>
                            Ever since Jelena’s mother introduced her to the traditional cake-making methods on her
                            ninth birthday, Jelena – founder and CEO, has developed a deep passion in both producing the
                            most attractive, delicious cakes from the best, purest and freshest ingredients and in
                            working with her customers towards delivering their exact requirements
                        </Text>
                    </Description>
                </Container>
                <Container>
                    <Image src={about3Png} />
                    <Description>
                        <Text>
                            Today Jelena, with over twenty years of experience as a professional confectioner,
                            deservedly enjoys repeat orders and outstanding reviews from a loyal and growing client base
                            – proving the old adage that “nothing succeeds like success”!
                            If you want that special occasion to be truly special, look no further.
                            See below a selection of exquisite cakes and cookies that strike the perfect balance between
                            appearance, texture and flavour, all baked to flawless perfection
                        </Text>
                    </Description>
                </Container>
                <Title>What our customers say</Title>
                <MobileSlider>
                    {feedBackList.map((item, i) => (
                        <MobileSlide key={i}>
                            <FeedBackCard data={item} />
                        </MobileSlide>
                    ))}
                </MobileSlider>
            </Wrapper>
        )
    }

}

export default AboutAsPage
