import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

export const Slider = styled(Swiper)`
  padding: 0 0 40px 0;
  max-width: calc(100vw - 40px);

  .swiper-pagination {
    top: calc(100% - 25px);
    padding: 3px 0;
  }

  .swiper-pagination-bullet {
    position: relative;
    border: 1px solid ${({ theme }) => theme.colors.graphics};
    width: 12px;
    height: 12px;
    background-color: transparent;
    opacity: 0.8;
    border-radius: 50%;
  }

  .swiper-pagination-bullet-active {
    background-color: transparent;

    :before {
      content: "";
      position: absolute;
      top: calc(50% - 4px);
      left: calc(50% - 4px);
      width: 8px;
      height: 8px;
      background-color: ${({ theme }) => theme.colors.graphics};
      border-radius: 50%;
    }
  }

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(.76);

  }
`

export const MobileSlide = styled(SwiperSlide)`
  font-size: 18px;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
`
