import { useAppSelector } from '../../../hooks/store'
import { IAppOptionsState } from '../../../store/reducers/AppOptions/AppOptionsSlice'
import { useEffect } from 'react'

const isCongestionTime = (time: string, secondTime: string, from: string, to: string) => {
    if (time >= from && time <= to) {
        return true
    }
    if (secondTime >= from && time <= to) {
        return true
    }
    if (from >= time && from <= secondTime) {
        return true
    }
    if (to >= time && to <= secondTime) {
        return true
    }
    return false
}

export const useDeliveryPrice = () => {
    const {
        distanceRatio,
        congestionPrice,
        congestionWeekDays,
        congestionDaysInterval
    } = useAppSelector(
        (state) => (state.appOptionsReducer as IAppOptionsState).appDelivery
    )

    const getDeliveryPrice = (distance: string): string => {
        let result = ''
        const price = Number(distance) * Number(distanceRatio)
        const rest = String(price).split('.')
        result += rest[0]
        if (rest[1]) {
            if (Number(rest[1][0]) < 5) {
                result += '.50'
            } else {
                result = `${Number(result) + 1}.00`
            }
        }
        return result
    }

    const addCongestionPrice = (
        price: string,
        date: Date,
        time: string,
        secondTime: string
    ): string => {
        if (
            date >= congestionDaysInterval.from &&
            date <= congestionDaysInterval.to
        ) {
            return price
        }
        const currentWeekDays = congestionWeekDays.find(
            ({ day }) => day === date.getDay()
        )
        if (!currentWeekDays) {
            return price
        }
        if (isCongestionTime(time, secondTime, currentWeekDays.from, currentWeekDays.to)) {
            return String((Number(price) + Number(congestionPrice)).toFixed(2))
        }
        return price
    }

    return { getDeliveryPrice, addCongestionPrice }
}
