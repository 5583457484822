import { AppDispatch } from '../../store'
import { instance } from '../../../API/baseAPI'
import { showMessage } from '../AppCondition/AppConditionSlice'
import {
    IChangeAddress,
    IChangeClient,
    IChangePassword,
    ICreateOrder,
    ILogin,
    IRemoveAddress,
    ISignUp,
    IRemoveImageRequest,
    IAddOrder,
    IRecoveryConfirm,
    ICreateAddress, IChangeImages
} from './Types'
import {
    IAddressData,
    IUser
} from '../../../Types/IUser'
import {
    addNewOrder,
    addUserAddress,
    changeImages,
    changeUserAddress,
    removeUserAddress,
    setUser
} from './UserSlice'
import { setIsAuth } from '../AppOptions/AppOptionsSlice'
import { IOrder } from '../../../Types/IOrder'
import { ITransaction } from '../../../Types/ITransactions'
import { setTransactions } from '../Transactions/TransactionsSlice'
import { errorHandler } from '../../Helpers/errorHandler'

export const signUp = (data: ISignUp) => async (dispatch: AppDispatch) => {
    try {
        const response = await instance.post<IUser>(
            '/clients/auth/signup',
            data
        )
        dispatch(setUser(response.data))
        dispatch(setIsAuth(true))
        return true
    } catch (err: any) {
        dispatch(errorHandler(err))
    }
}

export const login = (data: ILogin) => async (dispatch: AppDispatch) => {
    try {
        const response = await instance.post<IUser>('clients/auth/login', data)
        dispatch(setUser(response.data))
        dispatch(setIsAuth(true))
        return true
    } catch (err: any) {
        dispatch(errorHandler(err))
    }
}

export const logOut = () => async (dispatch: AppDispatch) => {
    try {
        await instance.get<IUser>('clients/auth/logout')
        dispatch(setUser(null))
        dispatch(setIsAuth(false))
        return
    } catch (err: any) {
        dispatch(errorHandler(err))
    }
}

export const removeAccount = (data: { clientId: string }) => async (dispatch: AppDispatch) => {
    try {
        const response = await instance.delete<string>('clients', { data })
        if (response.data) {
            dispatch(setUser(null))
            dispatch(setIsAuth(false))
        }
        return
    } catch (err: any) {
        dispatch(errorHandler(err))
    }
}

export const recoveryPassword =
    (email: string) => async (dispatch: AppDispatch) => {
        try {
            return await instance.post<boolean>('clients/auth/recovery', {
                email
            })
        } catch (err: any) {
            dispatch(errorHandler(err))
        }
    }

export const recoveryConfirm =
    (data: IRecoveryConfirm) => async (dispatch: AppDispatch) => {
        try {
            return await instance.post<boolean>(
                'clients/auth/recovery/confirm',
                data
            )
        } catch (err: any) {
            dispatch(errorHandler(err))
        }
    }

export const recoveryChange =
    (newPassword: string) => async (dispatch: AppDispatch) => {
        try {
            const response = await instance.post<IUser>(
                'clients/auth/recovery/change',
                { newPassword }
            )
            dispatch(setUser(response.data))
            dispatch(setIsAuth(true))
        } catch (err: any) {
            dispatch(errorHandler(err))
        }
    }

export const getProfile = () => async (dispatch: AppDispatch) => {
    try {
        const response = await instance.get<IUser>('/clients/auth/profile')
        dispatch(setUser(response.data))
        return
    } catch (err: any) {
        dispatch(setIsAuth(false))
        dispatch(setUser(null))
        return
    }
}

export const changePassword =
    (data: IChangePassword) => async (dispatch: AppDispatch) => {
        try {
            const response = await instance.post<IUser>(
                '/clients/auth/password',
                data
            )
            return response.data
        } catch (err: any) {
            dispatch(errorHandler(err))
        }
    }

export const changeProfile =
    (data: IChangeClient) => async (dispatch: AppDispatch) => {
        try {
            const response = await instance.patch<IUser>(
                '/clients',
                data
            )
            dispatch(setUser(response.data))
            return true
        } catch (err: any) {
            dispatch(errorHandler(err))
        }
    }

export const addAddress =
    (data: { clientId: string }) => async (dispatch: AppDispatch) => {
        try {
            const response = await instance.post<{
                clientId: string
                address: IAddressData
            }>('/clients/address', data)
            dispatch(addUserAddress(response.data.address))
            return
        } catch (err: any) {
            dispatch(errorHandler(err))
        }
    }

export const createAddress =
    (data: ICreateAddress) =>
        async (dispatch: AppDispatch): Promise<IAddressData | undefined> => {
            try {
                const response = await instance.post<{
                    clientId: string
                    address: IAddressData
                }>('/clients/address/new', data)
                dispatch(addUserAddress(response.data.address))
                return response.data.address
            } catch (err: any) {
                dispatch(errorHandler(err))
            }
        }

export const removeAddress =
    (data: IRemoveAddress) => async (dispatch: AppDispatch) => {
        try {
            const response = await instance.delete<IRemoveAddress>(
                '/clients/address/data',
                { data }
            )
            dispatch(removeUserAddress(response.data))
            return
        } catch (err: any) {
            dispatch(errorHandler(err))
        }
    }

export const changeAddress =
    (data: IChangeAddress) => async (dispatch: AppDispatch) => {
        try {
            const response = await instance.patch<IChangeAddress>(
                '/clients/address/data',
                data
            )
            dispatch(changeUserAddress(response.data))
            return
        } catch (err: any) {
            dispatch(errorHandler(err))
        }
    }

export const createOrder =
    (data: ICreateOrder) => async (dispatch: AppDispatch) => {
        try {
            await instance.post<IOrder>('/orders/client/clear', data)
            return true
        } catch (err: any) {
            dispatch(errorHandler(err))
        }
    }

export const addOrder = (data: IAddOrder) => async (dispatch: AppDispatch) => {
    try {
        const response = await instance.post<IOrder>('/orders/client', data)
        dispatch(addNewOrder(response.data))
        return true
    } catch (err: any) {
        dispatch(errorHandler(err))
    }
}

export const addOrderImage =
    (data: FormData) =>
        async (dispatch: AppDispatch) => {
            try {
                const response = await instance.post<IChangeImages>(
                    '/orders/images',
                    data
                )
                dispatch(changeImages(response.data))
                return true
            } catch (err: any) {
                dispatch(errorHandler(err))
            }
        }

export const removeOrderImage =
    (data: IRemoveImageRequest) =>
        async (dispatch: AppDispatch) => {
            try {
                const response = await instance.delete<IChangeImages>(
                    '/orders/images',
                    { data }
                )
                dispatch(changeImages(response.data))
                return
            } catch (err: any) {
                dispatch(errorHandler(err))
            }
        }
