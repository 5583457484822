import React, { FC } from 'react'
import { Container, Named, Span, Text, Title, Wrapper } from '../../UI/Text/TextPage'

import { askedQuestionsData } from '../../Constants/PagesTexts/askedQuestions'
import { TextItem } from './Components/TextItem/TextItem'


const FAQPage: FC = () => {
    return (
        <Wrapper>
            <Title>Frequently Asked Questions</Title>
            {askedQuestionsData.map((question, questionIndex) => (
                <Container key={questionIndex}>
                    <Named>{questionIndex + 1}. {question.title}</Named>
                    {question.text.map((text, textIndex) => text === 'span' ?
                        <Span key={textIndex}>{text}</Span> :
                        <TextItem key={textIndex} textData={text} />
                    )}
                </Container>
            ))}
        </Wrapper>
    )
}

export default FAQPage
