import { Dispatch, FC, SetStateAction } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { yupValidation } from '../../../../Constants/yupValidation'
import {
    ButtonSubmit,
    Fields,
    Form,
    FormLink,
    FormMessage,
    FormStringButton,
    FormTitle
} from '../../../../UI/Form/FormStyled'
import FieldText from '../../../../components/Fields/FieldText/FieldText'
import { useAppDispatch } from '../../../../hooks/store'
import { login } from '../../../../store/reducers/User/ActionCreators'
import { useNavigate } from 'react-router'
import { TabKey } from '../../Layout'

interface FormLoginProps {
    openTab: TabKey
    setOpenTab: Dispatch<SetStateAction<TabKey>>
}

const FormLogin: FC<FormLoginProps> = ({ openTab, setOpenTab }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { values, touched, errors, handleChange, handleSubmit, resetForm } =
        useFormik({
            initialValues: {
                email: '',
                password: ''
            },
            validationSchema: Yup.object({
                email: yupValidation.email,
                password: yupValidation.password
            }),
            onSubmit: async (values, { resetForm }) => {
                const result = await dispatch(login(values))
                if (result) {
                    setOpenTab('close')
                    navigate('/account')
                    resetForm({})
                }
            }
        })

    if (openTab !== 'login') return null
    return (
        <Form onSubmit={handleSubmit}>
            <FormTitle>Log in</FormTitle>
            <Fields>
                <FieldText
                    name="email"
                    value={values.email}
                    placeholder="Email"
                    onChange={handleChange}
                    isTouched={touched.email}
                    error={errors.email}
                    type="email"
                />
                <FieldText
                    name="password"
                    value={values.password}
                    placeholder="Password"
                    onChange={handleChange}
                    isTouched={touched.password}
                    error={errors.password}
                    type="password"
                />
            </Fields>
            <FormStringButton onClick={() => setOpenTab('recovery')}>
                Forgot password?
            </FormStringButton>
            <ButtonSubmit type="submit">Submit</ButtonSubmit>
            <FormMessage>
                If you do not have an account,{' '}
                <FormLink onClick={() => setOpenTab('signup')}>
                    register
                </FormLink>
            </FormMessage>
        </Form>
    )
}

export default FormLogin
