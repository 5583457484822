import { FC, useMemo } from 'react'
import { useLocation } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../hooks/store'
import { getStringDate } from '../../Helpers/getStrungDate'
import { deliveryMethods } from '../../Constants/deliveryMethods'
import FileLoader from '../../components/FileLoader/FileLoader'
import {
    addOrderImage,
    removeOrderImage
} from '../../store/reducers/User/ActionCreators'
import OrderImage from './Components/OrderImage/OrderImage'
import { IImage } from '../../Types/IImage'
import {
    Delivery,
    DeliveryLine,
    DeliveryText, DeliveryValue,
    Media,
    MediaButton,
    MediaImages,
    Section,
    Table,
    TableLine,
    TableSubLine,
    TableText,
    Title,
    Wrapper
} from './OrderStyled'
import { getProductsPrice, requestPriceValue } from '../../Helpers/getProductsPrice'
import OrderProduct from './Components/OrderProduct/OrderProduct'

interface ILocationState {
    orderId: string
}

const getDeliveryMethodTitle = (methodValue: string) => {
    const method = deliveryMethods.find(({ value }) => value === methodValue)
    if (method) {
        return method.title
    }
    return ''
}

const Order: FC = () => {
    const dispatch = useAppDispatch()
    const { orderId } = useLocation().state as ILocationState
    const order = useAppSelector((state) => {
        if (!state.userReducer.user || !orderId) {
            return null
        }
        return state.userReducer.user.orders.find(
            ({ id }) => id === orderId
        )
    })

    const orderProducts = order ? order.products : []
    const productsPrice = order ? getProductsPrice(order.products) : ''

    const isCustomMade = !!order?.products.some(
        ({ sectionName }) => sectionName.toLowerCase() === 'custom made'
    )

    if (!order) {
        return <Title>Order not found</Title>
    }

    const addImageHandler = (data: FormData) => {
        dispatch(addOrderImage(data))
    }

    const removeImageHandler = (image: IImage) => {
        if (!order) return
        dispatch(removeOrderImage({ ...image, orderId: order.id }))
    }

    return (
        <Wrapper>
            <Section>
                <Title>Order info</Title>
                <Table>
                    <TableLine isBright={true}>
                        <TableText>Product</TableText>
                        <TableText>Options</TableText>
                        <TableText>Quantity</TableText>
                        <TableText>Cost</TableText>
                        <TableText>Sum</TableText>
                    </TableLine>
                    {orderProducts.map((product) => (
                        <OrderProduct product={product} key={product.id} />
                    ))}
                    {productsPrice !== requestPriceValue && (
                        <TableSubLine>
                            <TableText>{productsPrice}</TableText>
                        </TableSubLine>
                    )}
                </Table>
            </Section>
            <Section>
                <Title>Delivery Info</Title>
                <Delivery>
                    <DeliveryLine>
                        <DeliveryText>Delivery Method</DeliveryText>
                        <DeliveryValue>
                            {getDeliveryMethodTitle(order.delivery.method)}
                        </DeliveryValue>
                    </DeliveryLine>
                    <DeliveryLine>
                        <DeliveryText>Delivery Date</DeliveryText>
                        <DeliveryValue>
                            {getStringDate(order.delivery.date) ? getStringDate(order.delivery.date) : '-'}
                        </DeliveryValue>
                    </DeliveryLine>
                    <DeliveryLine>
                        <DeliveryText>Delivery Time</DeliveryText>
                        <DeliveryValue>
                            {order.delivery.time} - {order.delivery.secondTime}
                        </DeliveryValue>
                    </DeliveryLine>
                    {order.deliveryPlace.address && (
                        <DeliveryLine>
                            <DeliveryText>Delivery Address</DeliveryText>
                            <DeliveryValue>{order.deliveryPlace.address}</DeliveryValue>
                        </DeliveryLine>
                    )}
                </Delivery>
            </Section>
            {isCustomMade && (
                <Section>
                    <Title>Images</Title>
                    <Media>
                        <FileLoader
                            onLoad={addImageHandler}
                            data={{ orderId: order.id }}
                            accept='image/jpeg, image/png, image/svg+xml'
                        >
                            <MediaButton>Add Image</MediaButton>
                        </FileLoader>
                        {!!order.images.length && (
                            <MediaImages>
                                {order.images.map((image) => (
                                    <OrderImage
                                        image={image}
                                        onRemove={removeImageHandler}
                                        key={image.name}
                                        isRemoved={
                                            order.status === 'created' ||
                                            order.status === 'current'
                                        }
                                    />
                                ))}
                            </MediaImages>
                        )}
                    </Media>
                </Section>
            )}
        </Wrapper>
    )
}

export default Order
