import React, { FC } from 'react'
import { IProductsSection } from '../../Types/IProduct'
import { Description, Title, Wrapper } from './ShopPageStyled'
import ProductCard from './Components/ProductCard/ProductCard'
import BestSellers from '../../components/BestSellers/BestSellers'
import { useAppSelector } from '../../hooks/store'
import { useParams } from 'react-router'
import { IProductsPersistState } from '../../store/reducers/ProductsSlice/ProductsSlice'


const ShopPage: FC = () => {

    const { sectionId } = useParams()

    const section = useAppSelector((state) => {
        return (state.productReducer as IProductsPersistState).productsSections.find(({ id }) => id === sectionId)
    })

    if (!section) {
        return null
    }
    
    return (
        <Wrapper>
            <Title>{section.title}</Title>
            <Description>{section.description}</Description>
            {section.products.map((product, i) => (
                <ProductCard
                    product={product}
                    sectionName={section.title}
                    sectionId={section.id} key={i}
                />
            ))}
            <BestSellers />
        </Wrapper>
    )
}

export default ShopPage
