import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IProductsSection, IProductsSectionBD } from '../../../Types/IProduct'
import storage from 'reduxjs-toolkit-persist/lib/storage'
import { persistReducer } from 'reduxjs-toolkit-persist'

const persistCardConfig = {
    key: 'products',
    storage
}

export interface IProductsPersistState {
    productsSections: IProductsSection[]
    treadsSections: IProductsSection[]
}

const initialState: IProductsPersistState = {
    productsSections: [],
    treadsSections: []
}

const getPath = (str: string) => {
    return '/' + str.replace(/\s/g, '_').toLowerCase()
}

const addPathToProductsSections = (
    productSections: IProductsSectionBD[]
): {
    treads: IProductsSection[]
    products: IProductsSection[]

} => {
    const allSections = productSections
        .map((section) => {
            const sectionPath = getPath(section.title)
            return {
                ...section,
                path: sectionPath,
                products: section.products
                    .map((product) => ({
                        ...product,
                        path: sectionPath + getPath(product.title)
                    }))
                    .filter(({ isActive }) => isActive)
                    .sort((a, b) => (a.order > b.order ? 1 : -1))
            }
        })
        .sort((a, b) => (a.order > b.order ? 1 : -1))
    const treadsSections: IProductsSection[] = []
    const sections: IProductsSection[] = []
    allSections.forEach(section => {
        if (section.isTreads && section.isActive) {
            treadsSections.push(section)
        } else {
            sections.push(section)
        }
    })
    return { treads: treadsSections, products: sections }
}

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProductSections(state, action: PayloadAction<IProductsSectionBD[]>) {
            const { treads, products } = addPathToProductsSections(action.payload)
            state.productsSections = products
            state.treadsSections = treads
        }
    }
})

export const { setProductSections } = productsSlice.actions

export default persistReducer(persistCardConfig, productsSlice.reducer)
