import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import storage from 'reduxjs-toolkit-persist/lib/storage'
import { persistReducer } from 'reduxjs-toolkit-persist'
import { IAppOptionsResponse, ICongestionWeekDay } from './Types'

const toDay = new Date()
const startDate = new Date(toDay.getFullYear(), 11, 25)
const endDate = new Date(toDay.getFullYear() + 1, 0, 1)

const persistCardConfig = {
    key: 'addOptions',
    storage
}

export interface IAppOptionsState {
    appDelivery: {
        originDeliveryAddress: string
        distanceRatio: string
        congestionChargeZone: string[]
        congestionPrice: string
        congestionWeekDays: { day: number; from: string; to: string }[]
        congestionDaysInterval: {
            from: Date
            to: Date
        }
    }
    closeOrderDates: {
        closeWeekDays: number[]
        closeDates: string[]
    }
    isAuth: boolean
    updatedAt: string
}

const initialState: IAppOptionsState = {
    updatedAt: '',
    isAuth: false,
    appDelivery: {
        originDeliveryAddress: '66 Bradfield Dr, Barking IG11 9AS, UK',
        distanceRatio: '1.25',
        congestionChargeZone: [
            'EC1A',
            'EC1M',
            'EC1N',
            'EC1P',
            'EC1R',
            'EC1V',
            'EC1Y',
            'EC2A',
            'EC2M',
            'EC2N',
            'EC2P',
            'EC2R',
            'EC2V',
            'EC2Y',
            'EC3A',
            'EC3M',
            'EC3N',
            'EC3P',
            'EC3R',
            'EC3V',
            'EC4A',
            'EC4M',
            'EC4N',
            'EC4P',
            'EC4R',
            'EC4V',
            'EC4Y',
            'SE1',
            'SW1A',
            'SW1E',
            'SW1H',
            'SW1P',
            'SW1V',
            'SW1W',
            'SW1X',
            'SW1Y',
            'W1A',
            'W1B',
            'W1C',
            'W1D',
            'W1F',
            'W1G',
            'W1H',
            'W1J',
            'W1K',
            'W1S',
            'W1T',
            'W1U',
            'W1W',
            'WC1A',
            'WC1B',
            'WC1E',
            'WC1H',
            'WC1N',
            'WC1R',
            'WC1V',
            'WC1X',
            'WC2A',
            'WC2B',
            'WC2E',
            'WC2H',
            'WC2N',
            'WC2R'
        ],
        congestionPrice: '15',
        congestionWeekDays: [
            {
                day: 1,
                from: '07:00',
                to: '18:00'
            },
            {
                day: 2,
                from: '07:00',
                to: '18:00'
            },
            {
                day: 3,
                from: '07:00',
                to: '18:00'
            },
            {
                day: 4,
                from: '07:00',
                to: '18:00'
            },
            {
                day: 5,
                from: '07:00',
                to: '18:00'
            },
            {
                day: 6,
                from: '12:00',
                to: '18:00'
            },
            {
                day: 0,
                from: '12:00',
                to: '18:00'
            }
        ],
        congestionDaysInterval: {
            from: startDate,
            to: endDate
        }
    },
    closeOrderDates: {
        closeWeekDays: [],
        closeDates: []
    }
}

export const appOptionsSlice = createSlice({
    name: 'appOptions',
    initialState,
    reducers: {
        setIsAuth(state, action: PayloadAction<boolean>) {
            state.isAuth = action.payload
        },
        setOptions(state, action: PayloadAction<IAppOptionsResponse>) {
            const { closeWeekDays, closeDates, updatedAt, ...rest } =
                action.payload
            state.appDelivery = { ...state.appDelivery, ...rest }
            state.closeOrderDates = {
                closeWeekDays,
                closeDates
            }
            state.updatedAt = updatedAt
        }
    }
})

export const { setIsAuth, setOptions } = appOptionsSlice.actions

export default persistReducer(persistCardConfig, appOptionsSlice.reducer)
