import styled from 'styled-components'
import { MainTitle } from '../../../../UI/Common/common'
import { ButtonMain } from '../../../../UI/Buttons/ButtonMain'

export const Wrapper = styled.section`
  margin: 30px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (${({ theme }) => theme.media.mobile}) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (${({ theme }) => theme.media.mobile}) {
    align-items: center;
  }
`

export const Subtitle = styled.p`
  margin: 15px 0 0 0;
  width: 400px;
  font-size: 14px;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 20px 0 0 0;
    font-size: 16px;
    width: auto;
    text-align: center;
  }
`

export const Title = styled(MainTitle)`
  margin: 20px 0 0 0;
  font-weight: 200;
  font-size: max(1.8em, min(3em, calc(var(--base-scale) * 4)));
  line-height: 50px;
  letter-spacing: 0.2px;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 0 0 0 0;
    line-height: 30px;
    text-align: center;
  }
`

export const Button = styled(ButtonMain)`
  margin: 30px 0 0 0;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 40px 0 0 0;
    height: 60px;
    width: 100%;
  }
`


export const ImageWrapper = styled.div`
  position: relative;
  margin: 20px 0 0 0;
  width: 50%;
  padding-top: 45%;

  @media (${({ theme }) => theme.media.mobile}) {
    padding: 95% 0 0 0;
    margin: 0 0 30px 0;
    width: 90%;
    display: flex;
    justify-content: center;
  }

`

export const Image = styled.img.attrs({
    alt: 'promo'
})`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  @media (${({ theme }) => theme.media.mobile}) {
    width: 100%;
  }
`

