import { FC } from 'react'
import { IAddress, IAddressData } from '../../../../Types/IUser'
import { useFormik } from 'formik'
import FieldText from '../../../../components/Fields/FieldText/FieldText'
import FieldTextArea from '../../../../components/Fields/FieldTextArea/FieldTextArea'
import { Bar, Button, Form, Line } from './AddressStyled'
import FieldAddress, {
    IFieldAddressData
} from '../../../../components/Fields/FieldAddress/FieldAddress'
import FieldNumber from '../../../../components/Fields/FieldNumber/FieldNumber'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import {
    changeAddress,
    removeAddress
} from '../../../../store/reducers/User/ActionCreators'
import getObjectDifference from '../../../../utils/getObjectDifference'

interface AddressProps {
    userId: string
    origin: string
    address: IAddressData
}

const Address: FC<AddressProps> = ({ address, origin, userId }) => {
    const { mobile } = useAppSelector((state) => state.appConditionReducer)
    const dispatch = useAppDispatch()

    const initialValues = {
        address: address.address,
        postCode: address.postCode,
        flat: address.flat,
        floor: address.floor,
        isCongestionZone: address.isCongestionZone,
        deliveryDistance: {
            origin: origin,
            distance: address.deliveryDistance.distance
        }
    }

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { resetForm }) => {
            await dispatch(
                changeAddress({
                    clientId: userId,
                    address: { ...values, id: address.id }
                })
            )
            resetForm({ values })
        }
    })

    const addressChoiceHandler = (data: IFieldAddressData) => {
        formik.setValues({
            ...formik.values,
            address: data.address,
            postCode: data.postCode,
            isCongestionZone: data.isCongestionZone,
            deliveryDistance: {
                ...formik.values.deliveryDistance,
                distance: data.deliveryDistance
            }
        })
    }

    const removeAddressHandler = () => {
        dispatch(removeAddress({ clientId: userId, addressId: address.id }))
    }

    return (
        <Form onSubmit={formik.handleSubmit}>
            <FieldAddress
                name="address"
                value={formik.values.address}
                placeholder="Address"
                onChoice={addressChoiceHandler}
                isTouched={formik.touched.address}
                error={formik.errors.address}
            />
            <Line>
                <FieldNumber
                    name="floor"
                    value={formik.values.floor}
                    placeholder="Floor"
                    onChange={formik.handleChange}
                    isTouched={formik.touched.floor}
                    error={formik.errors.floor}
                />
                <FieldNumber
                    name="flat"
                    value={formik.values.flat}
                    placeholder="Flat"
                    onChange={formik.handleChange}
                    isTouched={formik.touched.flat}
                    error={formik.errors.flat}
                />
                <FieldText
                    name="postCode"
                    value={formik.values.postCode}
                    placeholder="Postal Code"
                    onChange={formik.handleChange}
                    isTouched={formik.touched.postCode}
                    error={formik.errors.postCode}
                    readonly={true}
                />
            </Line>
            <Bar>
                {!mobile && <div />}
                <Button
                    type="submit"
                    noActive={!(formik.isValid && formik.dirty)}
                >
                    Save
                </Button>
                <Button type="button" onClick={removeAddressHandler}>
                    Remove Address
                </Button>
            </Bar>
        </Form>
    )
}

export default Address
