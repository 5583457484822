import { ChangeEvent, FC, ReactNode } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Input, Label } from './FileLoaderStyled'

interface FileLoaderProps {
    onLoad: (data: FormData) => void
    data: { [key: string]: string }
    children: ReactNode
    multiple?: boolean
    accept: string
}

const FileLoader: FC<FileLoaderProps> = ({
    onLoad,
    data,
    children,
    multiple = true,
    accept
}) => {
    const filedId = uuidv4()

    function changeHandler(e: ChangeEvent<HTMLInputElement>) {
        const files = e.target.files
        const formData = new FormData()
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value)
        })
        if (files) {
            Object.entries(files).forEach(([key, value]) => {
                formData.append('files', value)
            })
        }
        onLoad(formData)
        e.target.files = null
        e.target.value = ''
    }

    return (
        <Label htmlFor={`input-files-${filedId}`}>
            <Input
                type="file"
                id={`input-files-${filedId}`}
                multiple={multiple}
                onChange={changeHandler}
                accept={accept}
            />
            {children}
        </Label>
    )
}

export default FileLoader
