import { MainTextCss } from '../../UI/Text/Text'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Wrapper = styled.div`
  margin: 60px 0 0 0;
  min-height: 70vh;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 1fr;
  justify-content: space-between;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 20px 0 0 0;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    grid-gap: 20px 0;
  }
`

export const Menu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 10px 0;

  .active {
    opacity: 1;
  }

  @media (${({ theme }) => theme.media.mobile}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 20px 20px;
  }
`

export const MenuButton = styled(NavLink)`
  opacity: 0.6;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.graphics};
  ${MainTextCss};

  @media (${({ theme }) => theme.media.mobile}) {
    padding: 5px;
    border: 1px solid ${({ theme }) => theme.colors.graphics};
    border-radius: 4px;
    text-align: center;
  }
`
