import { FC, useRef, useState } from 'react'
import {
    Label,
    Placeholder,
    Container,
    ContainerTime,
    Value,
    IconTime,
    Error
} from '../FieldsStyled'
import TimePicker from '../../TimePicker/TimePicker'
import { FormikErrors, FormikTouched } from 'formik'
import useOutsideClick from '../../../hooks/useOutsideClick'
import { useAppDispatch } from '../../../hooks/store'
import { showMessage } from '../../../store/reducers/AppCondition/AppConditionSlice'
import { useSecondEffect } from '../../../hooks/useSecondEffect'

interface FieldTimeProps {
    selectedDate: string
    firstValue: string
    secondValue: string
    firstName: string
    secondName: string
    placeholder: string
    onChange: (field: string, value: any, shouldValidate?: boolean) => void
    isTouched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
    error:
        | string
        | string[]
        | FormikErrors<any>
        | FormikErrors<any>[]
        | undefined
}

const FieldTime: FC<FieldTimeProps> = ({
                                           selectedDate,
                                           firstValue,
                                           secondValue,
                                           firstName,
                                           secondName,
                                           placeholder,
                                           onChange,
                                           isTouched,
                                           error
                                       }) => {
    const dispatch = useAppDispatch()
    const [pickerIsOpen, setPickerIsOpen] = useState<boolean>(false)

    const wrapperRef = useRef<HTMLLabelElement>(null)
    useOutsideClick(wrapperRef, () => setPickerIsOpen(false))

    const clickHandler = () => {
        if (!selectedDate) {
            dispatch(showMessage('Please select a date'))
            return
        }
        setPickerIsOpen(!pickerIsOpen)
    }

    useSecondEffect(() => {
        onChange(firstName, '')
        onChange(secondName, '')
    }, [selectedDate])

    return (
        <Label ref={wrapperRef}>
            <Placeholder>{placeholder}</Placeholder>
            <ContainerTime
                isChosen={!!(firstValue && secondValue)}
                onClick={clickHandler}
            >
                <Value>{firstValue ? firstValue : '-:-'}</Value>
                <Value>-</Value>
                <Value>{secondValue ? secondValue : '-:-'}</Value>
                <IconTime isChosen={!!(firstValue && secondValue)} />
            </ContainerTime>
            <TimePicker
                selectedDate={selectedDate}
                isOpen={pickerIsOpen}
                onClose={() => setPickerIsOpen(false)}
                firstName={firstName}
                secondName={secondName}
                firstValue={firstValue}
                secondValue={secondValue}
                onChange={onChange}
            />
            {isTouched && error && <Error>{String(error)}</Error>}
        </Label>
    )
}

export default FieldTime
