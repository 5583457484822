import React, { FC } from 'react'
import { Container, Line, Named, Span, Text, Title, Wrapper } from '../../UI/Text/TextPage'

import { pricesData } from '../../Constants/PagesTexts/prices'

const PricePage: FC = () => {
    return (
        <Wrapper>
            <Title>Prices</Title>
            {pricesData.map((price, priceIndex) => (
                <Container key={priceIndex}>
                    <Named>{price.title}</Named>
                    {price.text.map((text, textIndex) => text === 'span' ?
                        <Span key={textIndex}>{text}</Span> :
                        <Text key={textIndex}>{text}</Text>)}
                </Container>
            ))}
            <Line>ddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd
                ddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd</Line>
        </Wrapper>
    )
}

export default PricePage
