import { FC } from 'react'
import { useAppSelector } from '../../hooks/store'
import TransactionCard from './Components/TransactionCard/TransactionCard'
import { ITransaction } from '../../Types/ITransactions'
import { Container, Title, Wrapper } from './TransactionsStyled'

const sortTransactions = (transactions: ITransaction[]) => {
    return transactions.sort((a, b) => {
        if (a.status === 'pending') {
            return -1
        }
        if (new Date(a.createdAt) > new Date(b.createdAt)) {
            return -1
        }
        return 1
    })
}

const Transactions: FC = () => {
    const { transactions } = useAppSelector((state) => state.transactionsReducer)

    return (
        <Wrapper>
            <Title>{!!transactions.length ? 'Transactions' : 'Transactions list is empty'}</Title>
            <Container>
                {sortTransactions([...transactions]).map(transaction => (
                    <TransactionCard
                        transaction={transaction}
                        key={transaction.id}
                    />))}
            </Container>
        </Wrapper>
    )
}

export default Transactions