import { RefObject, useEffect } from 'react'
import useLatest from './useLatest'

interface useOutsideClickProps {
    (elementRef: RefObject<any>, handler: () => void, attached?: boolean): void
}

const useOutsideClick: useOutsideClickProps = (elementRef, handler, attached = true) => {

    const latestHandler = useLatest(handler)

    useEffect(() => {
        if(!attached) return

        const handleClick = (e: any) => {
            if(!elementRef.current) return
            if(!elementRef.current.contains(e.target)) {
                latestHandler.current()
            }
        }

        document.addEventListener("click", handleClick)

        return () => {
            document.removeEventListener("click", handleClick)
        }
    },[elementRef, latestHandler, attached])
}

export default useOutsideClick
