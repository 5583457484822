import { FC } from 'react'
import { useFormikContext } from 'formik'
import { ICartInitialValues } from '../../../../Components/CartFormik/CartFormik'
import FieldAddress, {
    IFieldAddressData
} from '../../../../../../components/Fields/FieldAddress/FieldAddress'
import FieldNumber from '../../../../../../components/Fields/FieldNumber/FieldNumber'
import FieldText from '../../../../../../components/Fields/FieldText/FieldText'
import { Line } from '../../CartDeliveryStyled'
import { useAppSelector } from '../../../../../../hooks/store'
import AddressesDropDown from '../../../../Components/AddressesDropDown/AddressesDropDown'
import { IAddressData } from '../../../../../../Types/IUser'
import { ButtonAdd, ButtonAddMobile, DropDownContainer, Title } from '../MethodsStyled'

interface DeliveryMethodProps {
    onOpenForm: () => void
}

const DeliveryMethod: FC<DeliveryMethodProps> = ({ onOpenForm }) => {
    const { user } = useAppSelector((state) => state.userReducer)
    const { mobile } = useAppSelector((state) => state.appConditionReducer)
    const { values, touched, errors, handleChange, setFieldValue } =
        useFormikContext<ICartInitialValues>()

    const changeAddressHandler = (data: IFieldAddressData) => {
        setFieldValue('deliveryPlace', {
            address: data.address,
            postCode: data.postCode,
            isPaidParking: data.isCongestionZone,
            deliveryDistance: data.deliveryDistance,
            floor: '',
            flat: ''
        })
    }

    const selectAddressHandler = (address: IAddressData) => {
        setFieldValue('deliveryPlace', {
            address: address.address,
            postCode: address.postCode,
            flat: address.flat,
            floor: address.floor,
            isPaidParking: address.isCongestionZone,
            deliveryDistance: address.deliveryDistance.distance
        })
    }

    const clearAddressHandler = () => {
        setFieldValue('deliveryPlace', {
            address: '',
            postCode: '',
            flat: '',
            floor: '',
            isPaidParking: false,
            deliveryDistance: ''
        })
    }

    if (!mobile) {
        return (
            <Line>
                {user ? (
                    <DropDownContainer>
                        <Title>Choose Addresss</Title>
                        <AddressesDropDown
                            title='Addresses'
                            value={values.deliveryPlace.address}
                            options={user.addressesData}
                            onChange={selectAddressHandler}
                            onClear={clearAddressHandler}
                            isTouched={touched.deliveryPlace?.address}
                            error={errors.deliveryPlace?.address}
                        />
                    </DropDownContainer>
                ) : (
                    <FieldAddress
                        name='deliveryPlace.address'
                        placeholder='Delivery Address'
                        onChoice={changeAddressHandler}
                        onClear={clearAddressHandler}
                        isTouched={touched.deliveryPlace?.address}
                        error={errors.deliveryPlace?.address}
                    />
                )}
                {(values.deliveryPlace.address || !user) && (
                    <Line>
                        <Line>
                            <FieldNumber
                                name='deliveryPlace.floor'
                                value={values.deliveryPlace.floor}
                                placeholder='Floor'
                                onChange={handleChange}
                                isTouched={touched.deliveryPlace?.floor}
                                error={errors.deliveryPlace?.floor}
                            />
                            <FieldNumber
                                name='deliveryPlace.flat'
                                value={values.deliveryPlace.flat}
                                placeholder='Flat'
                                onChange={handleChange}
                                isTouched={touched.deliveryPlace?.flat}
                                error={errors.deliveryPlace?.flat}
                            />
                        </Line>
                        <FieldText
                            name='deliveryPlace.postCode'
                            value={values.deliveryPlace.postCode}
                            placeholder='Post Code'
                            onChange={handleChange}
                            isTouched={touched.deliveryPlace?.postCode}
                            error={errors.deliveryPlace?.postCode}
                        />
                    </Line>
                )}
                {(user && !values.deliveryPlace.address) && (<ButtonAdd onClick={onOpenForm}>+</ButtonAdd>)}
            </Line>
        )
    } else {
        return (
            <>
                {user ? (
                    <DropDownContainer>
                        <Title>Choose Addresss</Title>
                        <AddressesDropDown
                            title='Addresses'
                            value={values.deliveryPlace.address}
                            options={user.addressesData}
                            onChange={selectAddressHandler}
                            onClear={clearAddressHandler}
                        />
                        {!values.deliveryPlace.address && (
                            <ButtonAddMobile onClick={onOpenForm}>
                                Add New Address
                            </ButtonAddMobile>
                        )}
                    </DropDownContainer>
                ) : (
                    <FieldAddress
                        name='deliveryPlace.address'
                        placeholder='Deliveri Address'
                        onChoice={changeAddressHandler}
                        onClear={clearAddressHandler}
                        isTouched={touched.deliveryPlace?.address}
                        error={errors.deliveryPlace?.address}
                    />
                )}
                <Line>
                    <FieldNumber
                        name='deliveryPlace.floor'
                        value={values.deliveryPlace.floor}
                        placeholder='Floor'
                        onChange={handleChange}
                        isTouched={touched.deliveryPlace?.floor}
                        error={errors.deliveryPlace?.floor}
                    />
                    <FieldNumber
                        name='deliveryPlace.flat'
                        value={values.deliveryPlace.flat}
                        placeholder='Flat'
                        onChange={handleChange}
                        isTouched={touched.deliveryPlace?.flat}
                        error={errors.deliveryPlace?.flat}
                    />
                </Line>
                <FieldText
                    name='deliveryPlace.postCode'
                    value={values.deliveryPlace.postCode}
                    placeholder='Postal Code'
                    onChange={handleChange}
                    isTouched={touched.deliveryPlace?.postCode}
                    error={errors.deliveryPlace?.postCode}
                />
            </>
        )
    }
}

export default DeliveryMethod
