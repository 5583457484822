import React, { FC } from 'react'
import { useAppSelector } from '../../hooks/store'
import HeaderMobile from './Components/HeaderMobile/HeaderMobile'
import HeaderLaptop from './Components/HeaderLaptop/HeaderLaptop'

interface HeaderProps {
    onProfileClick: () => void
}

const Header: FC<HeaderProps> = ({ onProfileClick }) => {
    const { mobile } = useAppSelector((state) => state.appConditionReducer)

    if (!mobile) {
        return <HeaderLaptop onProfileClick={onProfileClick} />
    } else {
        return <HeaderMobile onProfileClick={onProfileClick} />
    }

}

export default Header
