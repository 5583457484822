import React, { FC } from 'react'

import { Message, Wrapper } from './LinkMoreStyled'
import { useNavigate } from 'react-router'
import { ButtonMore } from '../../Buttons/ButtonsIcon'

interface LinkMoreProps {
    message: string
    path: string
}

const LinkMore: FC<LinkMoreProps> = ({
                                         message,
                                         path
                                     }) => {

    const navigate = useNavigate()

    return (
        <Wrapper onClick={() => navigate(path)}>
            <Message>{message}</Message>
            <ButtonMore />
        </Wrapper>
    )
}

export default LinkMore
