import { FC } from 'react'
import { ITextData } from '../TextItem/TextItem'
import { InternalLink, Link } from '../../../../UI/Text/TextPage'

interface LinkProps {
    data: ITextData
}

export const TextLink: FC<LinkProps> = ({ data }) => {
    if (data.isInternal) {
        return (
            <InternalLink to={data.path}>{data.text}</InternalLink>
        )
    }
    return (
        <Link href={data.path} target='_blank'>{data.text}</Link>
    )
}