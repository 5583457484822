import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 40px 0 0 0;
  width: 80%;

  @media (${({ theme }) => theme.media.mobile}) {
    margin: 20px 0 0 0;
    width: 100%;
  }
`