import React, { FC, ReactNode } from 'react'
import { Pagination } from 'swiper'
import { Slider } from './MobileSliderStyled'
import 'swiper/css'
import 'swiper/css/pagination'

interface SliderProps {
    children: ReactNode
}

const MobileSlider: FC<SliderProps> = (props) => {

    return (
        <Slider
            {...props}
            spaceBetween={30}
            pagination={{
                dynamicBullets: true
            }}
            modules={[Pagination]}
        >
            {props.children}
        </Slider>
    )
}

export default MobileSlider
