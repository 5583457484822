import styled, { keyframes } from 'styled-components'

export const animation1 = keyframes`
    10% {
      opacity: 1;
      transform: translateY(0);
    }
    90%{
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(0);
    }
`

export const animation2 = keyframes`

  20% {
    opacity: 0;
    transform: translateY(-30px);
  }
    30% {
      opacity: 1;
      transform: translateY(0);
    }
    90%{
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(0);
    }
`

export const animation3 = keyframes`

  30% {
    opacity: 0;
    transform: translateY(-30px);
  }
    40% {
      opacity: 1;
      transform: translateY(0);
    }
    90%{
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(0);
    }
`
export const animation4 = keyframes`

  40% {
    opacity: 0;
    transform: translateY(-30px);
  }
    50% {
      opacity: 1;
      transform: translateY(0);
    }
    90%{
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(0);
    }
`

export const Wrapper = styled.div`
    position: relative;
    width: 100px;
    height: 100px;
`

export const Image = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
`

export const Image1 = styled(Image)`
    animation: ${animation1} 4s infinite linear;
`
export const Image2 = styled(Image)`
    animation: ${animation2} 4s infinite linear;
`
export const Image3 = styled(Image)`
    animation: ${animation3} 4s infinite linear;
`
export const Image4 = styled(Image)`
    animation: ${animation4} 4s infinite linear;
`
