import React, { FC } from 'react'
import {
    FilingContainer,
    FilingDescription,
    FilingImage,
    FilingImageWrapper,
    FilingSections,
    FilingTitle,
    FilingWrapper
} from './ExtraFilingsStyled'
import { IExtraFiling } from '../../../../Types/IProduct'
import { getImageUrl } from '../../../../utils/getImageUrl'

interface FilingProps {
    filing: IExtraFiling
    isActive: boolean
    clickHandler: (filing: IExtraFiling, isActive: boolean) => void
}

const ExtraFiling: FC<FilingProps> = ({ filing, isActive = false, clickHandler }) => {

    return (
        <FilingWrapper
            isActive={isActive}
            onClick={() => clickHandler(filing, isActive)}
        >
            <FilingImageWrapper>
                <FilingImage src={getImageUrl(filing.photo[0])} />
            </FilingImageWrapper>
            <FilingContainer>
                <FilingTitle>{filing.title}</FilingTitle>
                <FilingDescription>
                    {filing.structure.map((item, i) => <FilingSections key={i}>-{item}</FilingSections>)}
                </FilingDescription>
            </FilingContainer>

        </FilingWrapper>
    )
}

export default ExtraFiling