export function hideScrollBar(mobile: boolean) {
    const bodyHeight = document.body.scrollHeight
    const viewportHeight = window.innerHeight
    if (bodyHeight > viewportHeight) {
        document.body.style.overflow = 'hidden'
        if (!mobile) {
            document.body.style.paddingRight = '9.5px'
        }
    }
}

export function showScrollBar(mobile: boolean) {
    document.body.style.overflow = 'unset'
    if (!mobile) {
        document.body.style.paddingRight = '0px'
    }
}
